import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useGetOrderDraftsQuery } from '../../slices/orderDraftsApiSlice';
import formatDate from '../../utils/formatDate.js';
import { useEffect } from 'react';

const OrderDraftListAdminScreen = () => {
	const { data: orderDrafts, isLoading, error, refetch } = useGetOrderDraftsQuery();

	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<>
			<nav aria-label='breadcrumb'>
				<ol className='breadcrumb'>
					<li className='breadcrumb-item'>
						<a href='/'>首页</a>
					</li>
					<li className='breadcrumb-item active' aria-current='page'>
						订单编辑列表
					</li>
				</ol>
			</nav>
			{isLoading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Table striped hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>创建日期</th>
								<th>客户</th>
								<th>销售员</th>
								<th>产品数量</th>
								<th>付款方式</th>
								<th>备注</th>
								<th>操作</th>
							</tr>
						</thead>
						<tbody>
							{orderDrafts?.map((draft) => (
								<tr key={draft._id}>
									<td>{formatDate(draft.createdAt)}</td>
									<td>{draft.customer.name}</td>
									<td>{draft.user && draft.user.name}</td>
									<td>{draft.orderItems.length}</td>
									<td>{draft.paymentMethod}</td>
									<td>{draft.orderNotes}</td>
									<td>
										<LinkContainer to={`/order/create/${draft._id}/draft`}>
											<Button variant='outline-light' size='sm'>
												继续编辑
											</Button>
										</LinkContainer>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}
		</>
	);
};

export default OrderDraftListAdminScreen;
