import { Card } from 'react-bootstrap';
// import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrderListFinanceTable from './OrderListFinanceTable';
const OrderListFinanceScreen = () => {
	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									订单跟进
								</li>
							</ol>
						</nav>
					</Card.Title>
					<p className='card-subtitle text-muted'>Order List</p>
				</Card.Header>
				<Card.Body className='p-0'>
					<OrderListFinanceTable />
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderListFinanceScreen;
