import { createSlice } from '@reduxjs/toolkit';
import { updateOrder } from '../utils/orderUtils';

const initialState = localStorage.getItem('order')
	? JSON.parse(localStorage.getItem('order'))
	: {
			orderItems: [],
			orderId: '',
			quotationId: '',
			customer: {},
			sale: {},
			assistant: {},
			deliveryAddress: {},
			paymentMethod: '全额支付',
			customerQuotaInfo: {},
			paymentDetail: {},
			scheduleNodes: [],
	  };

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		addToOrder: (state, action) => {
			const item = action.payload;
			state.orderItems = Array.from(new Set(state.orderItems.concat(item)));
			return updateOrder(state);
		},

		addDraftItemsToOrder: (state, action) => {
			state.orderItems = action.payload;
			return updateOrder(state);
		},

		updateOrderItems: (state, action) => {
			state.orderItems = state.orderItems.map((x) => (x._id === action.payload._id ? action.payload : x));
			return updateOrder(state);
		},

		updateOrderItemsSellingPrice: (state, action) => {
			state.orderItems = state.orderItems.map((x) => (x._id === action.payload._id ? action.payload : x));
			return updateOrder(state);
		},

		updateOrderItemsQty: (state, action) => {
			state.orderItems = state.orderItems.map((x) => (x._id === action.payload._id ? action.payload : x));
			return updateOrder(state);
		},

		saveOrderId: (state, action) => {
			state.orderId = action.payload;
			return updateOrder(state);
		},

		saveOrderIndexOfDay: (state, action) => {
			state.orderIndexOfDay = action.payload;
			return updateOrder(state);
		},

		saveQuotationId: (state, action) => {
			state.quotationId = action.payload;
			return updateOrder(state);
		},

		saveCustomer: (state, action) => {
			state.customer = action.payload;
			state.customerQuotaInfo = action.payload.customerQuotas;
			return updateOrder(state);
		},

		saveSale: (state, action) => {
			state.sale = action.payload;
			return updateOrder(state);
		},

		saveAssistant: (state, action) => {
			state.assistant = action.payload;
			return updateOrder(state);
		},

		savePaymentMethod: (state, action) => {
			state.paymentMethod = action.payload;
			return updateOrder(state);
		},

		savePaymentDetail: (state, action) => {
			state.paymentDetail.prePaymentOption = action.payload.prePaymentOption;
			state.paymentDetail.prePaymentPercent = action.payload.prePaymentPercent;
			state.paymentDetail.percentPayment = action.payload.percentPayment;
			state.paymentDetail.percentBalance = action.payload.percentBalance;
			state.paymentDetail.prePayment = action.payload.prePayment;
			state.paymentDetail.prePaymentBalance = action.payload.prePaymentBalance;
			state.paymentDetail.paymentExplain = action.payload.paymentExplain;
			state.paymentDetail.arrivalTime = action.payload.arrivalTime;
			state.paymentDetail.warrantyPeriod = action.payload.warrantyPeriod;
			state.paymentDetail.orderNotes = action.payload.orderNotes;
			return updateOrder(state);
		},

		saveScheduleNodes: (state, action) => {
			state.scheduleNodes = action.payload;
			return updateOrder(state);
		},

		clearScheduleNodes: (state, action) => {
			state.scheduleNodes = [];
			return updateOrder(state);
		},

		addScheduleNodesToOrder: (state, action) => {
			const node = action.payload;
			state.scheduleNodes = Array.from(new Set(state.scheduleNodes.concat(node)));
			return updateOrder(state);
		},

		addScheduleNodeToOrder: (state, action) => {
			state.scheduleNodes = state.scheduleNodes.concat(action.payload);
			return updateOrder(state);
		},

		removeScheduleNodesFromOrder: (state, action) => {
			state.scheduleNodes = state.scheduleNodes.filter((x) => x.title !== action.payload);
			return updateOrder(state);
		},

		removeFromOrder: (state, action) => {
			state.orderItems = state.orderItems.filter((x) => x._id !== action.payload);
			return updateOrder(state);
		},

		clearOrderItems: (state, action) => {
			state.orderItems = [];
			localStorage.setItem('order', JSON.stringify(state));
		},

		clearCustomer: (state, action) => {
			state.customer = {};
			return updateOrder(state);
		},

		clearSale: (state, action) => {
			state.sale = {};
			state.orderId = '';
			return updateOrder(state);
		},

		clearAssistant: (state, action) => {
			state.assistant = {};
			return updateOrder(state);
		},

		resetOrder: (state) => {
			state.customer = {};
			state.sale = {};
			state.assistant = {};
			state.customerQuotaInfo = {};
			state.orderItems = [];
			state.orderId = '';
			state.quotationId = '';
			state.itemPrice = '';
			state.deliveryAddress = '';
			state.paymentMethod = '全额支付';
			state.paymentDetail = {};
			state.scheduleNodes = [];
			localStorage.setItem('order', JSON.stringify(state));
		},
	},
});

export const {
	addToOrder,
	addDraftItemsToOrder,
	updateOrderItems,
	updateOrderItemsQty,
	updateOrderItemsSellingPrice,
	saveCustomer,
	saveSale,
	saveAssistant,
	clearOrderItems,
	clearCustomer,
	clearSale,
	clearAssistant,
	savePaymentMethod,
	savePaymentDetail,
	saveScheduleNodes,
	clearScheduleNodes,
	addScheduleNodesToOrder,
	addScheduleNodeToOrder,
	removeScheduleNodesFromOrder,
	removeFromOrder,
	resetOrder,
	saveOrderId,
	saveOrderIndexOfDay,
	saveQuotationId,
} = orderSlice.actions;

export default orderSlice.reducer;
