import { createSlice } from '@reduxjs/toolkit';

const initialState = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')) : { customers: [] };

const customerSlice = createSlice({
	name: 'customer',
	initialState,
	reducers: {
		loadCustomersByUserToLocalStorge: (state, action) => {
			const customers = action.payload;
			state.customers = customers;
			localStorage.setItem('preLoadCustomers', JSON.stringify(state));
		},
	},
});

export const { loadCustomersByUserToLocalStorge } = customerSlice.actions;

export default customerSlice.reducer;
