import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import Message from '../../../components/Message';
import { saveOrderId, saveOrderIndexOfDay, saveQuotationId } from '../../../slices/orderSlice';
import OrderButton from './OrderButton';
import SelectOrderSaleModal from './table/SelectOrderSaleModal';

const OrderHeader = ({ newOrderId, incrementIndex, loadingIndex, error, orderCreating, updatingDraft, orderDraftSaving, initiatingApproval }) => {
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.auth);
	const order = useSelector((state) => state.order);
	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		dispatch(saveOrderIndexOfDay(incrementIndex));
		dispatch(saveOrderId(newOrderId));
		dispatch(saveQuotationId(newOrderId));
	}, [dispatch, newOrderId, incrementIndex]);
	return (
		<>
			<Card>
				<Card.Body>
					<Row className='flex-between-center'>
						<Col sm={2} md={6} lg={7} xl={4}>
							{userInfo.role === 'assistant' ? (
								<>
									<h5 className='my-2 mb-md-0 me-1 align-center'>
										订单编号 #
										{order?.orderId !== '' ? (
											<>{order?.orderId}</>
										) : (
											<>
												<Button variant='falcon-primary' size='sm' className='rounded-pill ms-2 mb-1' type='button' onClick={() => setModalShow(true)}>
													选择销售 <FontAwesomeIcon icon='fa-solid fa-users' className='ms-1' />
												</Button>
											</>
										)}
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
													<span>订单编号将根据你选择的销售员生成</span>
												</Tooltip>
											}
										>
											<span className='ms-2 text-primary fs-10'>
												<FontAwesomeIcon icon='question-circle' />
											</span>
										</OverlayTrigger>
									</h5>
								</>
							) : (
								<>
									<h5 className='my-2 mb-md-0 me-1 align-center'>
										订单编号 # {loadingIndex ? <CircularProgress className='ms-2' size={20} /> : error ? <Message>订单编号生成错误,刷新后重试</Message> : newOrderId}
										<OverlayTrigger
											overlay={
												<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
													<span>销售代码+日期+当日订单序号</span>
												</Tooltip>
											}
										>
											<span className='ms-2 text-primary fs-10'>
												<FontAwesomeIcon icon='question-circle' />
											</span>
										</OverlayTrigger>
									</h5>
								</>
							)}
						</Col>
						<Col sm={2} md={6} lg={6} xl={8} xxl={6}>
							<OrderButton orderCreating={orderCreating} updatingDraft={updatingDraft} orderDraftSaving={orderDraftSaving} initiatingApproval={initiatingApproval} />
						</Col>
					</Row>
				</Card.Body>
			</Card>
			{/* Modal */}
			<SelectOrderSaleModal modalShow={modalShow} setModalShow={setModalShow} />
		</>
	);
};

export default OrderHeader;
