/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import OrderPaymentInfo from './OrderPaymentInfo';
import { clearScheduleNodes } from '../../../slices/orderSlice';

const OrderPayment = () => {
	const order = useSelector((state) => state.order);
	const { customer, orderItems, customerQuotaInfo, totalPrice } = order;
	const { getValues, register, setValue } = useFormContext();
	const [paymentRadioDisabled, setPaymentRadioDisabled] = useState(true);
	const [balance, setBalance] = useState();
	const [quota, setQuota] = useState();
	const dispatch = useDispatch();

	useEffect(() => {
		if (Object.keys(customer).length !== 0 && orderItems.length !== 0) {
			setPaymentRadioDisabled(false);
			if (customerQuotaInfo.length !== 0) {
				setBalance(customerQuotaInfo.find((item) => item.isExpired === false).balance);
				setQuota(customerQuotaInfo.find((item) => item.isExpired === false).quota);
			} else {
				setBalance(0);
				setQuota(0);
			}
		} else {
			setPaymentRadioDisabled(true);
		}
	}, [customer, orderItems, customerQuotaInfo, getValues]);

	return (
		<Card className='mt-3'>
			<Card.Header>
				<Card.Title>
					<h6 className='mb-0 mb-md-0 me-1'>付款条件</h6>
				</Card.Title>
			</Card.Header>
			<Card.Body>
				<Form.Check
					type='radio'
					label='全额支付'
					id='paymentMethod1'
					name='paymentMethod'
					value='全额支付'
					disabled={paymentRadioDisabled}
					{...register('paymentMethod', {
						onChange: (e) => {
							setValue('paymentMethod', e.target.value);
						},
					})}
				/>

				<Form.Check
					type='radio'
					label='额度支付(待开放)'
					id='paymentMethod2'
					name='paymentMethod'
					value='额度支付'
					onClick={(e) => {
						dispatch(clearScheduleNodes());
						setValue('prePaymentPercent', 0); // reset pre payment percent form field value
						setValue('percentPayment', 0); // reset pre payment percent form field value
					}}
					//disabled={paymentRadioDisabled}
					disabled
					{...register('paymentMethod', { onChange: (e) => setValue('paymentMethod', e.target.value) })}
				/>

				<Form.Check
					type='radio'
					label='部分支付'
					className='mb-1'
					id='paymentMethod3'
					name='paymentMethod'
					value='部分支付'
					disabled={paymentRadioDisabled}
					{...register('paymentMethod', {
						onChange: (e) => {
							setValue('percentBalance', totalPrice);
							setValue('paymentMethod', e.target.value);
						},
					})}
				/>
				<Col>
					<hr />
				</Col>
				<OrderPaymentInfo balance={balance} quota={quota} />
			</Card.Body>
		</Card>
	);
};

export default OrderPayment;
