// import { useEffect } from 'react';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { Badge, Button, Card, CloseButton, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useGetOrderDetailsQuery } from '../../../slices/ordersApiSlice';
import celebration from '../../../assets/img/animated-icons/celebration.json';
import file from '../../../assets/img/animated-icons/file.json';
import SubtleBadge from '../../../components/common/SubtleBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OrderCreateSuccessScreen = () => {
	const { id: orderId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const { data: order, isLoading, error } = useGetOrderDetailsQuery(orderId);

	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);

	return isLoading ? (
		<Loader />
	) : error ? (
		<Message variant='danger' />
	) : (
		<>
			<Row className='g-3'>
				<Col xs={12}>
					<Card className='mb-1'>
						<Card.Body>
							<Row className='flex-between-center'>
								<Col md>
									<h5 className='my-2 mb-md-0 me-1'>
										订单编号 # {order.orderId}
										{order.approvalStatus === 'inProcessOfApproval' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='info'>
												<FontAwesomeIcon icon='fa-feather' className='me-1' />
												<span>审批中</span>
											</Badge>
										)}
										{order.approvalStatus === 'approved' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='success'>
												<FontAwesomeIcon icon='check' className='me-1' />
												<span>审核通过</span>
											</Badge>
										)}
										{order.approvalStatus === 'reject' && (
											<Badge className='ms-1 mb-1 p-1 fs-11 align-middle ' bg='danger'>
												<FontAwesomeIcon icon='x-mark' className='me-1' />
												<span>被驳回</span>
											</Badge>
										)}
									</h5>
								</Col>
								<Col xs='auto'>
									<Button variant='link' className='text-warning fw-medium p-0 me-3' onClick={() => navigate(`/${userInfo.role}/orderlist`)}>
										跟进订单
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col md={12}>
					<Card>
						<Card.Header>
							<Card.Title className='mb-0'>订单详情</Card.Title>
						</Card.Header>
						<Row>
							<Col>
								<ListGroup variant='flush'>
									<ListGroup.Item key={order.orderId}>
										<Row>
											<Col>
												<p>
													<strong>客户名称:</strong>
													<span className='fs-8 fw-bolder text-primary ms-2'>{order.customerInfo.customerName}</span>
												</p>
												{order.customerInfo.staffName && (
													<p>
														<strong>联系人:</strong>
														<span className='ms-2'>{order.customerInfo.staffName}</span>
													</p>
												)}
												{order.customerInfo.staffPhone && (
													<p>
														<strong>电话:</strong>
														<span className='ms-2'>{order.customerInfo.staffPhone}</span>
													</p>
												)}
												{order.customerInfo.staffWechat && (
													<p>
														<strong>微信:</strong>
														<span className='ms-2'> {order.customerInfo.staffWechat}</span>
													</p>
												)}
												{order.customerInfo.staffEmail && (
													<p>
														<strong>邮箱:</strong>
														<a className='ms-2' href={`mailto:${order.customerInfo.staffEmail}`}>
															{order.customerInfo.staffEmail}
														</a>
													</p>
												)}
												<p>
													<strong>地址: </strong>
													<span className='ms-2'>{order.customerInfo.customerAddress}</span>
												</p>
											</Col>
											<Col>
												<ListGroup variant='flush'>
													<ListGroup.Item>
														<p>
															<strong>业务联系人:</strong>
															<span className='ms-2 fs-8 text-primary'>
																<strong>{order.saleUser ? order.saleUser.name : order.createdUser.name}</strong>
															</span>
														</p>
														<p>
															<strong>电话:</strong>
															<span className='ms-2'>{order.saleUser ? order.saleUser.phone : order.createdUser.phone}</span>
														</p>
														<p>
															<strong>邮箱:</strong>
															<a href={`mailto:${order.saleUser ? order.saleUser.email : order.createdUser.email}`}>
																<span className='ms-2'>{order.saleUser ? order.saleUser.email : order.createdUser.email}</span>
															</a>
														</p>
													</ListGroup.Item>
													<ListGroup.Item>
														<p>
															<strong>销售助理:</strong>
															<span className='ms-2 fs-8 text-primary'>
																<strong>{order.assistantUser ? order.assistantUser.name : order.createdUser.name}</strong>
															</span>
														</p>
														<p>
															<strong>电话:</strong>
															<span className='ms-2'>{order.assistantUser ? order.assistantUser.phone : order.createdUser.phone}</span>
														</p>
														<p>
															<span className='me-2'>
																<strong>邮箱:</strong>
															</span>
															<a href={`mailto:${order.assistantUser ? order.assistantUser.email : order.createdUser.email}`}>
																<span>{order.assistantUser ? order.assistantUser.email : order.createdUser.email}</span>
															</a>
														</p>
													</ListGroup.Item>
												</ListGroup>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item>
										<h5 className='my-3'>
											<strong>货款支付</strong>
										</h5>
										<p>
											<strong>方式: </strong>
											{order.paymentMethod}
										</p>

										<Row className='mb-2'>
											<Col>产品总价</Col>
											<Col>¥ {order.itemsPrice}</Col>
										</Row>
										<Row className='mb-2'>
											<Col>其他费用</Col>
											<Col>¥ {order.deliveryPrice}</Col>
										</Row>
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '百分比' && (
											<>
												<Row className='mb-2'>
													<Col>预付百分比:</Col>
													<Col>
														<strong>{order.prePaymentPercent} %</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.percentBalance}</strong>
													</Col>
												</Row>
												<Row className='mb-3'>
													<Col md={7}>
														<ul className='timeline-order'>
															{order.scheduleNodes.length !== 0 &&
																order.scheduleNodes.map((node) => (
																	<li className='timeline-item mb-3'>
																		<Card>
																			<Card.Header className='bg-primary-subtle pb-1'>
																				<Card.Title>
																					<h6 className='text-1100'>{node.title}</h6>
																				</Card.Title>
																			</Card.Header>
																			<Card.Body className='pt-3 pb-3'>
																				<Row>
																					<Col sm={6}>账期</Col>
																					<Col sm={6}>{node.paymentDays}天</Col>
																					<Col sm={6}>付款百分比</Col>
																					<Col sm={6}>{node.paymentPercent}%</Col>
																					<Col sm={6}>付款金额</Col>
																					<Col sm={6}>{node.paymentAmount} 元</Col>
																				</Row>
																			</Card.Body>
																		</Card>
																	</li>
																))}
														</ul>
													</Col>
												</Row>
											</>
										)}
										{order.paymentMethod === '部分支付' && order.prePaymentOption === '预付款' && (
											<>
												<Row className='mb-2'>
													<Col>预支付:</Col>
													<Col>
														<strong>¥ {order.prePayment}</strong>
													</Col>
												</Row>
												<Row className='mb-2'>
													<Col>剩余尾款:</Col>
													<Col>
														<strong>¥ {order.prePaymentBalance}</strong>
													</Col>
												</Row>
											</>
										)}
										<Row className='mb-3'>
											<Col>
												<strong>总价</strong>
											</Col>
											<Col>
												<strong>¥ {order.totalPrice}</strong>
											</Col>
										</Row>
									</ListGroup.Item>
									<ListGroup.Item className='mb-6'>
										<h5 className='my-3'>
											<strong>订单列表</strong>
										</h5>
										<ListGroup.Item className='bg-primary'>
											<Row className='text-white'>
												<Col>
													<strong>货号</strong>
												</Col>
												<Col>
													<strong>设备名称</strong>
												</Col>
												<Col>
													<strong>数量</strong>
												</Col>
												<Col>
													<strong>单价</strong>
												</Col>
												<Col>
													<strong>小计</strong>
												</Col>
											</Row>
										</ListGroup.Item>
										{order.orderItems.map((item, index) => (
											<>
												<ListGroup.Item key={index}>
													<Row>
														<Col>
															{item.productCode}
															{item.sellingPrice < item.minimumSellingPrice && (
																<SubtleBadge bg='info' className='ms-2'>
																	优惠
																</SubtleBadge>
															)}
														</Col>
														<Col>{item.name}</Col>
														<Col>{item.qty}</Col>
														<Col>¥{item.sellingPrice}</Col>
														<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
													</Row>
												</ListGroup.Item>
											</>
										))}
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Modal show={show}>
				<Modal.Header>
					<Modal.Title>{order.approvalStatus === 'inProcessOfApproval' ? '订单提示🔔' : '恭喜您 🎉'} </Modal.Title>
					<CloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>
					<div className='text-center'>
						{order.approvalStatus === 'inProcessOfApproval' && (
							<div className='wizard-lottie-wrapper'>
								<div className='wizard-lottie mx-auto w-50 h-50'>
									<Lottie animationData={file} />
								</div>
							</div>
						)}
						{order.approvalStatus === 'systemApproved' && (
							<div className='wizard-lottie-wrapper'>
								<div className='wizard-lottie mx-auto'>
									<Lottie animationData={celebration} />
								</div>
							</div>
						)}

						<h4 className='my-3'>{order.approvalStatus === 'inProcessOfApproval' ? '订单审批中...' : '订单创建成功!'}</h4>
						<p className='fs-0'>{order.approvalStatus === 'inProcessOfApproval' && ''}</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleClose}>
						好!
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default OrderCreateSuccessScreen;
