import { Modal } from 'react-bootstrap';

import SelectSaleTable from './SelectSaleTable';

const SelectSaleModal = ({ selectSaleModalShow, setSelectSaleModalShow }) => {
	return (
		<>
			<Modal show={selectSaleModalShow} size='lg' onHide={() => setSelectSaleModalShow(false)} aria-labelledby='contained-modal-title-vcenter' centered>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>选择销售员</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<SelectSaleTable setModalShow={setSelectSaleModalShow} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SelectSaleModal;
