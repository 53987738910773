import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import App from './App';
import './assets/styles/index.css';
import { AdminRoute, AssistantRoute, BuyerRoute, FinanceRoute, ManagerRoute, PrivateRoute, SaleRoute, UserRoute } from './components/validroute/AuthRoute';
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import store from './store';
// Layout
import AuthCardLayout from './layouts/AuthCardLayout';
// public screen
import DashboardScreen from './screens/dashboard/DashboardScreen';
import LoginoutScreen from './screens/LoginoutScreen';
import LoginScreen from './screens/LoginScreen';
import CreateOrderScreen from './screens/order/create-an-order/CreateOrderScreen';
import OrderCreateSuccessScreen from './screens/order/create-an-order/OrderCreateSuccessScreen';
import QuotationScreen from './screens/order/quotation/QuotationScreen';
import RegisterScreen from './screens/RegisterScreen';
// User screen
import CustomerListScreen from './screens/customer/CustomerListScreen';
import OrderListScreen from './screens/order/OrderListScreen';
import ProfileScreen from './screens/ProfileScreen';
// Admin screen
import CustomerListAdminScreen from './screens/admin/CustomerListAdminScreen';
import OrderDraftListAdminScreen from './screens/admin/OrderDraftListAdminScreen';
import OrderListAdminScreen from './screens/admin/OrderListAdminScreen';
import ProductListAdminScreen from './screens/admin/ProductListAdminScreen';
import UserListAdminScreen from './screens/admin/UserListAdminScreen';
import ApprovalListScreen from './screens/approval/ApprovalListScreen';
import CustomerApprovalDetailScreen from './screens/approval/CustomerApprovalDetailScreen';
import OrderApprovalDetailScreen from './screens/approval/OrderApprovalDetailScreen';
import QuotaApprovalDetailScreen from './screens/approval/QuotaApprovalDetailScreen';
import NotificationListScreen from './screens/notification/NotificationListScreen';
import OrderListAssistantScreen from './screens/order/assistant/OrderListAssistantScreen';
import OrderListBuyerScreen from './screens/order/buyer/OrderListBuyerScreen';
import OrderListFinanceScreen from './screens/order/finance/OrderListFinanceScreen';
import GenechampUserListScreen from './screens/order/manager/GenechampUserListScreen';
import OrderListManagerScreen from './screens/order/manager/OrderListManagerScreen';
import OrderDetailScreen from './screens/order/OrderDetailScreen';
import OrderDraftListScreen from './screens/order/OrderDraftListScreen';
import OrderListSaleScreen from './screens/order/sale/OrderListSaleScreen';
import ProductListScreen from './screens/product/ProductListScreen';

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			{/*- ------------- Authentication ---------------------------  */}
			{/*- ------------- Card ---------------------------  */}
			<Route element={<AuthCardLayout />}>
				<Route index={true} path='/' element={<LoginScreen />} />
				<Route path='/register' element={<RegisterScreen />} />
				<Route path='/loginout' element={<LoginoutScreen />} />
			</Route>

			<Route element={<App />}>
				<Route path='' element={<PrivateRoute />}>
					{/* ------------ DashboardScreen ----------- */}
					<Route path='/dashboard' element={<DashboardScreen />} />

					{/* ------------ Public access --------- */}
					<Route path='/profile' element={<ProfileScreen />} />
					<Route path='/approval/list' element={<ApprovalListScreen />} />
					<Route path='/approval/customer/detail/:id' element={<CustomerApprovalDetailScreen />} />
					<Route path='/approval/order/:orderId/detail/:approvalId' element={<OrderApprovalDetailScreen />} />
					<Route path='/approval/quota/detail/:id' element={<QuotaApprovalDetailScreen />} />
					<Route path='/notificationlist' element={<NotificationListScreen />} />
					<Route path='/productlist' element={<ProductListScreen />} />
					<Route path='/customerlist' element={<CustomerListScreen />} />
					<Route path='/order/create/new' element={<CreateOrderScreen />} />
					<Route path='/order/create/:draftId/draft' element={<CreateOrderScreen />} />
					<Route path='/order/quotation' element={<QuotationScreen />} />
					<Route path='/order/quotation/:draftId' element={<QuotationScreen />} />
					<Route path='/order/:id/success' element={<OrderCreateSuccessScreen />} />
					<Route path='/order/detail/:id' element={<OrderDetailScreen />} />

					{/* ------------ Manager only access --------- */}
					<Route path='' element={<ManagerRoute />}>
						<Route path='/manager/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/manager/orderlist' element={<OrderListManagerScreen />} />
						<Route path='/manager/userlist' element={<GenechampUserListScreen />} />
					</Route>

					{/* ------------ Finance only access --------- */}
					<Route path='' element={<FinanceRoute />}>
						<Route path='/finance/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/finance/orderlist' element={<OrderListFinanceScreen />} />
					</Route>

					{/* ------------ Finance only access --------- */}
					<Route path='' element={<BuyerRoute />}>
						<Route path='/buyer/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/buyer/orderlist' element={<OrderListBuyerScreen />} />
					</Route>

					{/* ------------ User only access --------- */}
					<Route path='' element={<UserRoute />}>
						<Route path='/user/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/user/orderlist' element={<OrderListScreen />} />
					</Route>

					{/* ------------ Assistant only access --------- */}
					<Route path='' element={<AssistantRoute />}>
						<Route path='/assistant/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/assistant/orderlist' element={<OrderListAssistantScreen />} />
					</Route>

					{/* ------------ Sale only access --------- */}
					<Route path='' element={<SaleRoute />}>
						<Route path='/sale/draftlist' element={<OrderDraftListScreen />} />
						<Route path='/sale/orderlist' element={<OrderListSaleScreen />} />
					</Route>

					{/* ------------ Admin only access --------- */}
					<Route path='' element={<AdminRoute />}>
						<Route path='/admin/orderlist' element={<OrderListAdminScreen />} />
						<Route path='/admin/draftlist' element={<OrderDraftListAdminScreen />} />
						<Route path='/admin/productlist' element={<ProductListAdminScreen />} />
						<Route path='/admin/customerlist' element={<CustomerListAdminScreen />} />
						<Route path='/admin/userlist' element={<UserListAdminScreen />} />
					</Route>
				</Route>
			</Route>
		</Route>
	)
);
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<HelmetProvider>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<Provider store={store}>
					<RouterProvider router={router} />
				</Provider>
			</LocalizationProvider>
		</HelmetProvider>
	</React.StrictMode>
);
