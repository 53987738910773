import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import React, { useState } from 'react';
import { Button, CloseButton, Modal, Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import { useImportProductMutation } from '../../slices/productsApiSlice';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ProductBatchImportModal = ({ showImportDataModal, setShowImportDataModal }) => {
	const EXTENSIONS = ['xlsx', 'xls'];
	// call ImportData hook
	const [importProductData, { isPending: isImportingProductData }] = useImportProductMutation();
	const { userInfo } = useSelector((state) => state.auth);

	const [colDefs, setColDefs] = useState([]);
	const [data, setData] = useState({});

	const getExention = (file) => {
		const parts = file.name.split('.');
		const extension = parts[parts.length - 1];
		return EXTENSIONS.includes(extension);
	};

	const convertToJson = (headers, data) => {
		const rows = [];
		data.forEach((row) => {
			let rowData = {};
			let addRowDataUser = {};
			row.forEach((element, index) => {
				rowData[headers[index]] = element;
				addRowDataUser = { ...rowData, user: userInfo._id };
			});
			rows.push(addRowDataUser);
		});
		return rows;
	};

	const importDataTable = useMaterialReactTable({
		columns: colDefs,
		data,
		localization: MRT_Localization_ZH_HANS,
		muiTablePaperProps: {
			elevation: 0,
		},
		initialState: {},
	});

	const handleImportProduct = async (e) => {
		try {
			if (data) {
				const res = await importProductData(data).unwrap();
				toast.success(res.message);
				setData({});
				setColDefs([]);
				setShowImportDataModal(false);
			} else {
				alert('数据为空');
			}
		} catch (error) {
			toast.error(error.data.message);
		}
	};
	const importExcel = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = async (event) => {
			// parse data
			const bstr = event.target.result;
			const workBook = XLSX.read(bstr, { type: 'binary' });

			// get first sheet
			const workSheetName = workBook.SheetNames[0];
			const workSheet = workBook.Sheets[workSheetName];
			// convert to array
			const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

			const headers = fileData[0];
			const heads = headers.map((head) => ({ accessorKey: head, header: head }));

			setColDefs(heads);

			// removing header
			fileData.splice(0, 1);

			setData(convertToJson(headers, fileData));
		};

		if (file) {
			if (getExention(file)) {
				reader.readAsArrayBuffer(file);
			} else {
				alert('请选择Excel文件');
			}
		} else {
			setData({});
			setColDefs([]);
		}
	};
	return (
		<Modal show={showImportDataModal} backdrop='static' fullscreen={true}>
			<Modal.Header>
				<Modal.Title>批量导入产品</Modal.Title>
				<CloseButton
					onClick={() => {
						setShowImportDataModal(false);
						setData({});
						setColDefs([]);
					}}
				/>
			</Modal.Header>
			<Modal.Body>
				<input type='file' onChange={importExcel} className='mb-3' />
				<MaterialReactTable table={importDataTable} />
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleImportProduct}>开始上传{isImportingProductData && <Spinner size='sm' />}</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ProductBatchImportModal;
