import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useAdvanceTableContext } from '../../providers/AdvanceTableProvider';

const RecentPurchasesHeader = () => {
	const { getSelectedRowModel } = useAdvanceTableContext();

	return (
		<Row className='flex-between-center flex-column flex-sm-row'>
			<Col sm='auto' className='d-flex align-items-center pe-0'>
				<h5 className='fs-9 mb-0 text-nowrap py-2 py-xl-0'>待处理订单</h5>
			</Col>
			<Col sm='auto' className=''>
				{getSelectedRowModel().rows.length > 0 && (
					<div className='d-flex'>
						<Form.Select size='sm' aria-label='Bulk actions'>
							<option>批量操作</option>
							<option value='refund'>退款</option>
							<option value='delete'>删除</option>
							<option value='archive'>存档</option>
						</Form.Select>
						<Button type='button' variant='falcon-default' size='sm' className='ms-2'>
							<FontAwesomeIcon icon='check' />
						</Button>
					</div>
				)}

				{/* // 	<div id='orders-actions'>
				// 		<IconButton variant='falcon-default' size='sm' icon='plus' transform='shrink-3'>
				// 			<span className='ms-1'>新建</span>
				// 		</IconButton>
				// 	</div> */}
			</Col>
		</Row>
	);
};

export default RecentPurchasesHeader;
