import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Message from '../../components/Message';
import { useAcceptOrderMutation, useAddAccountsReceivableMutation, useDeliverOrderMutation } from '../../slices/ordersApiSlice';
import formatDate from '../../utils/formatDate';

const OrderDetailOperationCard = ({ order, refetch, placeOrderNode, deliverNode, acceptNode }) => {
	const { userInfo } = useSelector((state) => state.auth);
	const [deliverOrder, { isLoading: loadingDeliver }] = useDeliverOrderMutation();
	const [acceptOrder, { isLoading: loadingAccept }] = useAcceptOrderMutation();
	const [addOrderAccountsReceivable, { isLoading: savingAR }] = useAddAccountsReceivableMutation();

	const deliverHandler = async () => {
		try {
			// 检查订单是否有发货后收款计划节点
			if (order.scheduleNodes.length === 0) {
				await deliverOrder(order._id).unwrap();
				toast.success('订单已发货');
				await addOrderAccountsReceivable({
					orderId: order._id,
					details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: order.totalPrice },
				}).unwrap();
				refetch();
			} else {
				const deliverScheduleNode = order.scheduleNodes.filter((node) => node.title === '发货');
				if (deliverScheduleNode.length !== 0) {
					await deliverOrder(order._id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: deliverScheduleNode[0].paymentAmount, title: '发货', paymentDays: deliverScheduleNode[0].paymentDays, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				} else {
					await deliverOrder(order._id).unwrap();
					toast.success('订单已发货');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: 0, title: '发货', paymentDays: 0, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	const acceptHandler = async () => {
		try {
			// 检查订单是否有验收后收款计划节点
			if (order.scheduleNodes.length === 0) {
				await acceptOrder(order._id).unwrap();
				toast.success('订单已验收完毕');
				await addOrderAccountsReceivable({
					orderId: order._id,
					details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: order.totalPrice },
				}).unwrap();
				refetch();
			} else {
				const acceptScheduleNode = order.scheduleNodes.filter((node) => node.title === '验收');
				if (acceptScheduleNode.length !== 0) {
					await acceptOrder(order._id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: acceptScheduleNode[0].paymentAmount, title: '验收', paymentDays: acceptScheduleNode[0].paymentDays, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				} else {
					await acceptOrder(order._id).unwrap();
					toast.success('订单已验收完毕');
					await addOrderAccountsReceivable({
						orderId: order._id,
						details: { accountsReceivable: 0, title: '验收', paymentDays: 0, totalPrice: order.totalPrice },
					}).unwrap();
					refetch();
				}
			}
		} catch (err) {
			toast.error(err?.data?.message || err.message);
		}
	};

	return (
		<Card className='sticky-sidebar z-1'>
			<Card.Header>
				<Card.Title className='mb-0'>订单标记</Card.Title>
			</Card.Header>
			<ListGroup variant='flush'>
				<ListGroup.Item>
					<p>
						<strong>订单当前状态</strong>
					</p>
					{placeOrderNode.length !== 0 && !placeOrderNode[0]?.isPaid && (
						<Message>
							<FontAwesomeIcon icon='fa-user-clock' className='me-2' />
							待确认预付款
						</Message>
					)}
					{deliverNode.length !== 0 && !deliverNode[0]?.isPaid && (
						<Message>
							<FontAwesomeIcon icon='fa-user-clock' className='me-2' />
							待确认发货款
						</Message>
					)}
					{acceptNode.length !== 0 && !acceptNode[0]?.isPaid && (
						<Message>
							<FontAwesomeIcon icon='fa-user-clock' className='me-2' />
							待确认验收款
						</Message>
					)}

					{!order.isDelivered ? (
						<Message>
							<FontAwesomeIcon icon='fa-people-carry-box' className='me-2' />
							待发货/安装/提供服务
						</Message>
					) : !order.isAccepted ? (
						<Message>
							<FontAwesomeIcon icon='fa-list-check' className='me-2' />
							待验收
						</Message>
					) : !order.isCompleted ? (
						<Message>
							<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
							待完成订单
						</Message>
					) : (
						<Message variant='success'>
							<FontAwesomeIcon icon='check-circle' className='me-2' />
							订单已全部完成
						</Message>
					)}
				</ListGroup.Item>
				{userInfo.role === 'buyer' && !order.isDelivered && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={deliverHandler} className='m-3' disabled={!placeOrderNode[0]?.isPaid}>
							<FontAwesomeIcon icon='fa-solid fa-stamp' className='me-2' />
							标记为已发货 {loadingDeliver && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}
				{['sale', 'assistant', 'manager'].includes(userInfo.role) && !order.isAccepted && (
					<ListGroup.Item>
						<p>
							<strong>操作订单</strong>
						</p>
						<Button onClick={acceptHandler} className='m-3' disabled={!placeOrderNode[0]?.isPaid}>
							<FontAwesomeIcon icon='fa-regular fa-square-check' className='me-2' />
							验收完成{loadingAccept && <Spinner size='sm' />}
							{savingAR && <Spinner size='sm' />}
						</Button>
					</ListGroup.Item>
				)}
				<ListGroup.Item>
					<strong>操作记录</strong>
					<ul className='timeline-order m-3'>
						<li className='timeline-item'>
							<strong>
								{order.isDelivered ? (
									<Message variant='success'>
										<FontAwesomeIcon icon='fa-truck-fast' className='me-2' />
										订单已发货在 {formatDate(order.deliveredAt)}
									</Message>
								) : (
									<Message variant='warning'>
										<FontAwesomeIcon icon='fa-solid fa-cubes' className='me-2' />
										未发货
									</Message>
								)}
							</strong>
						</li>
						<li className='timeline-item'>
							<strong>
								{order.isAccepted ? (
									<Message variant='success'>
										<FontAwesomeIcon icon='fa-box-open' className='me-2' />
										订单已验收在 {formatDate(order.acceptedAt)}
									</Message>
								) : (
									<Message variant='warning'>
										<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
										等待验收
									</Message>
								)}
							</strong>
						</li>
						<li className='timeline-item'>
							<strong>
								{order.isCompleted ? (
									<Message variant='success'>
										<FontAwesomeIcon icon='fa-thumbs-up' className='me-2' />
										订单已完成在 {formatDate(order.completedAt)}
									</Message>
								) : (
									<Message variant='warning'>
										<FontAwesomeIcon icon='fa-clipboard-question' className='me-2' />
										待完成订单
									</Message>
								)}
							</strong>
						</li>
					</ul>
				</ListGroup.Item>
			</ListGroup>
		</Card>
	);
};

export default OrderDetailOperationCard;
