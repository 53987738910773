/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { useGetApprovalByIdQuery, useUpdateApprovalByIdMutation, useUpdateCustomerApprovalByIdMutation } from '../../slices/approvalsApiSlice';
import { useCheckCustomerNameMutation, useCreateCustomerMutation } from '../../slices/customersApiSlice';
import createMarkup from '../../utils/createMarkup';

const QuotaApprovalDetailScreen = () => {
	const { id: approvalId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const { data: approval, isLoading: loadingApproval, error: loadingApprovalError, refetch } = useGetApprovalByIdQuery(approvalId);
	console.log(approval);
	const [updateCustomerApprovalStatus, { isLoading: updatingApproval, error: updateError }] = useUpdateCustomerApprovalByIdMutation();

	const [checkCustomerName, { isLoading: nameChecking }] = useCheckCustomerNameMutation();

	const [createCustomer, { isLoading: Creating, error }] = useCreateCustomerMutation();

	useCallback(() => {
		async function fetchData() {
			await checkCustomerName({ newCustomerType: approval.detail?.newCustomerType, newCustomerName: approval.detail?.newCustomerName }).unwrap();
		}
		if (!loadingApproval) {
			try {
				return fetchData();
			} catch (error) {
				toast.error(error?.data?.message || error.error);
			}
		}
	}, []);

	const handlePass = async () => {
		try {
			await checkCustomerName({ newCustomerType: approval.detail?.newCustomerType, newCustomerName: approval.detail?.newCustomerConcatName }).unwrap();

			await updateCustomerApprovalStatus({
				_id: approvalId,
				status: 'approved',
			}).unwrap();
			toast.success('审批通过');
			await createCustomer({
				type: approval.detail?.newCustomerType,
				name: approval.detail?.newCustomerConcatName,
				organization: approval.detail?.newCustomerOrganization,
				department: approval.detail?.newCustomerDepartment,
				group: approval.detail?.newCustomerGroup,
				city: approval.detail?.newCustomerCity,
				address: approval.detail?.newCustomerAddress,
				phone: approval.detail?.newCustomerPhone,
				email: approval.detail?.newCustomerEmail,
				applicant: approval.applicant._id,
			}).unwrap();
			toast.success(`${approval.detail?.newCustomerConcatName}客户创建成功`);
			navigate('/approval/list');
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleUnagree = async () => {
		// 更新驳回理由
		await updateCustomerApprovalStatus({
			_id: approvalId,
			status: 'reject',
		});

		toast.success('申请已驳回');
		navigate('/approval/list');
	};

	const handleRenew = async () => {
		// 再次提交
		await updateCustomerApprovalStatus({
			_id: approvalId,
			status: 'reject',
		});

		toast.success('重新提交成功');
		navigate('/approval/list');
	};
	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<div>
			<Button
				className='mb-3'
				onClick={() => {
					navigate('/approval/list');
				}}
			>
				返回列表
			</Button>

			{loadingApproval ? (
				<Loader />
			) : (
				<>
					{/* {<Message variant={approval.status}>审批状态: {approval.status.content} </Message>} */}
					<h4>{approval?.title}</h4>
					<p className='fs--1 ps-2 mb-3'>
						销售员: <strong>{approval?.applicant.name}</strong>
					</p>
					{approval?.assistant._id && (
						<p className='fs--1 ps-2 mb-3'>
							申请人: <strong>{approval?.assistant.name}</strong>
						</p>
					)}
					<p className='fs--1 ps-2 mb-0' dangerouslySetInnerHTML={createMarkup(approval?.reason)}></p>
					{userInfo.canApproval && approval.status === 'waitingApproval' && (
						<>
							<Button variant='success' onClick={handlePass}>
								<FontAwesomeIcon icon='check' /> 同意
							</Button>
							<Button className='ms-3' variant='outline-danger' onClick={handleUnagree}>
								<FontAwesomeIcon icon='xmark' /> 不同意
							</Button>
						</>
					)}
					{!userInfo.canApproval && approval.status === 'reject' && (
						<>
							<Button className='mx-1' variant='success' onClick={handleRenew}>
								<FontAwesomeIcon icon='fa-solid fa-rotate-right' /> 再次申请
							</Button>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default QuotaApprovalDetailScreen;
