import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { setCredentials } from '../slices/authSlice';
import { useGetMyOrdersQuery } from '../slices/ordersApiSlice';
import { useProfileMutation } from '../slices/usersApiSlice';
import formatDate from '../utils/formatDate';

const ProfileScreen = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');

	const [isOpenModifyPassword, setIsOpenModifyPassword] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userInfo } = useSelector((state) => state.auth);

	const {
		handleSubmit,
		register,
		getValues,
		formState: { errors },
		clearErrors,
	} = useForm();

	const [updateProfile, { isLoading: loadingUpdateProfile }] = useProfileMutation();

	const { data: orders, isLoading, error } = useGetMyOrdersQuery();
	useEffect(() => {
		if (userInfo) {
			setName(userInfo.name);
			setEmail(userInfo.email);
		}
	}, [userInfo, userInfo.name, userInfo.email]);

	const submitHandler = async (data) => {
		try {
			const res = await updateProfile({ _id: userInfo._id, name, email, password: data.password }).unwrap();
			dispatch(setCredentials(res));
			toast.success('密码修改成功!');
		} catch (err) {
			toast.error(err?.data?.message || err.error);
		}
	};

	return (
		<>
			<Row className='mb-3'>
				<Col>
					<Card>
						<Card.Body>
							<Button
								variant='falcon-primary'
								onClick={() => {
									navigate('/dashboard');
								}}
							>
								返回首页
							</Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col md={3}>
					<Card>
						<Card.Header>
							<h5>个人资料</h5>
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleSubmit(submitHandler)}>
								<Form.Group controlId='name' className='my-2'>
									<Form.Label>名字</Form.Label>
									<Form.Control type='name' placeholder='...' value={name} onChange={(e) => setName(e.target.value)} disabled />
								</Form.Group>
								<Form.Group controlId='email' className='my-2'>
									<Form.Label>邮箱地址</Form.Label>
									<Form.Control type='name' placeholder='...' value={email} onChange={(e) => setEmail(e.target.value)} disabled />
								</Form.Group>
								<Button
									variant='falcon-danger'
									className='mt-2'
									onClick={(e) => {
										setIsOpenModifyPassword(!isOpenModifyPassword);
										clearErrors();
									}}
								>
									{isOpenModifyPassword ? '取消修改' : '修改密码'}
								</Button>
								{isOpenModifyPassword && (
									<>
										<Form.Group controlId='password' className='my-2'>
											<Form.Label>新密码</Form.Label>
											<Form.Control type='password' placeholder='重设密码' {...register('password', { required: '请输入新密码' })} isInvalid={!!errors.password} />
											<Form.Control.Feedback type='invalid'>{errors.password?.message}</Form.Control.Feedback>
										</Form.Group>

										<Form.Group controlId='confirmPassword' className='my-2'>
											<Form.Label>确认密码</Form.Label>
											<Form.Control
												type='password'
												placeholder='再次输入密码'
												{...register('confirmPassword', {
													required: '请再此输入新的密码',
													validate: {
														matchesPreviousPassword: (value) => {
															const { password } = getValues();
															return password === value || '密码应该相同!';
														},
													},
												})}
												isInvalid={!!errors.confirmPassword}
											/>
											<Form.Control.Feedback type='invalid'>{errors.confirmPassword?.message}</Form.Control.Feedback>
										</Form.Group>
										{loadingUpdateProfile ? (
											<Spinner size='sm' />
										) : (
											<Button type='submit' variant='primary' className='my-2'>
												保存并更新
											</Button>
										)}
									</>
								)}
							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col md={9}>
					<Card>
						<Card.Header>
							<h5>我的订单</h5>
						</Card.Header>
						<Card.Body>
							{isLoading ? (
								<Loader />
							) : error ? (
								<Message>{error?.data?.message || error.error}</Message>
							) : (
								<Table striped hover responsive className='table-lg'>
									<thead>
										<tr>
											<th>订单编号</th>
											<th>订单日期</th>
											<th>总价</th>
										</tr>
									</thead>
									<tbody>
										{orders.map((order) => (
											<tr key={order._id}>
												<td>
													{userInfo && (
														<LinkContainer to={`/order/${order._id}`}>
															<Link>{order.orderId}</Link>
														</LinkContainer>
													)}
												</td>
												<td>{formatDate(order.createdAt)}</td>
												<td>{order.totalPrice}</td>
											</tr>
										))}
									</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default ProfileScreen;
