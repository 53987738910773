export const weeklySalesData = [6000, 9000, 8500, 4000, 4500, 6500, 7000];

export const totalOrder = [20, 40, 100, 120];

export const marketShare = [
	{ id: 1, value: 53000000, name: 'Samsung', color: 'primary' },
	{ id: 2, value: 19000000, name: 'Huawei', color: 'info' },
	{ id: 3, value: 20000000, name: 'Apple', color: 'gray-300' },
];
export const weather = {
	city: 'New York City',
	condition: 'Sunny',
	precipitation: '50%',
	temperature: 31,
	highestTemperature: 32,
	lowestTemperature: 25,
};

export const totalSales = [
	[60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70],
	[100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50],
	[80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60],
	[70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80],
	[90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50],
	[80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70],
	[20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50],
	[60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110],
	[90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70],
	[50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110],
	[60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70],
	[20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70],
];
