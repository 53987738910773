/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useDeleteCustomerStaffMutation, useGetCustomersQuery } from '../../slices/customersApiSlice';
//Import Material React Table Translations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useSelector } from 'react-redux';
import Background from '../../components/common/Background';

import { toast } from 'react-toastify';
import SystemAlert from '../../components/modal/SystemAlert';
import corner1 from './../../assets/img/illustrations/authentication-corner.png';
import AddStaffToCustomerModal from './AddStaffToCustomerModal';
import CustomerCreateApplyModal from './CustomerCreateApplyModal';
import EditStaffToCustomerModal from './EditStaffToCustomerModal';
import SelectSaleModal from './SelectSaleModal';

const CustomerListScreen = () => {
	const [validationErrors, setValidationErrors] = useState({});
	const { userInfo } = useSelector((state) => state.auth);
	const [selectSaleModalShow, setSelectSaleModalShow] = useState(false);
	const [applyModalShow, setApplyModalShow] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteStaffConfirmModalShow, setDeleteStaffConfirmModalShow] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState({});
	const [selectedCustomer, setSelectedCustomer] = useState({});
	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: '客户名称',
				visibleInShowHideMenu: false,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.name,
					helperText: validationErrors?.name,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							name: undefined,
						}),
				},
			},
			{
				accessorKey: 'type',
				header: '客户类型',
				visibleInShowHideMenu: false,
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.type,
					helperText: validationErrors?.type,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							type: undefined,
						}),
				},
			},
			{
				accessorKey: 'city',
				header: '城市',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.city,
					helperText: validationErrors?.city,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							city: '',
						}),
				},
			},
			{
				accessorKey: 'address',
				header: '详细地址',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.deliveryAddress,
					helperText: validationErrors?.deliveryAddress,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							deliveryAddress: '',
						}),
				},
			},
			{
				accessorKey: 'sale.name',
				header: '销售员',
				filterVariant: 'autocomplete',
				enableEditing: false,
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				enableEditing: false,
			},
		],
		[validationErrors]
	);

	const { data: fetchedCustomers = [], isError: isLoadingCustomersError, isFetching: isFetchingCustomers, isLoading: isLoadingCustomers } = useGetCustomersQuery();
	const [deleteStaff, { isLoading: isDeletingStaff }] = useDeleteCustomerStaffMutation();

	const handleDeleteStaff = async () => {
		await deleteStaff({ customerId: selectedCustomer._id, staffId: selectedStaff._id }).unwrap();
		toast.success('已删除');
		setDeleteStaffConfirmModalShow(false);
		setSelectedCustomer({});
		setSelectedCustomer({});
	};
	const handleCancelDeleteStaff = () => {
		setDeleteStaffConfirmModalShow(false);
		setSelectedCustomer({});
		setSelectedCustomer({});
	};
	const table = useMaterialReactTable({
		columns,
		data: fetchedCustomers,
		createDisplayMode: 'modal',
		editDisplayMode: 'row',
		enableDensityToggle: false,
		enableExpandAll: true,
		enableSorting: true,
		enableGrouping: true,
		enableRowSelection: (row) => userInfo.role === 'manager',
		getRowId: (row) => row._id,
		enableFullScreenToggle: true,
		enableMultiRowSelection: true,
		localization: MRT_Localization_ZH_HANS,
		maxLeafRowFilterDepth: 2,
		positionToolbarAlertBanner: 'bottom',
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps: isLoadingCustomersError
			? {
					color: 'error',
					children: '错误的加载数据',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				minHeight: '500px',
			},
		},
		initialState: {
			density: 'compact',
			sorting: [
				{
					id: 'createdAt',
					desc: true,
				},
			],
			expanded: false,
			pagination: { pageIndex: 0, pageSize: 50 },
			columnVisibility: { createdAt: false, 'user.name': false },
		},
		onCreatingRowCancel: () => setValidationErrors({}),

		onEditingRowCancel: () => setValidationErrors({}),

		renderTopToolbarCustomActions: () => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				{userInfo.role === 'assistant' ? (
					<Button variant='falcon-primary' size='sm' className='falcon-primary ms-2 mb-1' type='button' onClick={() => setSelectSaleModalShow(true)}>
						为销售员申请添加客户
					</Button>
				) : (
					<Button variant='falcon-primary' size='sm' className='falcon-primary ms-2 mb-1' type='button' onClick={() => setApplyModalShow(true)}>
						申请添加客户
					</Button>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingCustomers,
			showAlertBanner: isLoadingCustomersError,
			showProgressBars: isFetchingCustomers,
		},

		muiDetailPanelProps: () => ({
			sx: () => ({
				marginTop: '0px',
				backgroundColor: '#fff',
			}),
		}),
		//custom expand button rotation
		muiExpandButtonProps: ({ row, table }) => ({
			onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
			sx: {
				transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
				transition: 'transform 0.2s',
			},
		}),

		//conditionally render detail panel
		renderDetailPanel: ({ row }) =>
			userInfo._id === row.original.sale?._id || userInfo.role === 'assistant' || userInfo.role === 'manager' ? (
				row.original.staffs.length !== 0 ? (
					<Card>
						<Background
							image={corner1}
							className='bg-card d-none d-md-block'
							style={{
								borderTopRightRadius: '0.375rem',
								borderBottomRightRadius: '0.375rem',
							}}
						/>
						<Card.Header className='position-relative'>
							<Card.Title>
								<Row className='flex-between-center'>
									<Col md>
										<h6 className='mb-2 mb-md-0 me-1'>联系人列表</h6>
									</Col>
									<Col xs='auto'>
										<AddStaffToCustomerModal selectedCustomer={row.original} />
									</Col>
								</Row>
							</Card.Title>
						</Card.Header>
						<Card.Body className='position-relative'>
							<Table striped hover responsive className='table-sm text-primary'>
								<thead>
									<tr key={row.original._id}>
										<th scope='col'>序号</th>
										<th scope='col'>联系人</th>
										<th scope='col'>邮箱</th>
										<th scope='col'>电话</th>
										<th scope='col'>微信</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{row.original.staffs?.map((staff, index) => (
										<tr key={staff._id} className='hover-actions-trigger'>
											<td className='align-middle text-nowrap'>{index + 1}</td>
											<td className='align-middle text-nowrap'>{staff.name}</td>
											<td className='align-middle text-nowrap'>{staff.email}</td>
											<td className='align-middle text-nowrap'>{staff.phone}</td>
											<td className='align-middle text-nowrap'>{staff.wechat}</td>
											<td>
												<div className='end-0 top-50 pe-3 translate-middle-y hover-actions'>
													<Button
														size='sm'
														variant='outline-secondary'
														className='border-none trasparent me-2'
														onClick={() => {
															setSelectedStaff(staff);
															setSelectedCustomer(row.original);
															setEditModalShow(true);
														}}
													>
														<FontAwesomeIcon icon='fa-solid fa-pencil' />
													</Button>
													<Button
														size='sm'
														variant='outline-danger'
														className='border-none trasparent'
														onClick={() => {
															setSelectedStaff(staff);
															setSelectedCustomer(row.original);
															setDeleteStaffConfirmModalShow(true);
														}}
													>
														<FontAwesomeIcon icon='fa-solid fa-trash' />
													</Button>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				) : (
					<AddStaffToCustomerModal selectedCustomer={row.original} />
				)
			) : null,
	});

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									客户列表
								</li>
							</ol>
						</nav>
					</Card.Title>
				</Card.Header>
				<Card.Body className='p-0'>
					<MaterialReactTable table={table} />
				</Card.Body>
			</Card>
			{/* Modal */}
			<EditStaffToCustomerModal
				setEditModalShow={setEditModalShow}
				editModalShow={editModalShow}
				selectedCustomer={selectedCustomer}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				setSelectedCustomer={setSelectedCustomer}
			/>
			<CustomerCreateApplyModal setApplyModalShow={setApplyModalShow} applyModalShow={applyModalShow} applicant={userInfo} />
			<SelectSaleModal selectSaleModalShow={selectSaleModalShow} setSelectSaleModalShow={setSelectSaleModalShow} />

			<SystemAlert
				title='删除提醒'
				bodyText={`确定删除 ${selectedCustomer.name} 的 ${selectedStaff.name} 吗？`}
				cancelText='先不删'
				confirmText='确定删除'
				confirmColor='danger'
				confirmWaiting={isDeletingStaff}
				modalShow={deleteStaffConfirmModalShow}
				setModalShow={handleCancelDeleteStaff}
				handleConfirm={handleDeleteStaff}
			/>
		</>
	);
};

export default CustomerListScreen;
