import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
const PrivateRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);

	return userInfo ? <Outlet /> : <Navigate to='/loginout' replace />;
};
const AdminRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'admin' ? <Outlet /> : <Navigate to='/' replace />;
};

const ManagerRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'manager' ? <Outlet /> : <Navigate to='/' replace />;
};

const FinanceRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'finance' ? <Outlet /> : <Navigate to='/' replace />;
};
const BuyerRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'buyer' ? <Outlet /> : <Navigate to='/' replace />;
};

const UserRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'user' ? <Outlet /> : <Navigate to='/' replace />;
};

const AssistantRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'assistant' ? <Outlet /> : <Navigate to='/' replace />;
};
const SaleRoute = () => {
	const { userInfo } = useSelector((state) => state.auth);
	return userInfo && userInfo.role === 'sale' ? <Outlet /> : <Navigate to='/' replace />;
};

export { PrivateRoute, AdminRoute, ManagerRoute, FinanceRoute, BuyerRoute, UserRoute, AssistantRoute, SaleRoute };
