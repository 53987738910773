import { Card, Col, Row } from 'react-bootstrap';

import OrderButton from './OrderButton';

const OrderFooter = () => {
	return (
		<>
			<Card>
				<Card.Body>
					<Row className='flex-between-center'>
						<Col sm={2} md={6} lg={7} xl={6}>
							<h5 className='mb-2 mb-md-0 me-1'>你已全部完成</h5>
						</Col>
						<Col sm={2} md={6} lg={6} xl={6} xxl={6}>
							<OrderButton />
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderFooter;
