import React from 'react';
import QuickOperateButton from './QuickOperateButton';

const QuickLinks = () => {
	return (
		<div style={{ position: 'sticky', top: '10px' }}>
			<QuickOperateButton />
		</div>
	);
};

export default QuickLinks;
