import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useInitiateQuotaApprovalMutation } from '../../../../slices/approvalsApiSlice';
import { toast } from 'react-toastify';
import classNames from 'classnames';

const CustomerQuotaApplyModal = () => {
	const order = useSelector((state) => state.order);
	const { customer } = order;
	const [modalShow, setModalShow] = useState(false);
	const [initiateQuotaApproval, { isLoading: loadingApproval }] = useInitiateQuotaApprovalMutation();
	const { register, handleSubmit } = useForm();

	const submitQuotaApproval = async (data) => {
		console.info(data);
		try {
			await initiateQuotaApproval({
				customer: customer._id,
				customerName: customer.name,
				applyQuota: data.applyQuota,
				rawReason: data.applyReason,
				type: 'quota',
			}).unwrap();
			setModalShow(false);
			toast.success('额度申请成功, 请耐心等待');
		} catch (err) {
			toast.error(err.data.message);
		}
	};

	return (
		<>
			<Button variant='outline-primary' size='sm' as={Link} className='fw-medium p-1 mx-2 mb-1' type='button' onClick={() => setModalShow(true)}>
				申请额度
			</Button>

			<Modal show={modalShow} onHide={() => setModalShow(false)} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
				<Form
					onSubmit={(e) => {
						// Resolve issue: How to prevent submit in nested form
						// this part is for stopping parent forms to trigger their submit
						if (e) {
							// sometimes not true, e.g. React Native
							if (typeof e.preventDefault === 'function') {
								e.preventDefault();
							}
							if (typeof e.stopPropagation === 'function') {
								// prevent any outer forms from receiving the event too
								e.stopPropagation();
							}
							return handleSubmit(submitQuotaApproval)(e);
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>申请额度</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>客户名称:{customer ? customer.name : '请选择客户'} </p>
						<p>联系人: {customer ? customer.person : '请选择客户'}</p>
						<p>联系电话: {customer ? customer.phone : '请选择客户'}</p>
						<Form.Group as={Row} className='g-1 mb-3' controlId='applyQuota'>
							<Form.Label column sm='3'>
								申请额度金额(元)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='applyQuota' placeholder='请填写申请金额' autoComplete='false' {...register('applyQuota', { valueAsNumber: true })} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='applyReason'>
							<Form.Label column sm='3'>
								申请说明
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									as='textarea'
									row={10}
									style={{ height: '150px' }}
									name='applyReason'
									placeholder='请填写申请理由'
									autoComplete='false'
									{...register('applyReason')}
								/>
							</Col>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setModalShow(false)}>
							关闭
						</Button>
						<Button variant='primary' type='submit' disabled={loadingApproval} className='d-inline-flex flex-center'>
							{loadingApproval && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
							<span className={classNames({ 'visually-hidden': loadingApproval })}>提交</span>
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default CustomerQuotaApplyModal;
