import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import CustomerApprovalListTable from './CustomerApprovalListTable';
import OrderApprovalListTable from './OrderApprovalListTable';
const ApprovalListScreen = () => {
	return (
		<Card className='mb-3'>
			<Card.Header>
				<Card.Title className='mb-2'>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<a href='/dashboard'>
									<FontAwesomeIcon icon='home' className='me-2' />
									首页
								</a>
							</li>
							<li className='breadcrumb-item ' aria-current='page'>
								审批列表
							</li>
						</ol>
					</nav>
				</Card.Title>
			</Card.Header>
			<Card.Body className='p-0'>
				<Tabs defaultActiveKey='order'>
					<Tab eventKey='order' title='订单审批' className='border-bottom border-x p-3'>
						<OrderApprovalListTable />
					</Tab>
					<Tab eventKey='customer' title='客户审批' className='border-bottom border-x p-3'>
						<CustomerApprovalListTable />
					</Tab>
					<Tab eventKey='quota' title='额度审批' className='border-bottom border-x p-3'>
						<CustomerApprovalListTable />
					</Tab>
				</Tabs>
			</Card.Body>
		</Card>
	);
};

export default ApprovalListScreen;
