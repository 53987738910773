import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import OrderProductListTable from './table/OrderProductListTable';
import SelectOrderProductModal from './table/SelectOrderProductModal';

const OrderProduct = () => {
	const [modalShow, setModalShow] = useState(false);

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title>
						<Row className='flex-between-center'>
							<Col md>
								<h6 className='mb-2 mb-md-0 me-1'>订单产品</h6>
							</Col>
							<Col xs='auto'>
								<Button variant='falcon-primary' size='sm' className='rounded-pill' type='button' onClick={() => setModalShow(true)}>
									添加产品 <FontAwesomeIcon icon='fa-solid fa-cubes' className='ms-1' />
								</Button>
							</Col>
						</Row>
					</Card.Title>
				</Card.Header>
				<Card.Body className='p-0'>
					<OrderProductListTable />
				</Card.Body>
			</Card>
			{/* Modal */}
			<SelectOrderProductModal modalShow={modalShow} setModalShow={setModalShow} />
		</>
	);
};

export default OrderProduct;
