import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { useCreateProductMutation, useDeleteProductMutation, useGetProductsQuery, useUpdateProductMutation } from '../slices/productsApiSlice';

export const ProductContext = createContext({ products: [] });

const ProductProvider = ({ children }) => {
	// call READ hook
	const { data: fetchedProducts = [], isError: isLoadingProductsError, isFetching: isFetchingProducts, isLoading: isLoadingProducts, refetch } = useGetProductsQuery();

	// call CREATE hook
	const [createProduct, { isPending: isCreatingProduct }] = useCreateProductMutation();

	// // call READ hook
	// const { data: fetchedProducts = [], isError: isLoadingProductsError, isFetching: isFetchingProducts, isLoading: isLoadingProducts, refetch } = useGetProductsQuery();

	// call UPDATE hook
	const [updateProduct, { isPending: isUpdatingProduct }] = useUpdateProductMutation();

	// call DELETE hook
	const [deleteProduct, { isPending: isDeletingProduct }] = useDeleteProductMutation();

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<ProductContext.Provider
			value={{
				fetchedProducts,
				isLoadingProductsError,
				isLoadingProducts,
				isFetchingProducts,
				refetch,
				createProduct,
				isCreatingProduct,
				updateProduct,
				isUpdatingProduct,
				deleteProduct,
				isDeletingProduct,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};

ProductProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useProductContext = () => useContext(ProductContext);

export default ProductProvider;
