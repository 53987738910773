//useEffect check to see if there any userInfo in localstorage, and if there is,that means you're logged in so it will then want to redirect from this page
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import { toast } from 'react-toastify';
import genechampNomalLogo from '../assets/genechamp-logo-img.png';
import FormContainer from '../components/FormContainer';
import { setCredentials } from '../slices/authSlice';
import { useLoginMutation } from '../slices/usersApiSlice';

const LoginScreen = () => {
	const [validated, setValidated] = useState(false);
	const [tooltip] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [login, { isLoading }] = useLoginMutation();

	const { userInfo } = useSelector((state) => state.auth);

	// after login should redirect
	const { search } = useLocation();
	const sp = new URLSearchParams(search);

	const redirectParam = sp.get('redirect');

	// const redirect = redirectParam ? redirectParam : ['admin'].includes(userInfo?.role) ? `/${userInfo?.role}/dashboard` : '/dashboard';
	const redirect = redirectParam ? redirectParam : '/dashboard';

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [userInfo, redirect, navigate]);

	const submitHandler = async (e) => {
		e.preventDefault();
		try {
			const res = await login({ email, password }).unwrap();

			dispatch(setCredentials({ ...res }));

			navigate(redirect);
		} catch (err) {
			toast.error(err?.data?.message || err.error, {
				position: 'top-center',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'colored',
			});
			setValidated(false);
		}
	};

	return (
		<Row className='d-flex flex-center'>
			<Col className='col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 position-relative d-flex flex-column min-vh-100 justify-content-center align-items-center'>
				<img src={genechampNomalLogo} alt='' height='80px' />
				<h4 className='text-white fw-light text-center pt-4 mb-3'>
					<span className='fw-bold'>
						<Typewriter
							words={['捷采生物销售管理系统', '精准助力 · 便捷采购']}
							loop={false}
							cursor='true'
							cursorStyle='|'
							cursorColor={`#e29b38`}
							typeSpeed={70}
							deleteSpeed={50}
							delaySpeed={5000}
						/>
					</span>
				</h4>

				<FormContainer>
					<Form noValidate validated={validated} onSubmit={submitHandler}>
						<Form.Group className='mb-3'>
							<Form.Control
								type='email'
								placeholder='邮箱'
								value={email}
								onChange={(e) => {
									setEmail(e.target.value);
									const form = e.currentTarget;
									form.checkValidity() ? setValidated(true) : setValidated(false);
								}}
								required
								className='transparent-input text-white'
							/>
							<Form.Control.Feedback tooltip={tooltip}>看起来很好！</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId='password' className='mb-3'>
							<Form.Control
								type='password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
								autoComplete='off'
								placeholder='密码'
								className='transparent-input text-white'
							/>
							<Form.Control.Feedback tooltip={tooltip}>非常好</Form.Control.Feedback>
						</Form.Group>
						<Button type='submit' variant='outline-primary' className='col-12' size='lg' disabled={isLoading}>
							{isLoading && <Spinner size='sm' />} <span>登录</span>
						</Button>
					</Form>
				</FormContainer>
				<h6 className='mt-2'>
					<span className='text-white'>PringXSoft | 2024 &copy; v1.0.0</span>
				</h6>
			</Col>
		</Row>
	);
};

export default LoginScreen;
