import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button } from 'react-bootstrap';
import { useGetOrdersQuery } from '../../slices/ordersApiSlice';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { FaTimes } from 'react-icons/fa';

const OrderListAdminScreen = () => {
	const { data: orders, isLoading, error } = useGetOrdersQuery();

	return (
		<>
			<nav aria-label='breadcrumb'>
				<ol className='breadcrumb'>
					<li className='breadcrumb-item'>
						<a href='/'>首页</a>
					</li>
					<li className='breadcrumb-item active' aria-current='page'>
						所有订单
					</li>
				</ol>
			</nav>
			{isLoading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Table striped hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>订单编号</th>
								<th>创建人</th>
								<th>创建日期</th>
								<th>总价</th>
								<th>付款</th>
								<th>发货</th>
								<th>签收</th>
								<th>安装</th>
								<th>验收</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{orders?.map((order) => (
								<tr key={order._id}>
									<td>{order.orderId}</td>
									<td>{order.user && order.user.name}</td>
									<td>{order.createdAt.substring(0, 10)}</td>
									<td>
										<span className='text-primary'>
											<strong>¥{order.totalPrice}</strong>
										</span>
									</td>
									<td>{order.isPaid ? order.paidAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
									<td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
									<td>{order.isReceipted ? order.receiptedAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
									<td>{order.isInstalled ? order.installedAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
									<td>{order.isCompleted ? order.completedAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
									<td>
										<LinkContainer to={`/order/detail/${order._id}`}>
											<Button variant='light' className='btn-sm'>
												查看订单
											</Button>
										</LinkContainer>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</>
			)}
		</>
	);
};

export default OrderListAdminScreen;
