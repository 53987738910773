import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import loginoutImg from '../assets/img/icons/spot-illustrations/45.png';

const LoginoutScreen = () => {
	return (
		<>
			<Row className='d-flex flex-center'>
				<Col className='col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-3 position-relative d-flex flex-column min-vh-100 justify-content-center align-items-center'>
					<div className='text-center'>
						<img
							className='d-block mx-auto mb-4'
							src={loginoutImg}
							alt='shield'
							width={100}
						/>
						<p className='text-white'>
							感谢您访问捷采生物系统，您现已
							<br className='d-none d-sm-block' />
							成功登出 !
						</p>
						<Button
							as={Link}
							color='primary'
							size='sm'
							className='mt-3'
							to={`/`}
						>
							<FontAwesomeIcon
								icon='chevron-left'
								transform='shrink-4 down-1'
								className='me-1'
							/>
							返回去登录
						</Button>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default LoginoutScreen;
