/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearCustomer, saveAssistant, saveOrderId, saveOrderIndexOfDay, saveQuotationId } from '../../../../slices/orderSlice';
import { useGetAssistantUsersQuery } from '../../../../slices/usersApiSlice';

const SelectOrderAssistantModal = ({ modalShow, setModalShow }) => {
	const dispatch = useDispatch();

	const [rowSelection, setRowSelection] = useState([]);
	const {
		data: fetchedAssistantUsers = [],
		isError: isLoadingAssistantUsersError,
		isFetching: isFetchingAssistantUsers,
		isLoading: isLoadingAssistantUsers,
		refetch,
	} = useGetAssistantUsersQuery();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: '销售助理',
			},
			{
				accessorKey: 'email',
				header: '邮箱',
			},
			{
				accessorKey: 'phone',
				header: '联系人电话',
			},
			{
				accessorKey: 'alias',
				header: '联系人电话',
			},
		],
		[]
	);
	const hanldeSelectedAssistant = async () => {
		const rows = table.getSelectedRowModel().rows; //or read entire rows
		const original = rows.map((row) => row.original);

		if (original.length !== 0) {
			dispatch(saveAssistant(original[0]));
			toast.success('销售助理添加成功！');
			setRowSelection([]);
			setModalShow(false);
		} else {
			toast.warning('请选择一位销售助理');
		}
	};
	const table = useMaterialReactTable({
		columns,
		data: fetchedAssistantUsers,
		enableColumnResizing: true,
		createDisplayMode: 'modal',
		editDisplayMode: 'custom',
		enableRowSelection: true,
		enableMultiRowSelection: false,
		getRowId: (row) => row._id,
		enableGrouping: true,
		enableFullScreenToggle: true,
		localization: MRT_Localization_ZH_HANS,
		positionToolbarAlertBanner: 'bottom',
		initialState: {
			expanded: true, //expand all rows by default
			pagination: { pageIndex: 0, pageSize: 50 },
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps: isLoadingAssistantUsersError
			? {
					color: 'error',
					children: '数据加载错误',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				maxHeight: '500px',
			},
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				{!isLoadingAssistantUsers && !isFetchingAssistantUsers && fetchedAssistantUsers.length !== 0 && (
					<>
						<Button size='sm' variant='primary' as={Link} onClick={hanldeSelectedAssistant}>
							确定
						</Button>
					</>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingAssistantUsers,
			showAlertBanner: isLoadingAssistantUsersError,
			showProgressBars: isFetchingAssistantUsers,
		},
	});

	return (
		<Modal show={modalShow} size='lg' onHide={() => setModalShow(false)} aria-labelledby='contained-modal-title-vcenter' centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>选择销售助理</Modal.Title>
			</Modal.Header>
			<Modal.Body className='p-0'>
				<MaterialReactTable table={table} />
			</Modal.Body>
		</Modal>
	);
};

export default SelectOrderAssistantModal;
