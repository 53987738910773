import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, CloseButton, Col, Form, Modal, OverlayTrigger, ProgressBar, Row, Table, Tooltip } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import Flex from '../../../../components/common/Flex';
import { saveScheduleNodes } from '../../../../slices/orderSlice';
import { useDispatch, useSelector } from 'react-redux';

const AddPaymentScheduleModal = ({ isOpenScheduleModal, setIsOpenScheduleModal, prePaymentPercent, percentBalance, percentPayment }) => {
	const validated = false;
	const dispatch = useDispatch();
	const [processNow, setProcessNow] = useState(0);
	const [preProcessNow, setPreProcessNow] = useState(0);
	const { handleSubmit, control } = useForm();
	const { totalPrice } = useSelector((state) => state.order);

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'scheduleNodes',
	});

	const [scheduleNode, setScheduleNode] = useState({
		title: '',
		paymentDays: 0,
		paymentPercent: 0,
		paymentAmount: 0,
	});

	const handleClose = () => {
		setIsOpenScheduleModal(!isOpenScheduleModal);
	};

	const submitScheduleItems = async (data, event) => {
		dispatch(saveScheduleNodes(data.scheduleNodes));
		remove();
		setProcessNow(0);
		setPreProcessNow(0);
	};

	useEffect(() => {
		setProcessNow(prePaymentPercent);
	}, [append, percentPayment, prePaymentPercent]);

	return (
		<Modal show={isOpenScheduleModal} onHide={handleClose} contentClassName='border' size='lg' backdrop='static'>
			<Form
				onSubmit={(e) => {
					if (e) {
						if (typeof e.preventDefault === 'function') {
							e.preventDefault();
						}
						if (typeof e.stopPropagation === 'function') {
							e.stopPropagation();
						}
						return handleSubmit(submitScheduleItems)(e);
					}
				}}
				noValidate
				validated={validated}
			>
				<Modal.Header closeVariant={'white'} className='bg-body-tertiary px-x1 border-bottom-0'>
					<Modal.Title as='h5'>添加尾款收款节点</Modal.Title>
					<CloseButton className='btn btn-circle btn-sm transition-base p-0' onClick={handleClose} />
				</Modal.Header>
				<Modal.Body className='p-x1'>
					<OverlayTrigger
						overlay={
							<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
								已设置进度{Number(preProcessNow) + Number(processNow)}% / 剩余{100 - (Number(preProcessNow) + Number(processNow))}%
							</Tooltip>
						}
					>
						<ProgressBar
							className='rounded-0'
							variant='primary'
							now={Number(preProcessNow) + Number(processNow)}
							label={`${Number(preProcessNow) + Number(processNow)}%`}
							key={2}
						/>
					</OverlayTrigger>
					<div className='my-3'>
						<p>
							当前首付设置百分比 <strong>{prePaymentPercent}%</strong>{' '}
						</p>
						<p>
							首付金额 <strong>¥{percentPayment}</strong>
						</p>
						<p>
							尾款金额 <strong>¥{percentBalance}</strong>
						</p>
						<p>
							尾款待分配百分比 <strong>{100 - prePaymentPercent}%</strong>
						</p>
					</div>
					<Table className='gx-2 flex-between-center mb-3'>
						<thead>
							<tr>
								<th>付款节点</th>
								<th>账期(天)</th>
								<th>付款百分比</th>
								<th>付款金额</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{fields.map((scheduleNode, index) => (
								<tr key={index} className='gx-2 flex-between-center mb-3'>
									<td>{scheduleNode.title}</td>
									<td className='mb-0 text-700'>{scheduleNode.paymentDays}天</td>
									<td className='mb-0 text-700'>{scheduleNode.paymentPercent}%</td>
									<td className='mb-0 text-700'>¥{scheduleNode.paymentAmount}</td>
									<td>
										<Button
											variant='link'
											to='#!'
											type='button'
											className='text-danger'
											size='sm'
											onClick={() => {
												remove(index);
												setProcessNow(Number(processNow) - Number(scheduleNode.paymentPercent));
												setScheduleNode({
													title: '',
													paymentDays: 0,
													paymentPercent: 0,
													paymentAmount: 0,
												});
											}}
										>
											<FontAwesomeIcon className='fs-10' icon='trash-alt' />
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					{fields.length < 2 && (
						<Row className='gy-3 gx-2'>
							<Col sm={3}>
								<Form.Select
									size='sm'
									name='scheduleNodeTitle'
									placeholder='节点名称'
									value={scheduleNode.title}
									onChange={(e) => setScheduleNode({ ...scheduleNode, title: e.target.value })}
								>
									<option value=''>请选择付款节点</option>
									<option value='发货' disabled={fields.map((node) => node.title).includes('发货') || !fields.length === 0}>
										发货/提供服务
									</option>
									<option value='验收' disabled={!fields.map((node) => node.title).includes('发货')}>
										验收
									</option>
								</Form.Select>
							</Col>
							<Col sm={9}>
								<Flex justifyContent='between' alignItems='center' className='gap-2'>
									<Form.Control
										type='Number'
										size='sm'
										name='scheduleNodePaymentDays'
										placeholder='账期'
										value={scheduleNode.paymentDays}
										onChange={(e) =>
											setScheduleNode({
												...scheduleNode,
												paymentDays: Number.parseInt(e.target.value),
											})
										}
									/>
									<Form.Control
										type='number'
										size='sm'
										name='scheduleNodePaymentPercent'
										placeholder='付款百分比'
										value={scheduleNode.paymentPercent}
										max={100 - Number.parseFloat(prePaymentPercent)}
										onChange={(e) =>
											setScheduleNode({
												...scheduleNode,
												paymentPercent: Number.parseInt(e.target.value),
												paymentAmount: Number.parseFloat((Number(e.target.value / 100) * Number(totalPrice)).toFixed(2)),
											})
										}
									/>
									<Form.Control type='text' size='sm' name='scheduleNodePaymentAmount' placeholder='待计算付款金额' disabled={true} value={scheduleNode.paymentAmount} />
									<Button
										variant='falcon-default'
										size='sm'
										className='me-2'
										type='button'
										disabled={scheduleNode.title === '' || scheduleNode.paymentDays === 0}
										onClick={() => {
											append({
												title: scheduleNode.title,
												paymentDays: scheduleNode.paymentDays,
												paymentPercent: scheduleNode.paymentPercent,
												paymentAmount: scheduleNode.paymentAmount,
											});
											setProcessNow(Number(processNow) + Number(scheduleNode.paymentPercent));

											setScheduleNode({
												title: '',
												paymentDays: 0,
												paymentPercent: 0,
												paymentAmount: 0,
											});
										}}
									>
										<FontAwesomeIcon icon='plus' />
									</Button>
								</Flex>
							</Col>
						</Row>
					)}
				</Modal.Body>

				<Modal.Footer className='bg-body-tertiary px-x1 border-top-0'>
					<Flex justifyContent='between' alignItems='center'>
						{Number(preProcessNow) + Number(processNow) === 100 && (
							<h5 className='mb-2 mb-md-0 me-1 text-success'>
								全部完成 <FontAwesomeIcon icon='arrow-right' />
							</h5>
						)}

						<Button variant='primary' type='submit' onClick={handleClose} disabled={Number(preProcessNow) + Number(processNow) !== 100}>
							保存
						</Button>
					</Flex>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export default AddPaymentScheduleModal;
