/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Badge, Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
//Import Material React Table Translations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Background from '../../../../components/common/Background';
import SystemAlert from '../../../../components/modal/SystemAlert';
import { useDeleteCustomerStaffMutation, useGetCustomersBySaleQuery, useGetCustomersByUserQuery, useGetCustomersQuery } from '../../../../slices/customersApiSlice';
import { saveCustomer } from '../../../../slices/orderSlice';
import AddStaffToCustomerModal from '../../../customer/AddStaffToCustomerModal';
import CustomerCreateApplyModal from '../../../customer/CustomerCreateApplyModal';
import EditStaffToCustomerModal from '../../../customer/EditStaffToCustomerModal';
import corner1 from './../../../../assets/img/illustrations/authentication-corner.png';

const SelectOrderCustomerModal = ({ modalShow, setModalShow }) => {
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.auth);
	const { sale } = useSelector((state) => state.order);
	const [applyModalShow, setApplyModalShow] = useState(false);
	const [editModalShow, setEditModalShow] = useState(false);
	const [deleteStaffConfirmModalShow, setDeleteStaffConfirmModalShow] = useState(false);
	const [selectedStaff, setSelectedStaff] = useState({});
	const [selectedCustomer, setSelectedCustomer] = useState({});
	const [skipLoad] = useState(true);
	const [skipLoadManager] = useState(true);
	const [skipLoadAssistant] = useState(true);

	const {
		data: fetchedAllCustomers = [],
		isError: isLoadingAllCustomersError,
		isFetching: isFetchingAllCustomers,
		isLoading: isLoadingAllCustomers,
	} = useGetCustomersQuery(undefined, {
		skip: userInfo.role === 'manager' ? !skipLoadManager : skipLoadManager,
	});

	const {
		data: fetchedUserCustomers = [],
		isError: isLoadingUserCustomersError,
		isFetching: isFetchingUserCustomers,
		isLoading: isLoadingUserCustomers,
	} = useGetCustomersByUserQuery(undefined, {
		skip: ['sale', 'user'].includes(userInfo.role) ? !skipLoad : skipLoad,
	});

	const {
		data: fetchedSelectedSaleCustomers = [],
		isError: isLoadingSaleCustomersError,
		isFetching: isFetchingSaleCustomers,
		isLoading: isLoadingSaleCustomers,
	} = useGetCustomersBySaleQuery(sale, {
		skip: userInfo.role === 'assistant' ? !skipLoadAssistant : skipLoadAssistant,
	});

	const [deleteStaff, { isLoading: isDeletingStaff }] = useDeleteCustomerStaffMutation();

	const handleSelectd = (customer, staff) => {
		const staffInfo = {
			customer_id: customer._id,
			customerName: customer.name,
			customerPhone: customer.phone,
			customerEmail: customer.email,
			customerSale: customer.sale._id,
			customerAddress: customer.address,
			customerQuotas: customer.quotas,
			staff_id: staff._id,
			staffName: staff.name,
			staffPhone: staff.phone,
			staffEmail: staff.email,
			staffWechat: staff.wechat,
		};
		setSelectedStaff(staffInfo);
	};

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: '客户名称',
			},
			{
				accessorFn: (row) => `${Number.parseInt(row.staffs.length)}`,
				accessorKey: 'staffsLength',
				header: '联系人',
				Cell: ({ renderedCellValue, row }) => {
					if (Number.parseInt(renderedCellValue) !== 0) {
						return <Badge>{renderedCellValue}</Badge>;
					} else {
						return <Badge bg='success'>暂无</Badge>;
					}
				},
			},
			{
				accessorKey: 'phone',
				header: '单位电话',
				muiEditTextFieldProps: {
					required: true,
					type: 'text',
					variant: 'outline',
				},
			},
			{
				accessorKey: 'email',
				header: '单位邮箱',
			},
			{
				accessorKey: 'sale.name',
				header: '业务联系人',
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
			},
		],
		[]
	);
	const handleDeleteStaff = async () => {
		await deleteStaff({ customerId: selectedCustomer._id, staffId: selectedStaff._id }).unwrap();
		toast.success('已删除');
		setDeleteStaffConfirmModalShow(false);
		setSelectedCustomer({});
		setSelectedCustomer({});
	};
	const handleCancelDeleteStaff = () => {
		setDeleteStaffConfirmModalShow(false);
		setSelectedCustomer({});
		setSelectedCustomer({});
	};
	const table = useMaterialReactTable({
		columns,
		data: userInfo.role === 'assistant' ? fetchedSelectedSaleCustomers : userInfo.role === 'manager' ? fetchedAllCustomers : fetchedUserCustomers,
		createDisplayMode: 'modal',
		editDisplayMode: 'custom',
		enableExpanding: true,
		getRowId: (row) => row._id,
		enableGrouping: true,
		enableFullScreenToggle: true,
		localization: MRT_Localization_ZH_HANS,
		positionToolbarAlertBanner: 'bottom',
		initialState: {
			pagination: { pageIndex: 0, pageSize: 50 },
			sorting: [{ id: 'staffsLength', desc: true }], //sort by state by default
			columnVisibility: { createdAt: false },
			density: 'compact',
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps:
			isLoadingUserCustomersError || isLoadingSaleCustomersError || isLoadingSaleCustomersError
				? {
						color: 'error',
						children: '数据加载错误',
				  }
				: undefined,
		muiTableContainerProps: {
			sx: {
				maxHeight: '500px',
			},
		},
		renderTopToolbarCustomActions: () => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				<Button
					size='sm'
					as={Link}
					onClick={(e) => {
						if (Object.values(selectedStaff).length !== 0) {
							dispatch(saveCustomer(selectedStaff));
							toast.success('客户添加成功！');
							setModalShow(false);
						} else {
							toast.warning('请选择一位客户联系人');
						}
					}}
					variant='primary'
				>
					确定
				</Button>
				{userInfo.role === 'assistant' && Object.values(sale).length !== 0 ? (
					<Button variant='outline-primary' size='sm' as={Link} type='button' onClick={() => setApplyModalShow(true)}>
						为 {sale.name} 申请添加客户
					</Button>
				) : (
					<Button variant='outline-primary' size='sm' as={Link} type='button' onClick={() => setApplyModalShow(true)}>
						申请添加客户
					</Button>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingAllCustomers || isLoadingUserCustomers || isLoadingSaleCustomers,
			showAlertBanner: isLoadingAllCustomersError || isLoadingUserCustomersError || isLoadingSaleCustomersError,
			showProgressBars: isFetchingAllCustomers || isFetchingUserCustomers || isFetchingSaleCustomers,
		},

		enableExpandAll: false, //disable expand all button
		muiDetailPanelProps: () => ({
			sx: () => ({
				marginTop: '0px',
				backgroundColor: '#fff',
			}),
		}),
		//custom expand button rotation
		muiExpandButtonProps: ({ row, table }) => ({
			onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
			sx: {
				transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
				transition: 'transform 0.2s',
			},
		}),

		//conditionally render detail panel
		renderDetailPanel: ({ row }) =>
			userInfo._id === row.original.sale?._id || userInfo.role === 'assistant' || userInfo.role === 'manager' ? (
				row.original.staffs.length !== 0 ? (
					<Card>
						<Background
							image={corner1}
							className='bg-card d-none d-md-block'
							style={{
								borderTopRightRadius: '0.375rem',
								borderBottomRightRadius: '0.375rem',
							}}
						/>
						<Card.Header className='position-relative'>
							<Card.Title>
								<Row className='flex-between-center'>
									<Col md>
										<h6 className='mb-2 mb-md-0 me-1'>联系人列表</h6>
									</Col>
									<Col xs='auto'>
										<AddStaffToCustomerModal selectedCustomer={row.original} />
									</Col>
								</Row>
							</Card.Title>
						</Card.Header>
						<Card.Body className='position-relative'>
							<Table striped hover responsive className='table-sm text-primary'>
								<thead>
									<tr>
										<th>选择</th>
										<th>序号</th>
										<th>联系人</th>
										<th>邮箱</th>
										<th>电话</th>
										<th>微信</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{row.original.staffs?.map((staff, index) => (
										<tr key={staff._id} className='hover-actions-trigger'>
											<td>
												<Form.Check
													type='radio'
													id={staff._id}
													name={row.original.name}
													onChange={(e) => {
														handleSelectd(row.original, staff);
													}}
												/>
											</td>
											<td className='align-middle text-nowrap'>{index + 1}</td>
											<td className='align-middle text-nowrap'>{staff.name}</td>
											<td className='align-middle text-nowrap'>{staff.email}</td>
											<td className='align-middle text-nowrap'>{staff.phone}</td>
											<td className='align-middle text-nowrap'>{staff.wechat}</td>
											<td>
												<div className='end-0 top-50 translate-middle-y hover-actions'>
													<Button
														size='sm'
														variant='outline-secondary'
														className='border-none trasparent me-2'
														onClick={() => {
															setSelectedStaff(staff);
															setSelectedCustomer(row.original);
															setEditModalShow(true);
														}}
													>
														<FontAwesomeIcon icon='fa-solid fa-pencil' />
													</Button>
													<Button
														size='sm'
														variant='outline-danger'
														className='border-none trasparent'
														onClick={() => {
															setSelectedStaff(staff);
															setSelectedCustomer(row.original);
															setDeleteStaffConfirmModalShow(true);
														}}
													>
														<FontAwesomeIcon icon='fa-solid fa-trash' />
													</Button>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
				) : (
					<AddStaffToCustomerModal selectedCustomer={row.original} />
				)
			) : null,
	});

	return (
		<>
			<Modal show={modalShow} onHide={() => setModalShow(false)} size='xl' aria-labelledby='contained-modal-title-vcenter' centered>
				<Modal.Header closeButton>
					<Modal.Title id='contained-modal-title-vcenter'>
						<h6>选择客户列表</h6>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className='p-0'>
					<MaterialReactTable table={table} />
				</Modal.Body>
			</Modal>
			<CustomerCreateApplyModal setApplyModalShow={setApplyModalShow} applyModalShow={applyModalShow} applicant={userInfo.role === 'assistant' ? sale : userInfo} />
			<EditStaffToCustomerModal
				setEditModalShow={setEditModalShow}
				editModalShow={editModalShow}
				selectedCustomer={selectedCustomer}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				setSelectedCustomer={setSelectedCustomer}
			/>
			<SystemAlert
				title='删除提醒'
				bodyText={`确定删除 ${selectedCustomer.name} 的 ${selectedStaff.name} 吗？`}
				cancelText='先不删'
				confirmText='确定删除'
				confirmColor='danger'
				confirmWaiting={isDeletingStaff}
				modalShow={deleteStaffConfirmModalShow}
				setModalShow={handleCancelDeleteStaff}
				handleConfirm={handleDeleteStaff}
			/>
		</>
	);
};

export default SelectOrderCustomerModal;
