import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { useGetMyNewNotificationsQuery, useGetMyNotificationsQuery, useGetMyUnreadNotificationsQuery, useSetNewNotificationToReadMutation } from '../slices/notificationsApiSlice';

export const NotificationContext = createContext({ products: [] });

const NotificationProvider = ({ children }) => {
	const { data: newUnreadNotifications = [], isLoading: loadingNotify, refetch } = useGetMyNewNotificationsQuery();

	const { data: unreadNotificationsCount = [] } = useGetMyUnreadNotificationsQuery();

	const [setToRead] = useSetNewNotificationToReadMutation();
	const { data: myNotifications = [] } = useGetMyNotificationsQuery();

	useEffect(() => {
		refetch();
	}, [refetch]);

	return (
		<NotificationContext.Provider
			value={{
				setToRead,
				newUnreadNotifications,
				unreadNotificationsCount,
				myNotifications,
				loadingNotify,
				refetch,
			}}
		>
			{children}
		</NotificationContext.Provider>
	);
};

NotificationProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationProvider;
