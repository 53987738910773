/* eslint-disable no-fallthrough */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import CitySelector from 'react-chinese-city-selector';
import 'react-chinese-city-selector/dist/index.css';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNotificationContext } from '../../providers/NotificationProvider';
import { useInitiateCustomerCreateApprovalMutation } from '../../slices/approvalsApiSlice';
import { useCheckCustomerNameMutation } from '../../slices/customersApiSlice';
import { useNotifyCustomerApplyToApplicantMutation, useNotifyCustomerApplyToApproverMutation } from '../../slices/notificationsApiSlice';

const CustomerCreateApplyModal = ({ setApplyModalShow, applyModalShow, applicant }) => {
	const { loadingNotify, refetch } = useNotificationContext();

	const [validated] = useState(false);

	const { userInfo } = useSelector((state) => state.auth);

	const [notifyApplicant] = useNotifyCustomerApplyToApplicantMutation();

	const [notifyAppover] = useNotifyCustomerApplyToApproverMutation();

	const [initiateCustomerCreateApproval, { isLoading: loadingApproval }] = useInitiateCustomerCreateApprovalMutation();

	const [checkCustomerName, { isLoading: nameChecking }] = useCheckCustomerNameMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm({
		defaultValues: {
			newCustomerType: '',
			newCustomerCity: '上海市上海市',
			newCustomerName: '',
			newCustomerCompany: '',
			newCustomerDepartment: '',
			newCustomerEmail: '',
			newCustomerOrganization: '',
			newCustomerGroup: '',
			newCustomerPhone: '',
			newCustomerAddress: '',
		},
	});

	useEffect(() => {
		if (!loadingNotify) {
			refetch();
		}
	}, [loadingNotify, refetch]);

	const submitCustomerApproval = async (data) => {
		try {
			const newCustomerConcatName = `${data.newCustomerName}${data.newCustomerDepartment}${data.newCustomerGroup}`;

			await checkCustomerName({ newCustomerName: newCustomerConcatName });

			const res = await initiateCustomerCreateApproval({
				applicant,
				newCustomerType: data.newCustomerType,
				newCustomerConcatName: newCustomerConcatName,
				newCustomerName: data.newCustomerName,
				newCustomerOrganization: data.newCustomerName,
				newCustomerDepartment: data.newCustomerDepartment,
				newCustomerGroup: data.newCustomerGroup,
				newCustomerPhone: data.newCustomerPhone,
				newCustomerEmail: data.newCustomerEmail,
				newCustomerCity: data.newCustomerCity,
				newCustomerAddress: data.newCustomerAddress,
			});
			const { approver, assistant, detail, _id } = res.data;

			await notifyApplicant({ toUser: assistant, fromUser: assistant, content: { detail, _id } }).unwrap();

			await notifyAppover({ toUser: approver, fromUser: assistant, content: { detail, _id } }).unwrap();

			setApplyModalShow(false);

			toast.success('客户申请已提交, 待批准');
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const onSelect = (e, data) => {
		const { province, city } = data;
		setValue('newCustomerCity', province + city);
	};

	return (
		<>
			<Modal
				show={applyModalShow}
				onHide={() => {
					setApplyModalShow(false);
					reset();
				}}
				size='lg'
				backdrop='static'
				aria-labelledby='contained-modal-title-vcenter'
				centered
			>
				<Form
					onSubmit={(e) => {
						if (e) {
							if (typeof e.preventDefault === 'function') {
								e.preventDefault();
							}
							if (typeof e.stopPropagation === 'function') {
								e.stopPropagation();
							}
							return handleSubmit(submitCustomerApproval)(e);
						}
					}}
					noValidate
					validated={validated}
				>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>{userInfo.role === 'assistant' ? `为${applicant.name}申请添加客户` : '申请添加客户'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerType'>
							<Form.Label column sm='3'>
								客户类型
							</Form.Label>
							<Col sm='6'>
								<Form.Select name='newCustomerType' isInvalid={!!errors.newCustomerType} {...register('newCustomerType', { required: true })}>
									<option value=''>选择客户类型</option>
									<option value='科研'>科研</option>
									<option value='工业'>工业</option>
									<option value='临床'>临床</option>
									<option value='机关'>机关</option>
								</Form.Select>
								{errors.newCustomerType?.type === 'required' && <p className='text-danger'>客户类型为必选项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerName'>
							<Form.Label column sm='3'>
								单位名称
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									type='text'
									name='newCustomerName'
									placeholder="eg:'复旦大学'"
									autoComplete='false'
									isInvalid={!!errors.newCustomerName}
									{...register('newCustomerName', { required: true })}
								/>
								{errors.newCustomerName?.type === 'required' && <p className='text-danger'>客户名称为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerDepartment'>
							<Form.Label column sm='3'>
								院系/部门(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									type='text'
									name='newCustomerDepartment'
									placeholder="eg:'医学系'"
									autoComplete='false'
									isInvalid={!!errors.newCustomerDepartment}
									{...register('newCustomerDepartment')}
								/>
								{errors.newCustomerDepartment?.type === 'required' && <p className='text-danger'>客户名称为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerGroup'>
							<Form.Label column sm='3'>
								课题组(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									type='text'
									name='newCustomerGroup'
									placeholder="eg:'xx课题组'"
									autoComplete='false'
									isInvalid={!!errors.newCustomerGroup}
									{...register('newCustomerGroup')}
								/>
								{errors.newCustomerGroup?.type === 'required' && <p className='text-danger'>客户名称为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerCity'>
							<Form.Label column sm='3'>
								城市
							</Form.Label>
							<Col sm='6'>
								<div
									style={{
										width: '380px',
										height: '40px',
										margin: '0 auto',
									}}
								>
									<CitySelector value='上海市' size='medium' onSelect={onSelect} {...register('newCustomerCity', { required: '所在城市为必填项' })} />
								</div>
								{errors.newCustomerCity?.type === 'required' && <p className='text-danger'>请选择客户所在城市</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerPhone'>
							<Form.Label column sm='3'>
								客户单位电话(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									type='text'
									name='newCustomerPhone'
									placeholder='客户单位电话'
									autoComplete='false'
									isInvalid={!!errors.newCustomerPhone}
									{...register('newCustomerPhone')}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerEmail'>
							<Form.Label column sm='3'>
								邮箱(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='newCustomerName' placeholder='客户邮箱' autoComplete='false' {...register('newCustomerEmail')} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='g-1 mb-3' controlId='newCustomerAddress'>
							<Form.Label column sm='3'>
								详细地址
							</Form.Label>
							<Col sm='6'>
								<Form.Control
									as='textarea'
									row={10}
									style={{ height: '150px' }}
									name='newCustomerAddress'
									placeholder='填写客户详细地址'
									autoComplete='false'
									isInvalid={!!errors.newCustomerAddress}
									{...register('newCustomerAddress', { required: true })}
								/>
								{errors.newCustomerAddress?.type === 'required' && <p className='text-danger'>填写客户详细地址</p>}
							</Col>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => {
								setApplyModalShow(false);
								reset();
							}}
						>
							关闭
						</Button>
						<Button variant='primary' type='submit' disabled={loadingApproval} className='d-inline-flex flex-center'>
							<span className={classNames({ 'visually-hidden': loadingApproval })}>提交</span>
							{nameChecking ? <Spinner as='span' animation='border' size='sm' /> : loadingApproval && <Spinner as='span' animation='border' size='sm' />}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default CustomerCreateApplyModal;
