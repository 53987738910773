/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clearCustomer, saveOrderId, saveOrderIndexOfDay, saveQuotationId, saveSale } from '../../../../slices/orderSlice';
import { useGetSaleUsersQuery } from '../../../../slices/usersApiSlice';
import { useGetNewIndexOfTodayBySelectedSaleMutation } from '../../../../slices/ordersApiSlice';

const SelectOrderSaleModal = ({ modalShow, setModalShow }) => {
	const dispatch = useDispatch();
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth();
	const currentDay = new Date().getDate();
	const [rowSelection, setRowSelection] = useState([]);
	const { sale } = useSelector((state) => state.order);
	const { data: fetchedSaleUsers = [], isError: isLoadingSaleUsersError, isFetching: isFetchingSaleUsers, isLoading: isLoadingSaleUsers, refetch } = useGetSaleUsersQuery();

	const [getOrderIndexBySelectedSale, { isLoading: LoadingIndex }] = useGetNewIndexOfTodayBySelectedSaleMutation();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: '销售员',
			},
			{
				accessorKey: 'email',
				header: '邮箱',
			},
			{
				accessorKey: 'phone',
				header: '联系人电话',
			},
			{
				accessorKey: 'alias',
				header: '联系人电话',
			},
		],
		[]
	);
	const hanldeSelectedSale = async () => {
		const rows = table.getSelectedRowModel().rows; //or read entire rows
		const original = rows.map((row) => row.original);
		// 获取订单编号

		if (original.length !== 0) {
			const res = await getOrderIndexBySelectedSale(original[0]).unwrap();
			const generatedOrderId =
				original[0].alias +
				'-' +
				currentYear +
				(currentMonth + 1 < 10 ? '0' + (currentMonth + 1) : currentMonth + 1) +
				(currentDay < 10 ? '0' + currentDay : currentDay) +
				res?.nextIndex;
			dispatch(saveOrderIndexOfDay(res.incrementIndex));
			dispatch(saveOrderId(generatedOrderId));
			dispatch(saveQuotationId(generatedOrderId));
			dispatch(saveSale(original[0]));
			dispatch(clearCustomer());
			toast.success('销售添加成功');
			setRowSelection([]);
			setModalShow(false);
		} else {
			toast.warning('请选择一位销售');
		}
	};
	const table = useMaterialReactTable({
		columns,
		data: fetchedSaleUsers,
		enableColumnResizing: true,
		createDisplayMode: 'modal', // ('modal', and 'custom' are also available)
		editDisplayMode: 'custom', // ('modal', 'cell', 'table', and 'custom' are also available)
		enableRowSelection: true,
		enableMultiRowSelection: false,
		getRowId: (row) => row._id,
		enableGrouping: true,
		enableFullScreenToggle: true,
		localization: MRT_Localization_ZH_HANS,
		positionToolbarAlertBanner: 'bottom',
		initialState: {
			expanded: true, //expand all rows by default
			pagination: { pageIndex: 0, pageSize: 50 },
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps: isLoadingSaleUsersError
			? {
					color: 'error',
					children: '数据加载错误',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				maxHeight: '500px',
			},
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				{!isLoadingSaleUsers && !isFetchingSaleUsers && fetchedSaleUsers.length !== 0 && (
					<>
						<Button size='sm' variant='primary' as={Link} onClick={hanldeSelectedSale}>
							确定
						</Button>
					</>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingSaleUsers,
			showAlertBanner: isLoadingSaleUsersError,
			showProgressBars: isFetchingSaleUsers,
		},
	});

	return (
		<Modal show={modalShow} size='lg' onHide={() => setModalShow(false)} aria-labelledby='contained-modal-title-vcenter' centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>选择销售员</Modal.Title>
			</Modal.Header>
			<Modal.Body className='p-0'>
				<MaterialReactTable table={table} />
			</Modal.Body>
		</Modal>
	);
};

export default SelectOrderSaleModal;
