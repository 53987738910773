import { Container, Row, Col } from 'react-bootstrap';

const FormContainer = ({ children }) => {
	return (
		<Container>
			<Row className='justify-content-sm-center justify-content-md-center justify-content-xxl-center'>
				<Col>{children}</Col>
			</Row>
		</Container>
	);
};

export default FormContainer;
