import React, { useState } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SystemAlert from '../../../components/modal/SystemAlert';
import { useDeleteOrderDraftMutation } from '../../../slices/orderDraftsApiSlice';
import { resetOrder } from '../../../slices/orderSlice';

const OrderButton = ({ orderCreating, orderDraftSaving, updatingDraft, initiatingApproval }) => {
	const { draftId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userInfo } = useSelector((state) => state.auth);
	const { register, setValue, watch } = useFormContext();
	const { paymentMethod, prePaymentPercent, prePaymentOption } = watch();
	const order = useSelector((state) => state.order);

	const [showClosePageConfirm, setShowClosePageConfirm] = useState(false);
	const [showDeleteDraftConfirm, setShowDeleteDraftConfirm] = useState(false);
	const [showEscEditingConfirm, setShowEscEditingConfirm] = useState(false);
	const handleClosePageConfirm = () => setShowClosePageConfirm(false);
	const handleShowClosePageConfirm = () => setShowClosePageConfirm(true);
	const handleCloseDeleteDraftConfirm = () => setShowDeleteDraftConfirm(false);
	const handleShowDeleteDraftConfirm = () => setShowDeleteDraftConfirm(true);
	const handleCloseEscEditingConfirm = () => setShowEscEditingConfirm(false);
	const handleShowEscEditingConfirm = () => setShowEscEditingConfirm(true);

	const [deleteOrderDraft, { isLoading: deletingDraft }] = useDeleteOrderDraftMutation();

	const handleClearOrder = () => {
		dispatch(resetOrder());
		navigate('/dashboard');
	};
	const handleDeleteDraft = async () => {
		await deleteOrderDraft(draftId).unwrap();
		navigate(`/${userInfo.role}/draftlist`);
		dispatch(resetOrder());
	};
	const handleOutEditingDraft = () => {
		dispatch(resetOrder());
		navigate(`/${userInfo.role}/draftlist`);
	};
	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			{order.orderItems.some((item) => item.sellingPrice < item.minimumSellingPrice) ? '产品销售价格小于底价' : '离成功就差一步！'}
		</Tooltip>
	);
	return (
		<>
			<SystemAlert
				title='提示内容'
				bodyText='退出将清空已填写内容'
				cancelText='手滑了'
				confirmText='继续退出'
				modalShow={showClosePageConfirm}
				setModalShow={handleClosePageConfirm}
				handleConfirm={handleClearOrder}
			/>
			<SystemAlert
				title='提示内容'
				bodyText='你确定要删除这个草稿吗?'
				cancelText='手滑了'
				confirmText='确定删除'
				confirmColor='danger'
				modalShow={showDeleteDraftConfirm}
				setModalShow={handleCloseDeleteDraftConfirm}
				handleConfirm={handleDeleteDraft}
			/>
			<SystemAlert
				title='提示内容'
				bodyText='确定要退出草稿编辑吗？您填写的内容可能未保存。'
				cancelText='手滑了'
				confirmText='继续退出'
				modalShow={showEscEditingConfirm}
				setModalShow={handleCloseEscEditingConfirm}
				handleConfirm={handleOutEditingDraft}
			/>
			{draftId ? (
				<>
					<Button variant='outline-warning' className='me-2 ms-xl-7 mt-xs-2 mt-md-2 mt-xl-2 ms-xxl-4 ' onClick={handleShowEscEditingConfirm}>
						<FontAwesomeIcon icon='fa-delete-left' className='me-2' />
						退出编辑
					</Button>
					<Button variant='outline-danger' className='me-2 mt-xs-2 mt-md-2 mt-xl-2' onClick={handleShowDeleteDraftConfirm}>
						{deletingDraft ? (
							<Spinner size='sm' />
						) : (
							<>
								<FontAwesomeIcon icon='trash-alt' className='me-2' />
								<span>删除</span>
							</>
						)}
					</Button>
					<Button
						variant='outline-secondary'
						type='submit'
						{...register('button')}
						value='updateDraft_button'
						onClick={(e) => setValue('button', 'updateDraft_button')}
						className='me-2 mt-xs-2 mt-md-2 mt-xl-2'
						disabled={Object.values(order.customer).length === 0 || order.orderItems.length === 0}
					>
						{updatingDraft ? (
							<Spinner size='sm' />
						) : (
							<>
								<FontAwesomeIcon icon='floppy-disk' className='me-2' />
								<span>保存</span>
							</>
						)}
					</Button>
				</>
			) : (
				<>
					<Button variant='outline-danger' className='me-2 ms-xl-7 mt-xs-2 mt-md-2 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 px-0' onClick={handleShowClosePageConfirm}>
						<FontAwesomeIcon icon='fa-delete-left' className='me-2' />
						<span>退出</span>
					</Button>
					<Button
						variant='outline-secondary'
						className='me-2 mt-xs-2 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 px-0'
						type='submit'
						{...register('button')}
						value='saveAsDraft_button'
						onClick={(e) => setValue('button', 'saveAsDraft_button')}
						disabled={Object.values(order.customer).length === 0 || order.orderItems.length === 0}
					>
						{orderDraftSaving ? (
							<Spinner size='sm' />
						) : (
							<>
								<FontAwesomeIcon icon='floppy-disk' className='me-2' />
								<span>保存至草稿</span>
							</>
						)}
					</Button>
				</>
			)}

			<Button
				variant='outline-primary'
				type='submit'
				{...register('button')}
				value='saveAsQuotation_button'
				onClick={(e) => setValue('button', 'saveAsQuotation_button')}
				className='me-2 mt-xs-2 col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3'
				disabled={Object.values(order.customer).length === 0 || order.orderItems.length === 0 || (userInfo.role === 'assistant' && Object.values(order.sale).length === 0)}
			>
				<FontAwesomeIcon icon='fa-clipboard' className='fs-9 align-item-center me-2' />
				<span>生成报价单</span>
			</Button>
			<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
				<Button
					variant={order.orderItems.some((item) => item.sellingPrice < item.minimumSellingPrice) || paymentMethod === '部分支付' ? 'outline-info' : 'outline-success'}
					type='submit'
					{...register('button')}
					value='placeOrder_button'
					onClick={(e) => setValue('button', 'placeOrder_button')}
					className='mt-xs-2 col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xxl-2'
					disabled={
						Object.values(order.customer).length === 0 ||
						order.orderItems.length === 0 ||
						(userInfo.role === 'assistant' && Object.values(order.sale).length === 0) ||
						Object.values(order.assistant).length === 0 ||
						(paymentMethod === '部分支付' && prePaymentOption === '百分比' && prePaymentPercent === 0 && order.scheduleNodes.length === 0) ||
						(paymentMethod === '部分支付' && prePaymentOption === '百分比' && prePaymentPercent !== 0 && order.scheduleNodes.length === 0)
					}
				>
					{orderCreating ? (
						<Spinner size='sm' />
					) : initiatingApproval ? (
						<Spinner size='sm' />
					) : (
						<>
							{order.orderItems.some((item) => item.sellingPrice < item.minimumSellingPrice) || paymentMethod === '部分支付' ? (
								<>
									<FontAwesomeIcon icon='fa-feather' className='fs-9 align-item-center me-2' />
									<span>去审批</span>
								</>
							) : (
								<>
									<FontAwesomeIcon icon='fa-handshake' className='fs-9 align-item-center me-2' />
									<span>下单</span>
								</>
							)}
						</>
					)}
				</Button>
			</OverlayTrigger>
		</>
	);
};

export default OrderButton;
