import { useMemo } from 'react';

//MRT Imports
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

//Material UI Imports
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';

//Icons Imports

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Col, ListGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useGetAssistantOrdersQuery } from '../../../slices/ordersApiSlice';

const OrderListAssistantTable = () => {
	const { data: orders = [], isLoading, isError } = useGetAssistantOrdersQuery();

	const columns = useMemo(
		() => [
			{
				id: 'order', //id used to define `group` column
				header: '订单信息',
				Header: ({ column }) => <em>{column.columnDef.header}</em>,
				columns: [
					{
						accessorKey: 'orderId',
						header: '订单编号',
						meta: {
							headerProps: { className: 'pe-1 sticky-column text-900' },
							cellProps: {
								className: 'fw-semibold',
							},
						},
						Cell: ({ row: { original } }) => {
							const { orderId, _id } = original;
							return <Link to={`/order/detail/${_id}`}>{orderId}</Link>;
						},
					},
					{
						accessorFn: (row) => (row.isCompleted ? '已完成' : '未完成'),
						id: 'isCompleted',
						header: '订单状态',
						filterVariant: 'autocomplete',
						Cell: ({ cell }) => {
							if (cell.getValue() === '已完成') {
								return <Badge bg='success'>已完成</Badge>;
							} else {
								return <Badge bg='danger'>未完成</Badge>;
							}
						},
						// Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
						muiFilterTextFieldProps: {
							sx: {
								minWidth: '250px',
							},
						},
					},
					{
						accessorKey: 'customerInfo.customerName',
						enableClickToCopy: true,
						filterVariant: 'autocomplete',
						header: '客户名称',
						size: 300,
					},
					{
						accessorKey: 'saleUser.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
						enableClickToCopy: true,
						filterVariant: 'autocomplete',
						header: '销售员',
						size: 100,
					},
					{
						accessorKey: 'assistantUser.name', //accessorKey used to define `data` column. `id` gets set to accessorKey automatically
						enableClickToCopy: true,
						filterVariant: 'autocomplete',
						header: '销售助理',
						size: 100,
					},
					{
						//accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
						accessorKey: 'orderNotes',
						id: 'orderNotes',
						header: '备注',
						Cell: ({ cell, row: { original } }) => {
							const { _id } = original;
							return (
								<>
									{cell.getValue() !== '' ? (
										<>
											<OverlayTrigger
												overlay={
													<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
														{cell.getValue()}
													</Tooltip>
												}
											>
												<Link to={`/order/detail/${_id}`}>
													<span className='d-inline-block text-truncate' style={{ maxWidth: '150px' }}>
														<FontAwesomeIcon icon='fa-regular fa-comment-dots' className='text-primary' /> &nbsp;
														{cell.getValue()}
													</span>
												</Link>
											</OverlayTrigger>
										</>
									) : (
										<span>无备注</span>
									)}
								</>
							);
						},
						// Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
						muiFilterTextFieldProps: {
							sx: {
								minWidth: '250px',
							},
						},
					},
					{
						accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
						id: 'createdAt',
						header: '下单日期',
						filterVariant: 'date',
						filterFn: 'lessThan',
						sortingFn: 'datetime',
						Cell: ({ cell }) => <span>{cell.getValue().toLocaleDateString()}</span>,
						// Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
						muiFilterTextFieldProps: {
							sx: {
								minWidth: '250px',
							},
						},
					},
				],
			},
			{
				id: 'orderProcess',
				header: '订单跟进',
				Header: ({ column }) => <em>{column.columnDef.header}</em>,
				columns: [
					{
						accessorKey: 'currentAccountsReceivable',
						header: '应收账款',
						Cell: ({ cell }) => (
							<>
								{cell.getValue() === 0 ? (
									<Badge pill bg='success' className='me-2'>
										无应收
									</Badge>
								) : (
									<Badge pill bg='danger'>
										<strong>¥{cell.getValue()}</strong>
									</Badge>
								)}
							</>
						),
					},
					{
						accessorKey: 'paymentStatus',
						header: '财务状态',
						meta: {
							headerProps: { className: 'text-center text-900' },
						},
						Cell: ({ row: { original } }) => {
							const { paymentStatus } = original;

							let bg = '';
							let icon = '';
							let text = '';
							switch (paymentStatus) {
								case 'waitingPayPlaceOrder':
									bg = 'danger';
									icon = 'stream';
									text = '待收预付款';
									break;
								case 'waitingPayDelivery':
									bg = 'danger';
									icon = 'stream';
									text = '待收发货款';
									break;
								case 'waitingPayAccept':
									bg = 'danger';
									icon = 'stream';
									text = '待收验收款';
									break;
								case 'waitingPayComplete':
									bg = 'danger';
									icon = 'stream';
									text = '待支付完成';
									break;
								case 'payComplete':
									bg = 'success';
									icon = 'check';
									text = '支付完成';
									break;
								default:
									break;
							}
							return (
								<Badge pill bg={bg} className='me-2'>
									<FontAwesomeIcon icon={icon} className='me-2' />
									<span className='fs-11'>{text}</span>
								</Badge>
							);
						},
					},
					{
						accessorKey: 'deliveryStatus',

						header: '物流状态',
						meta: {
							headerProps: { className: 'text-center text-900' },
						},
						Cell: ({ row: { original } }) => {
							const { deliveryStatus } = original;

							let bg = '';
							let icon = '';
							let text = '';
							switch (deliveryStatus) {
								case 'waitingDelivery':
									bg = 'danger';
									icon = 'stream';
									text = '待发货';
									break;
								case 'waitingAccept':
									bg = 'danger';
									icon = 'stream';
									text = '待验收';
									break;
								case 'waitingComplete':
									bg = 'warning';
									icon = 'stream';
									text = '待完成';
									break;
								case 'deliveryComplete':
									bg = 'success';
									icon = 'check';
									text = '物流完成';
									break;
								default:
									break;
							}
							return (
								<Badge pill bg={bg} className='me-2'>
									<FontAwesomeIcon icon={icon} className='me-2' />
									<span className='fs-11'>{text}</span>
								</Badge>
							);
						},
					},
				],
			},
		],
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data: orders, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
		enableColumnFilterModes: true,
		enableColumnOrdering: true,
		enableGrouping: true,
		enableColumnPinning: true,
		enableFacetedValues: true,
		//enableRowActions: true,
		//enableRowSelection: true,
		localization: MRT_Localization_ZH_HANS,
		initialState: {
			density: 'compact',
			showColumnFilters: false,
			showGlobalFilter: true,
			columnPinning: {
				left: ['mrt-row-expand', 'mrt-row-select'],
			},
			sorting: [
				{
					id: 'createdAt', //sort by age by default on page load
					desc: true,
				},
			],
		},
		paginationDisplayMode: 'pages',
		positionToolbarAlertBanner: 'bottom',
		muiSearchTextFieldProps: {
			size: 'small',
			variant: 'outlined',
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiPaginationProps: {
			color: 'secondary',
			rowsPerPageOptions: [10, 20, 30],
			shape: 'rounded',
			variant: 'outlined',
		},
		renderDetailPanel: ({ row: { original } }) => (
			<>
				<Row className='g-3'>
					<Col md={3}>
						<Card>
							<ListGroup className='flush'>
								<ListGroup.Item>
									<h5 className='my-3'>
										<strong>客户信息</strong>
									</h5>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<p>
											<strong>客户名称: </strong>
											{original.customerInfo.customerName}
										</p>
										{original.customerInfo.staffName && (
											<p>
												<strong>联系人: </strong>
												{original.customerInfo.staffName}
											</p>
										)}
										{original.customerInfo.staffPhone && (
											<p>
												<strong>联系人电话: </strong>
												{original.customerInfo.staffPhone}
											</p>
										)}
										{original.customerInfo.staffWechat && (
											<p>
												<strong>联系人微信: </strong>
												{original.customerInfo.staffWechat}
											</p>
										)}
										{original.customerInfo.staffEmail && (
											<p>
												<strong>联系人邮箱: </strong>
												<a href={`mailto:${original.customerInfo.staffEmail}`}>{original.customerInfo.staffEmail}</a>
											</p>
										)}
										<p>
											<strong>联系人地址: </strong>
											{original.customerInfo.customerAddress}
										</p>
									</Row>
								</ListGroup.Item>
							</ListGroup>
						</Card>
					</Col>
					<Col md={9}>
						<Card style={{ maxWidth: '800px' }}>
							<ListGroup className='flush'>
								<ListGroup.Item className='pb-4'>
									<h5 className='my-3'>
										<strong>订单产品列表</strong>
									</h5>
									<ListGroup.Item className='bg-primary'>
										<Row className='text-white'>
											<Col>
												<strong>货号</strong>
											</Col>
											<Col>
												<strong>设备名称</strong>
											</Col>
											<Col>
												<strong>数量</strong>
											</Col>
											<Col>
												<strong>单价</strong>
											</Col>
											<Col>
												<strong>小计</strong>
											</Col>
										</Row>
									</ListGroup.Item>
									{original.orderItems.map((item, index) => (
										<>
											<ListGroup.Item key={index}>
												<Row>
													<Col>{item.productCode}</Col>
													<Col>{item.name}</Col>
													<Col>{item.qty}</Col>
													<Col>¥{item.sellingPrice}</Col>
													<Col>¥{(item.qty * item.sellingPrice).toFixed(2)}</Col>
												</Row>
											</ListGroup.Item>
										</>
									))}
								</ListGroup.Item>
							</ListGroup>
						</Card>
					</Col>
				</Row>
			</>
		),
	});

	return (
		<>
			{isLoading ? (
				<Loader />
			) : isError ? (
				<Message variant='danger'></Message>
			) : orders.length !== 0 ? (
				<MaterialReactTable table={table} />
			) : (
				<>
					<div className='d-flex justify-content-center'>
						<p className='vh-75 fs-9'>
							没有订单？<Link to='/order/create/new'>去新建</Link>
						</p>
					</div>
				</>
			)}
		</>
	);
};

export default OrderListAssistantTable;
