import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

import AdvanceTable from '../../components/common/advance-table/AdvanceTable';
import SubtleBadge from '../../components/common/SubtleBadge';
import Loader from '../../components/Loader';
import useAdvanceTable from '../../hooks/useAdvanceTable';
import AdvanceTableProvider from '../../providers/AdvanceTableProvider';
import { useGetMyOrderApprovalsQuery } from '../../slices/approvalsApiSlice';
import AdvanceTableFooter from '../../components/common/advance-table/AdvanceTableFooter';

const columns = [
	{
		accessorKey: 'title',
		header: '审批事项',
		meta: {
			headerProps: { className: 'pe-7 text-900' },
		},
		cell: ({ row: { original } }) => {
			const { _id, title, detail } = original;
			return <Link to={`/approval/order/${detail.orderOID}/detail/${_id}`}>{title}</Link>;
		},
	},
	{
		accessorKey: 'status',
		header: '审批状态',
		meta: {
			headerProps: { className: 'text-center text-900' },
			cellProps: { className: 'fs-9' },
		},
		cell: ({ row: { original } }) => {
			const { status } = original;
			let icon = '';
			let bg = '';
			let text = '';
			switch (status) {
				case 'waitingApproval':
					icon = 'circle-question';
					bg = 'info';
					text = '待审批';
					break;
				case 'approved':
					icon = 'check';
					bg = 'success';
					text = '通过';
					break;
				case 'reject':
					icon = 'xmark';
					bg = 'danger';
					text = '驳回';
					break;
				default:
					break;
			}
			return (
				<SubtleBadge pill bg={bg} className='px-4 py-1'>
					<FontAwesomeIcon icon={icon} className='me-1' />
					<span>{text}</span>
				</SubtleBadge>
			);
		},
	},
	{
		accessorKey: 'applicant.name',
		header: '销售员',
	},
	{
		accessorKey: 'assistant.name',
		header: '销售助理',
	},
];

const OrderApprovalListTable = () => {
	const { data: myApprovals, isLoading, refetch } = useGetMyOrderApprovalsQuery();
	const table = useAdvanceTable({
		data: myApprovals ? myApprovals : [],
		columns,
		sortable: true,
		pagination: true,
		perPage: 20,
	});
	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<AdvanceTableProvider {...table}>
			{isLoading ? (
				<Loader />
			) : (
				<AdvanceTable
					headerClassName='bg-200 text-nowrap align-middle'
					rowClassName='btn-reveal-trigger text-nowrap align-middle'
					tableProps={{
						size: 'sm',
						className: 'fs-10 mb-0 overflow-hidden',
					}}
				/>
			)}
			<AdvanceTableFooter rowInfo navButtons className='mt-3' />
		</AdvanceTableProvider>
	);
};

export default OrderApprovalListTable;
