/* eslint-disable react/jsx-pascal-case */
import { Box, IconButton, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { useCreateCustomerMutation, useDeleteCustomerMutation, useGetCustomersQuery, useUpdateCustomerMutation } from '../../slices/customersApiSlice';
//Import Material React Table Translations
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import FalconCloseButton from '../../components/common/FalconCloseButton';
import AddStaffToCustomerModal from '../customer/AddStaffToCustomerModal';
import CustomerCreateApplyModal from '../customer/CustomerCreateApplyModal';

const CustomerListScreen = () => {
	const [validationErrors, setValidationErrors] = useState({});

	const [show, setShow] = useState(false);
	const [showBody, setShowBody] = useState('');
	const [customerId, setCustomerId] = useState('');

	const columns = [
		{
			accessorKey: 'name',
			header: '客户名称',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.name,
				helperText: validationErrors?.name,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						name: undefined,
					}),
			},
		},
		{
			accessorKey: 'person',
			header: '联系人',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.person,
				helperText: validationErrors?.person,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						person: undefined,
					}),
			},
		},
		{
			accessorKey: 'phone',
			header: '联系人电话',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.phone,
				helperText: validationErrors?.phone,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						phone: undefined,
					}),
			},
		},
		{
			accessorKey: 'email',
			header: '邮箱',
			muiEditTextFieldProps: {
				error: !!validationErrors?.email,
				helperText: validationErrors?.email,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						email: undefined,
					}),
			},
		},
		{
			accessorKey: 'wechat',
			header: '微信',
			muiEditTextFieldProps: {
				error: !!validationErrors?.wechat,
				helperText: validationErrors?.wechat,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						wechat: undefined,
					}),
			},
		},
		{
			accessorKey: 'deliveryAddress.city',
			header: '城市',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.city,
				helperText: validationErrors?.city,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						city: '',
					}),
			},
		},
		{
			accessorKey: 'deliveryAddress.address',
			header: '详细地址',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.address,
				helperText: validationErrors?.address,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						address: undefined,
					}),
			},
		},
		{
			accessorKey: 'deliveryAddress.postalCode',
			header: '邮编',
			muiEditTextFieldProps: {
				required: true,
				error: !!validationErrors?.postalCode,
				helperText: validationErrors?.postalCode,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						postalCode: undefined,
					}),
			},
		},
		{
			accessorKey: 'deliveryAddress.country',
			header: '国家',
			muiEditTextFieldProps: {
				error: !!validationErrors?.country,
				helperText: validationErrors?.country,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						country: undefined,
					}),
			},
		},
		{
			accessorKey: 'user.name',
			header: '创建人',
			enableEditing: false,
		},
		{
			accessorKey: 'createdAt',
			header: '创建时间',
			enableEditing: false,
		},
	];

	// call CREATE hook
	const [createCustomer, { isPending: isCreatingCustomer }] = useCreateCustomerMutation();

	// call READ hook
	const { data: fetchedCustomers = [], isError: isLoadingCustomersError, isFetching: isFetchingCustomers, isLoading: isLoadingCustomers, refetch } = useGetCustomersQuery();

	// call UPDATE hook
	const [updateCustomer, { isPending: isUpdatingCustomer }] = useUpdateCustomerMutation();

	//call DELETE hook
	const [deleteCustomer, { isPending: isDeletingCustomer }] = useDeleteCustomerMutation();

	// CREATE action
	const handleCreateCustomer = async ({ values, table }) => {
		const createValues = {
			name: values.name ? values.name : '',
			person: values.person ? values.person : '',
			phone: values.phone ? values.phone : '',
			email: values.email ? values.email : '',
			wechat: values.wechat ? values.wechat : '',
			deliveryAddress: {
				country: values['deliveryAddress.country'] ? values['deliveryAddress.country'] : '',
				city: values['deliveryAddress.city'] ? values['deliveryAddress.city'] : '',
				postalCode: values['deliveryAddress.postalCode'] ? values['deliveryAddress.postalCode'] : '',
				address: values['deliveryAddress.address'] ? values['deliveryAddress.address'] : '',
			},
		};

		const newValidationErrors = validateCustomer(createValues);
		if (Object.values(newValidationErrors).some((error) => error)) {
			setValidationErrors(newValidationErrors);
			return;
		}

		setValidationErrors({});

		await createCustomer(createValues);
		table.setCreatingRow(null); //exit creating mode
		refetch();
	};

	// UPDATE action
	const handleSaveCustomer = async ({ row, values, table }) => {
		const editValues = {
			customerId: row.id ? row.id : '',
			name: values.name ? values.name : '',
			person: values.person ? values.person : '',
			phone: values.phone ? values.phone : '',
			email: values.email ? values.email : '',
			wechat: values.wechat ? values.wechat : '',
			deliveryAddress: {
				country: values['deliveryAddress.country'] ? values['deliveryAddress.country'] : '',
				city: values['deliveryAddress.city'] ? values['deliveryAddress.city'] : '',
				postalCode: values['deliveryAddress.postalCode'] ? values['deliveryAddress.postalCode'] : '',
				address: values['deliveryAddress.address'] ? values['deliveryAddress.address'] : '',
			},
		};

		const newValidationErrors = validateCustomer(editValues);
		if (Object.values(newValidationErrors).some((error) => error)) {
			setValidationErrors(newValidationErrors);
			return;
		}

		setValidationErrors({});

		await updateCustomer(editValues);
		table.setEditingRow(null); //exit editing mode
		refetch();
	};

	const handleClose = () => setShow(false);
	const handleShow = (row) => {
		setShow(true);
		setCustomerId(row.original._id);
		setShowBody(`你确定要删除客户: ${row.original.name} 吗？`);
	};
	// DELETE action
	const handleConfirmDelete = async () => {
		setShow(false);
		await deleteCustomer({ customerId });
		setCustomerId('');
		refetch();
	};
	//DELETE action
	// const openDeleteConfirmModal = (row) => {
	// 	if (window.confirm('Are you sure you want to delete this user?')) {
	// 		// deleteUser(row.original.id);
	// 	}
	// };

	const table = useMaterialReactTable({
		columns,
		data: fetchedCustomers,
		createDisplayMode: 'modal', // ('modal', and 'custom' are also available)
		editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
		enableExpandAll: false, //hide expand all double arrow in column header
		enableExpanding: true,
		enableEditing: true,
		enableDensityToggle: false,
		enableSorting: true,
		enableGrouping: true,
		enableRowSelection: true,
		getRowId: (row) => row._id,
		enableFullScreenToggle: true,
		enableMultiRowSelection: false,
		localization: MRT_Localization_ZH_HANS,
		maxLeafRowFilterDepth: 2,

		positionToolbarAlertBanner: 'bottom',
		muiToolbarAlertBannerProps: isLoadingCustomersError
			? {
					color: 'error',
					children: '错误的加载数据',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				minHeight: '500px',
			},
		},
		initialState: {
			density: 'compact',
			sorting: [
				{
					id: 'createdAt', //sort by age by default on page load
					desc: true,
				},
			],
			grouping: ['name'],
			expanded: true,
			pagination: { pageIndex: 0, pageSize: 50 },
			columnVisibility: { createdAt: false, 'user.name': false },
		},
		onCreatingRowCancel: () => setValidationErrors({}),
		onCreatingRowSave: handleCreateCustomer,
		onEditingRowCancel: () => setValidationErrors({}),
		onEditingRowSave: handleSaveCustomer,
		renderRowActions: ({ row, staticRowIndex, table }) => (
			<>
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Tooltip title='编辑'>
						<IconButton onClick={() => table.setEditingRow(row)}>
							<EditIcon />
						</IconButton>
					</Tooltip>
					<Tooltip title='删除'>
						<IconButton color='error' onClick={() => handleShow(row)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				</Box>
			</>
		),
		renderTopToolbarCustomActions: ({ table, internalEditComponents }) => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				{!isLoadingCustomers && !isFetchingCustomers && fetchedCustomers.length !== 0 && (
					<>
						<CustomerCreateApplyModal />
						{table.getSelectedRowModel().rows.length !== 0 ? (
							<AddStaffToCustomerModal selectedCustomer={table.getSelectedRowModel().rows.map((row) => row.original)} setRefetch={refetch} />
						) : (
							<></>
						)}
					</>
				)}
				{!isLoadingCustomers && !isFetchingCustomers && fetchedCustomers.length === 0 && (
					<>
						<CustomerCreateApplyModal />
					</>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingCustomers,
			isSaving: isCreatingCustomer || isUpdatingCustomer || isDeletingCustomer,
			showAlertBanner: isLoadingCustomersError,
			showProgressBars: isFetchingCustomers,
		},
	});

	return (
		<>
			<Card>
				<Card.Header>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<a href='/'>首页</a>
							</li>
							<li className='breadcrumb-item active' aria-current='page'>
								客户列表
							</li>
						</ol>
					</nav>
				</Card.Header>
				<Card.Body className='p-0'>
					<MaterialReactTable table={table} />
				</Card.Body>
			</Card>
			<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false}>
				<Modal.Header>
					<Modal.Title>Modal title</Modal.Title>
					<FalconCloseButton onClick={handleClose} />
				</Modal.Header>
				<Modal.Body>{showBody}</Modal.Body>
				<Modal.Footer>
					<Button variant='secondary' onClick={handleClose}>
						取消
					</Button>
					<Button variant='primary' onClick={handleConfirmDelete}>
						确定
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CustomerListScreen;

const validateRequired = (value) => !!value?.length;
// const validateEmail = (email) =>
// 	!!email?.length &&
// 	email
// 		.toLowerCase()
// 		.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
function validateCustomer(customer) {
	return {
		name: !validateRequired(customer.name) ? '客户名称是必填字段' : '',
		person: !validateRequired(customer.person) ? '联系人是必填字段' : '',
		city: !validateRequired(customer.deliveryAddress.city) ? '城市是必填字段' : '',
		address: !validateRequired(customer.deliveryAddress.address) ? '详细地址是必填字段' : '',
	};
}
