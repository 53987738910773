// fetchBaseQuery is the function that will allow us to make requests to our backend API.
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../constants';

import { setCredentials, logout } from './authSlice'; // Import the logout action

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result.error && result.error.status === 401) {
		// try to get a new token
		const refreshResult = await baseQuery('/refreshToken', api, extraOptions);
		if (refreshResult.data) {
			// store the new token
			api.dispatch(setCredentials(refreshResult.data));
			// retry the initial query
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logout());
		}
	}
	return result;
};
async function baseQueryWithAuth(args, api, extra) {
	const result = await baseQuery(args, api, extra);

	// Dispatch the logout action on 401.
	if (result.error && result.error.status === 401) {
		api.dispatch(logout());
	}
	return result;
}

// endpoints is an object that will contain all of our API endpoints. We don't have to manually fetch data from our API anymore. We can just call the endpoints object and it will automatically fetch the data for us.
export const apiSlice = createApi({
	baseQuery: baseQueryWithAuth, // Use the customized baseQuery
	baseQueryWithReauth: baseQueryWithReauth,
	tagTypes: ['Product', 'Customer', 'Order', 'User', 'OrderDraft', 'Approval', 'Notification'],
	endpoints: (builder) => ({}),
});
