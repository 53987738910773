import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Spinner } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import Background from '../../common/Background';
import SubtleBadge from '../../common/SubtleBadge';
import bg1 from '../../../assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from '../../../assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from '../../../assets/img/icons/spot-illustrations/corner-3.png';

const StatisticsCard = ({ stat, loadingStatsData, ...rest }) => {
	const { title, value, decimal, suffix, prefix, valueClassName, badgeBg, badgeText, link, linkText, className } = stat;

	return (
		<Card className={classNames(className, 'overflow-hidden')} {...rest}>
			<>
				{title === '客户数量' && <Background image={bg1} className='bg-card' />}
				{title === '订单数量' && <Background image={bg2} className='bg-card' />}
				{title === '总收入' && <Background image={bg3} className='bg-card' />}
				<Card.Body className='position-relative'>
					<h6>
						{title}
						{badgeText && (
							<SubtleBadge bg={badgeBg} pill className='ms-2'>
								{badgeText}
							</SubtleBadge>
						)}
					</h6>

					<>
						{loadingStatsData ? (
							<div>
								<Spinner variant='primary' size='md' />
							</div>
						) : (
							<div className={classNames(valueClassName, 'display-4 fs-5 mb-2 fw-normal font-sans-serif')}>
								<CountUp start={0} end={value} duration={2.75} suffix={suffix} prefix={prefix} separator=',' decimals={decimal ? 2 : 0} decimal='.'></CountUp>
							</div>
						)}

						<Link to={link} className='fw-semibold fs-10 text-nowrap'>
							{linkText}
							<FontAwesomeIcon icon='angle-right' className='ms-1' transform='down-1' />
						</Link>
					</>
				</Card.Body>
			</>
		</Card>
	);
};

StatisticsCard.proTypes = {
	stat: PropTypes.shape({
		title: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		decimal: PropTypes.bool,
		suffix: PropTypes.string.isRequired,
		prefix: PropTypes.string.isRequired,
		valueClassName: PropTypes.string,
		linkText: PropTypes.string,
		link: PropTypes.string,
		badgeText: PropTypes.string,
		badgeBg: PropTypes.string,
		image: PropTypes.string,
		className: PropTypes.string,
	}),
};
export default StatisticsCard;
