import { CUSTOMERS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const customersApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getCustomers: builder.query({
			query: () => ({
				url: CUSTOMERS_URL,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomerDetails: builder.query({
			query: (customerId) => ({
				url: `${CUSTOMERS_URL}/${customerId}`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomersByUser: builder.query({
			query: () => ({
				url: `${CUSTOMERS_URL}/mine`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomersBySale: builder.query({
			query: (sale) => ({
				url: `${CUSTOMERS_URL}/sale/${sale._id}`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomerById: builder.query({
			query: (customerId) => ({
				url: `${CUSTOMERS_URL}/${customerId}`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomerByName: builder.query({
			query: (customerName) => ({
				url: `${CUSTOMERS_URL}/${customerName}`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		getCustomerOptionsByUser: builder.query({
			query: () => ({
				url: `${CUSTOMERS_URL}/options`,
			}),
			providesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),
		createCustomer: builder.mutation({
			query: (customer) => ({
				url: CUSTOMERS_URL,
				method: 'POST',
				body: { ...customer },
			}),
			invalidatesTags: ['Customer'],
		}),

		updateCustomer: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Customer'],
		}),
		updateCustomerQuota: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}/quotas/${data.quotaId}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Customer'],
		}),
		deleteCustomer: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}`,
				method: 'DELETE',
				body: data,
			}),
			invalidatesTags: ['Customer'],
		}),
		checkCustomerName: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/check/customer`,
				body: data,
				method: 'POST',
			}),
			invalidatesTags: ['Customer'],
			keepUnusedDataFor: 5,
		}),

		addStaffToCustomer: builder.mutation({
			query: (person) => ({
				url: `${CUSTOMERS_URL}/staff/add`,
				method: 'POST',
				body: { ...person },
			}),
			invalidatesTags: ['Customer'],
		}),

		updateCustomerStaff: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}/staff/${data.staffId}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Customer'],
		}),

		deleteCustomerStaff: builder.mutation({
			query: (data) => ({
				url: `${CUSTOMERS_URL}/${data.customerId}/staff/${data.staffId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Customer'],
		}),
	}),
});

export const {
	useGetCustomersQuery,
	useGetCustomersByUserQuery,
	useGetCustomerOptionsByUserQuery,
	useGetCustomerByIdQuery,
	useGetCustomerByNameQuery,
	useCreateCustomerMutation,
	useAddStaffToCustomerMutation,
	useGetCustomerDetailsQuery,
	useUpdateCustomerQuotaMutation,
	useUpdateCustomerStaffMutation,
	useDeleteCustomerStaffMutation,
	useUpdateCustomerMutation,
	useDeleteCustomerMutation,
	useCheckCustomerNameMutation,
	useGetCustomersBySaleQuery,
} = customersApiSlice;
