import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, OverlayTrigger, Placeholder, Row, Table, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../../components/common/Background';
import CardDropdown from '../../../components/common/CardDropdown';
import { clearCustomer } from '../../../slices/orderSlice';
import corner1 from './../../../assets/img/illustrations/authentication-corner.png';
import SelectOrderCustomerModal from './table/SelectOrderCustomerModal';

const OrderCustomer = () => {
	const order = useSelector((state) => state.order);
	const { userInfo } = useSelector((state) => state.auth);
	const { watch } = useFormContext();
	const { draftId } = watch();
	const { customer } = order;
	const [modalShow, setModalShow] = useState(false);
	const dispatch = useDispatch();

	return (
		<>
			<Card className='mb-3'>
				<Background
					image={corner1}
					className='bg-card d-none d-md-block'
					style={{
						borderTopRightRadius: '0.375rem',
						borderBottomRightRadius: '0.375rem',
					}}
				/>
				<Card.Header className='position-relative'>
					<Row className='flex-between-center'>
						<Col md>
							<h6 className='mb-2 mb-md-0 me-1'>客户信息</h6>
						</Col>
						<Col xs='auto'>
							<CardDropdown drop='start'>
								<div className='py-2'>
									<Dropdown.Item
										className='text-danger'
										onClick={() => {
											dispatch(clearCustomer());
										}}
									>
										清空
									</Dropdown.Item>
								</div>
							</CardDropdown>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body className='position-relative'>
					{Object.values(customer).length === 0 && (
						<Button
							variant='falcon-primary'
							size='sm'
							className='rounded-pill mb-1'
							type='button'
							onClick={() => setModalShow(true)}
							disabled={userInfo.role === 'assistant' && Object.values(order.sale).length === 0}
						>
							<span className='text-primary'>选择客户</span>
							<FontAwesomeIcon icon='fa-solid fa-user-tie' className='text-primary fs-9 ms-1' />
						</Button>
					)}

					<Table borderless size='sm' className='fs--1'>
						<tbody>
							{Object.values(customer).length !== 0 && (
								<>
									<tr>
										<th className='text-sm-start'>客户名称</th>
										<td className='text-sm-start'>
											<span className='fs-8'>
												<strong className='border-bottom me-2 text-primary '>{customer.customerName}</strong>
											</span>
											<OverlayTrigger
												overlay={
													<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
														重新选择
													</Tooltip>
												}
											>
												<Button variant='falcon-primary' size='sm' className='rounded-pill' type='button' onClick={() => setModalShow(true)}>
													<FontAwesomeIcon icon='fa-arrows-rotate' />
												</Button>
											</OverlayTrigger>
										</td>
									</tr>
									<tr>
										<th className='text-sm-start'>联系人</th>
										<td className='text-sm-start'>
											{customer.staffName ? (
												<strong>{customer.staffName}</strong>
											) : draftId ? (
												<Placeholder animation='glow' className='mb-2'>
													<Placeholder xs={2} bg='primary' />
												</Placeholder>
											) : (
												<></>
											)}
										</td>
									</tr>
									<tr>
										<th className='text-sm-start'>联系人电话</th>
										<td className='text-sm-start'>
											{customer.staffPhone ? (
												<strong>{customer.staffPhone}</strong>
											) : draftId ? (
												<Placeholder animation='glow' className='mb-2'>
													<Placeholder xs={3} bg='primary' />
												</Placeholder>
											) : (
												<></>
											)}
										</td>
									</tr>
									{customer.staffWechat ? (
										<tr>
											<th className='text-sm-start'>微信</th>
											<td className='text-sm-start'>
												<strong>{customer.staffWechat}</strong>
											</td>
										</tr>
									) : (
										<></>
									)}
									{customer.staffEmail ? (
										<tr>
											<th className='text-sm-start'>邮箱</th>
											<td className='text-sm-start'>
												<strong>{customer.staffEmail}</strong>{' '}
											</td>
										</tr>
									) : (
										<></>
									)}
									<tr>
										<th className='text-sm-start'>地址</th>
										<td className='text-sm-start'>
											{customer.customerAddress ? (
												<strong>{customer.customerAddress}</strong>
											) : draftId ? (
												<Placeholder animation='glow' className='mb-2'>
													<Placeholder xs={7} bg='primary' />
												</Placeholder>
											) : (
												<></>
											)}
										</td>
									</tr>
								</>
							)}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			{/* Modal */}
			<SelectOrderCustomerModal modalShow={modalShow} setModalShow={setModalShow} />
		</>
	);
};

export default OrderCustomer;
