import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNotificationContext } from '../../providers/NotificationProvider';

const NotificationListScreen = () => {
	const { myNotifications } = useNotificationContext();

	return (
		<div>
			<Button as={Link} to='/dashboard' variant='outline-primary'>
				<FontAwesomeIcon icon='home' className='me-1' />
				首页
			</Button>
			<ListGroup className='mt-3'>
				{myNotifications.map((notification) => {
					return (
						<>
							<ListGroup.Item action active={notification.unread && true}>
								<div className='d-flex justify-content-between'>
									<span className='fs-9'>{notification.rawText}</span>
									<span className='fs-10 align-bottom'>{notification.time}</span>
									<span>来自：{notification.fromUser.name}</span>
								</div>
							</ListGroup.Item>
						</>
					);
				})}
			</ListGroup>
		</div>
	);
};

export default NotificationListScreen;
