import { ORDER_DRAFTS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const orderDraftsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		saveOrderAsDraft: builder.mutation({
			query: (orderDrafts) => ({
				url: ORDER_DRAFTS_URL,
				method: 'POST',
				body: { ...orderDrafts },
			}),
			invalidatesTags: ['OrderDraft'],
		}),

		getOrderDrafts: builder.query({
			query: () => ({
				url: ORDER_DRAFTS_URL,
			}),
			providesTags: ['OrderDraft'],
			keepUnusedDataFor: 5,
		}),
		getOrderDraftById: builder.query({
			query: (draftId) => ({
				url: `${ORDER_DRAFTS_URL}/${draftId}`,
			}),
			providesTags: ['OrderDraft'],
			keepUnusedDataFor: 5,
		}),
		getMyOrderDrafts: builder.query({
			query: () => ({
				url: `${ORDER_DRAFTS_URL}/mine`,
			}),
			providesTags: ['OrderDraft'],
			keepUnusedDataFor: 5,
		}),
		updateOrderDraft: builder.mutation({
			query: (data) => ({
				url: `${ORDER_DRAFTS_URL}/${data._id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['OrderDraft'],
		}),
		deleteOrderDraft: builder.mutation({
			query: (draftId) => ({
				url: `${ORDER_DRAFTS_URL}/${draftId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OrderDraft'],
		}),
	}),
});

export const {
	useSaveOrderAsDraftMutation,
	useGetOrderDraftsQuery,
	useGetOrderDraftByIdQuery,
	useUpdateOrderDraftMutation,
	useDeleteOrderDraftMutation,
	useGetMyOrderDraftsQuery,
} = orderDraftsApiSlice;
