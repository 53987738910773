import { Card, Col, Row, Table } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import Loader from '../../../components/Loader';
import Message from '../../../components/Message';
import { useGetUsersByGenechampQuery } from '../../../slices/usersApiSlice';

const GenechampUserListScreen = () => {
	const { data: users, isLoading, error } = useGetUsersByGenechampQuery();

	// const [deleteUser, { isLoading: loadingDelete }] = useDeleteUserMutation();

	// const deleteHandler = async (id) => {
	// 	if (window.confirm('确定要删除用户吗?')) {
	// 		try {
	// 			await deleteUser(id);
	// 			toast.success('用户已删除');
	// 			refetch();
	// 		} catch (err) {
	// 			toast.error(err?.data?.message || err.error);
	// 		}
	// 	}
	// };
	return (
		<>
			<Card>
				<Card.Header className='border-bottom'>
					<Row className='align-items-center'>
						<Col>
							<h3>用户列表</h3>
						</Col>
					</Row>
				</Card.Header>
				<Card.Body>
					{/* {loadingDelete && <Loader />}
					{loadingDelete && <Loader />} */}
					{isLoading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error.data.message}</Message>
					) : (
						<Table hover responsive>
							<thead>
								<tr>
									<th>名字</th>
									<th>销售代码</th>
									<th>邮箱</th>
									<td>总经理</td>
									<td>客户服务</td>
									<td>销售员</td>
									<td>采购员</td>
									<td>其他</td>
								</tr>
							</thead>
							<tbody>
								{users.map((user) => (
									<tr key={user._id} className='hover-actions-trigger'>
										<td>{user.name}</td>
										<td>{user.alias}</td>
										<td>
											<a href={`mailto:${user.email}`}>{user.email}</a>
										</td>
										<td>{user.role === 'manager' ? <FaCheck style={{ color: 'green' }} /> : '--'}</td>
										<td>{user.role === 'assistant' ? <FaCheck style={{ color: 'green' }} /> : '--'}</td>
										<td>{user.role === 'sale' ? <FaCheck style={{ color: 'green' }} /> : '--'}</td>
										<td>{user.role === 'buyer' ? <FaCheck style={{ color: 'green' }} /> : '--'}</td>
										<td>{user.role === 'user' ? <FaCheck style={{ color: 'green' }} /> : '--'}</td>
										{/* <td className='w-auto'>
											<div className='end-0 top-50 pe-3 translate-middle-y hover-actions'>
												<Button variant='light' size='sm' className='border-300 me-1 text-600' as={Link} to={`/admin/user/${user._id}/edit`}>
													<FontAwesomeIcon icon='edit' />
												</Button>
												<Button variant='light' size='sm' className='border-300 text-600' onClick={() => deleteHandler(user._id)}>
													<FontAwesomeIcon icon='trash-alt' />
												</Button>
											</div>
										</td> */}
									</tr>
								))}
							</tbody>
						</Table>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default GenechampUserListScreen;
