import React from 'react';
import { Col, Row } from 'react-bootstrap';
import QuickLinks from './QuickLinks.jsx';
import StatisticsCards from '../../components/dashboard/stats-cards/StatisticsCards.jsx';
import { totalSales } from '../../data/dashboard/default.js';
import RecentPurchases from './RecentPurchases.jsx';
import TotalSales from './TotalSales.jsx';

const DashboardScreen = () => {
	return (
		<>
			<Row className='g-3'>
				<Col md={3} xl={3} xxl={3}>
					<QuickLinks />
				</Col>
				<Col md={9} xl={9} xxl={9}>
					<StatisticsCards />
					<RecentPurchases />
					<TotalSales data={totalSales} />
				</Col>
			</Row>
		</>
	);
};

export default DashboardScreen;
