import { NOTIFICATIONS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const notificationsApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		notifyCustomerApplyToApprover: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/customer/notify/approver`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		notifyCustomerApplyToApplicant: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/customer/notify/applicant`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		notifyCustomerApprovedToApplicant: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/customer/approved/notify/applicant`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		notifyOrderApplyToApprover: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/order/notify/approver`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		notifyOrderApplyToApplicant: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/order/notify/applicant`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		notifyOrderApprovedToApplicant: builder.mutation({
			query: (notification) => ({
				url: `${NOTIFICATIONS_URL}/order/approved/notify/applicant`,
				method: 'POST',
				body: notification,
			}),
			invalidatesTags: ['Notification'],
		}),
		getMyNotifications: builder.query({
			query: () => ({
				url: `${NOTIFICATIONS_URL}/mine`,
			}),
			providesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
		getMyUnreadNotifications: builder.query({
			query: () => ({
				url: `${NOTIFICATIONS_URL}/unread`,
			}),
			providesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
		getMyNewNotifications: builder.query({
			query: () => ({
				url: `${NOTIFICATIONS_URL}/new`,
			}),
			providesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
		setNewNotificationToRead: builder.mutation({
			query: (notifications) => ({
				url: `${NOTIFICATIONS_URL}/set/read`,
				method: 'PUT',
				body: notifications,
			}),
			invalidatesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
		setAllNewNotificationToRead: builder.mutation({
			query: (notifications) => ({
				url: `${NOTIFICATIONS_URL}/set/allread`,
				method: 'PUT',
				body: notifications,
			}),
			invalidatesTags: ['Notification'],
			keepUnusedDataFor: 5,
		}),
	}),
});

export const {
	useGetMyNotificationsQuery,
	useGetMyNewNotificationsQuery,
	useGetMyUnreadNotificationsQuery,
	useSetNewNotificationToReadMutation,
	useSetAllNewNotificationToReadMutation,
	useNotifyCustomerApplyToApplicantMutation,
	useNotifyCustomerApplyToApproverMutation,
	useNotifyCustomerApprovedToApplicantMutation,
	useNotifyOrderApplyToApproverMutation,
	useNotifyOrderApplyToApplicantMutation,
	useNotifyOrderApprovedToApplicantMutation,
} = notificationsApiSlice;
