function formatDate(date) {
	const rawDate = new Date(date);
	const YY = rawDate.getFullYear() + '-';
	const MM = (rawDate.getMonth() + 1 < 10 ? '0' + (rawDate.getMonth() + 1) : rawDate.getMonth() + 1) + '-';
	const DD = rawDate.getDate() < 10 ? '0' + rawDate.getDate() : rawDate.getDate();
	const hh = (rawDate.getHours() < 10 ? '0' + rawDate.getHours() : rawDate.getHours()) + ':';
	const mm = (rawDate.getMinutes() < 10 ? '0' + rawDate.getMinutes() : rawDate.getMinutes()) + ':';
	const ss = rawDate.getSeconds() < 10 ? '0' + rawDate.getSeconds() : rawDate.getSeconds();
	return YY + MM + DD + ' ' + hh + mm + ss;
}

export default formatDate;
