/* eslint-disable react/jsx-pascal-case */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { useProductContext } from '../../providers/ProductProvider';
import formatDate from '../../utils/formatDate';

const ProductListAdminScreen = () => {
	const [validationErrors, setValidationErrors] = useState({});

	const {
		fetchedProducts,
		isLoadingProductsError,
		isLoadingProducts,
		isFetchingProducts,
		refetch,
		createProduct,
		isCreatingProduct,
		updateProduct,
		isUpdatingProduct,
		deleteProduct,
		isDeletingProduct,
	} = useProductContext();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'productCode',
				header: '货号',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.productCode,
					helperText: validationErrors?.productCode,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							firstName: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'name',
				header: '品名',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.name,
					helperText: validationErrors?.name,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							name: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'brand',
				header: '品牌',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.brand,
					helperText: validationErrors?.brand,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							brand: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'category',
				header: '种类',
				editVariant: 'select',
				editSelectOptions: ['设备', '试剂', '耗材', '服务'],
				muiEditTextFieldProps: {
					select: true,
					error: !!validationErrors?.category,
					helperText: validationErrors?.category,
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							category: undefined,
						}),
				},
			},
			{
				accessorKey: 'supplierName',
				header: '厂家',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.supplierName,
					helperText: validationErrors?.supplierName,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							supplierName: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'specification',
				header: '规格',
				muiEditTextFieldProps: {
					required: true,
					error: !!validationErrors?.specification,
					helperText: validationErrors?.specification,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							specification: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'countInStock',
				header: '库存',
				muiEditTextFieldProps: {
					required: true,
					type: 'number',
					error: !!validationErrors?.countInStock,
					helperText: validationErrors?.countInStock,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							countInStock: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'purchasingPrice',
				header: '进价',
				muiEditTextFieldProps: {
					required: true,
					type: 'number',
					error: !!validationErrors?.purchasingPrice,
					helperText: validationErrors?.purchasingPrice,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							purchasingPrice: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'minimumSellingPrice',
				header: '单价',
				muiEditTextFieldProps: {
					required: true,
					type: 'number',
					error: !!validationErrors?.minimumSellingPrice,
					helperText: validationErrors?.minimumSellingPrice,
					//remove any previous validation errors when Product focuses on the input
					onFocus: () =>
						setValidationErrors({
							...validationErrors,
							minimumSellingPrice: undefined,
						}),
					//optionally add validation checking for onBlur or onChange
				},
			},
			{
				accessorKey: 'user.name',
				header: '创建人',
				enableEditing: false,
			},
			{
				accessorKey: 'createdAt',
				header: '创建时间',
				enableEditing: false,
				Cell: ({ cell }) => <span>{cell.getValue('createdAt') && formatDate(cell.getValue('createdAt'))}</span>,
			},
		],
		[validationErrors]
	);

	// CREATE action
	const handleCreateProduct = async ({ values, table }) => {
		const newValidationErrors = validateProduct(values);
		if (Object.values(newValidationErrors).some((error) => error)) {
			setValidationErrors(newValidationErrors);
			return;
		}
		setValidationErrors({});
		await createProduct(values);
		table.setCreatingRow(null); //exit creating mode
		refetch();
	};

	// UPDATE action
	const handleSaveProduct = async ({ values, table }) => {
		const newValidationErrors = validateProduct(values);
		if (Object.values(newValidationErrors).some((error) => error)) {
			setValidationErrors(newValidationErrors);
			return;
		}
		setValidationErrors({});
		await updateProduct(values);
		table.setEditingRow(null); //exit editing mode
	};

	// DELETE action
	const openDeleteConfirmModal = async (row) => {
		if (window.confirm('你确定想要删除这个产品吗?')) {
			await deleteProduct(row.original._id);
			refetch();
		}
	};

	const table = useMaterialReactTable({
		columns,
		data: fetchedProducts,
		createDisplayMode: 'row', // ('modal', and 'custom' are also available)
		editDisplayMode: 'row', // ('modal', 'cell', 'table', and 'custom' are also available)
		enableEditing: true,
		getRowId: (row) => row._id,
		enableFullScreenToggle: true,
		localization: MRT_Localization_ZH_HANS,
		initialState: {
			density: 'compact',
			sorting: [
				{
					id: 'createdAt', //sort by age by default on page load
					desc: true,
				},
			],
		},
		muiToolbarAlertBannerProps: isLoadingProductsError
			? {
					color: 'error',
					children: 'Error loading data',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				minHeight: '500px',
			},
		},
		onCreatingRowCancel: () => setValidationErrors({}),
		onCreatingRowSave: handleCreateProduct,
		onEditingRowCancel: () => setValidationErrors({}),
		onEditingRowSave: handleSaveProduct,
		renderRowActions: ({ row, table }) => (
			<>
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<Tooltip title='编辑'>
						<Button variant='light' size='sm' className='border-300 me-1 text-600' as={Button} onClick={() => table.setEditingRow(row)}>
							<FontAwesomeIcon icon='edit' />
						</Button>
					</Tooltip>
					<Tooltip title='删除'>
						<Button variant='light' size='sm' className='border-300 text-600' onClick={() => openDeleteConfirmModal(row)}>
							<FontAwesomeIcon icon='trash-alt' />
						</Button>
					</Tooltip>
				</Box>
			</>
		),
		renderTopToolbarCustomActions: ({ table }) => (
			<Button
				variant='primary'
				onClick={() => {
					table.setCreatingRow(true);
				}}
			>
				新增产品
			</Button>
		),
		state: {
			isLoading: isLoadingProducts,
			isSaving: isCreatingProduct || isUpdatingProduct || isDeletingProduct,
			showAlertBanner: isLoadingProductsError,
			showProgressBars: isFetchingProducts,
		},
	});

	return (
		<>
			<Card>
				<Card.Header>
					<nav aria-label='breadcrumb'>
						<ol className='breadcrumb'>
							<li className='breadcrumb-item'>
								<a href='/'>首页</a>
							</li>
							<li className='breadcrumb-item active' aria-current='page'>
								产品列表
							</li>
						</ol>
					</nav>
				</Card.Header>
				<Card.Body className='p-0'>
					<MaterialReactTable table={table} />
				</Card.Body>
			</Card>
		</>
	);
};

export default ProductListAdminScreen;

const validateRequired = (value) => !!value.length;

function validateProduct(customer) {
	return {
		name: !validateRequired(customer.name) ? '必填项！' : '',
		brand: !validateRequired(customer.brand) ? '必填项！' : '',
		productCode: !validateRequired(customer.productCode) ? '必填项！' : '',
		price: !validateRequired(customer.price) ? '必填项！' : '',
		countInStock: !validateRequired(customer.countInStock) ? '必填项！' : '',
		supplierName: !validateRequired(customer.supplierName) ? '必填项！' : '',
	};
}
