export const addDecimals = (num) => {
	return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateOrder = (state) => {
	// Calculate items price
	state.itemsPrice = addDecimals(state.orderItems.reduce((acc, item) => acc + item.sellingPrice * item.qty, 0));

	state.totalPrice = Number(state.itemsPrice).toFixed(2);

	localStorage.setItem('order', JSON.stringify(state)); // JS Object to JSON String

	return state;
};
