import classNames from 'classnames';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useUpdateCustomerStaffMutation } from '../../slices/customersApiSlice';
import { useEffect } from 'react';

const EditStaffToCustomerModal = ({ selectedCustomer, selectedStaff, setEditModalShow, editModalShow, setSelectedStaff, setSelectedCustomer }) => {
	const [updateStaff, { isLoading: isUpdatingStaff }] = useUpdateCustomerStaffMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
	} = useForm({
		defaultValues: {
			personName: selectedStaff.name,
			personPhone: selectedStaff.phone,
			personWechat: selectedStaff.wechat,
			personEmail: selectedStaff.email,
		},
	});

	const submitNewPerson = async (data) => {
		try {
			console.log(data);
			await updateStaff({
				customerId: selectedCustomer._id,
				staffId: selectedStaff._id,
				personName: data.personName,
				personPhone: data.personPhone,
				personWechat: data.personWechat,
				personEmail: data.personEmail,
			}).unwrap();
			setEditModalShow(false);
			toast.success('联系人更新成功！');
		} catch (err) {
			toast.error(err.data.message);
		}
	};
	useEffect(() => {
		setValue('personName', selectedStaff.name);
		setValue('personPhone', selectedStaff.phone);
		setValue('personWechat', selectedStaff.wechat);
		setValue('personEmail', selectedStaff.email);
	}, [selectedCustomer.staffs, selectedStaff.email, selectedStaff.name, selectedStaff.phone, selectedStaff.wechat, setValue]);
	return (
		<>
			<Modal
				show={editModalShow}
				onHide={() => {
					setSelectedStaff({});
					setSelectedCustomer({});
					setEditModalShow(false);
				}}
				size='lg'
				aria-labelledby='contained-modal-title-vcenter'
				centered
				backdrop='static'
			>
				<Form
					onSubmit={(e) => {
						if (e) {
							if (typeof e.preventDefault === 'function') {
								e.preventDefault();
							}
							if (typeof e.stopPropagation === 'function') {
								e.stopPropagation();
							}
							return handleSubmit(submitNewPerson)(e);
						}
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>编辑客户联系人</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className='mb-3'>
							<Col sm='3'>
								<span className='text-primary fw-bold'>客户名称</span>
							</Col>
							<Col sm='6'>
								<span className='text-primary fw-bold'>{selectedCustomer.name}</span>
							</Col>
						</Row>
						<Form.Group as={Row} className='mb-3' controlId='personName'>
							<Form.Label column sm='3'>
								新联系人
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='personName' placeholder='姓名' autoComplete='false' {...register('personName', { required: true })} />
								{errors.personName?.type === 'required' && <p className='text-danger'>联系人姓名为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='personPhone'>
							<Form.Label column sm='3'>
								联系人电话(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='personPhone' placeholder='11位号码' autoComplete='false' {...register('personPhone')} />
								{errors.personPhone?.type === 'required' && <p className='text-danger'>联系人电话为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='personWechat'>
							<Form.Label column sm='3'>
								微信(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='personWechat' placeholder='联系人微信ID' autoComplete='false' {...register('personWechat')} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='personEmail'>
							<Form.Label column sm='3'>
								邮箱(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='personEmail' placeholder='eg: 123@xxx.com' autoComplete='false' {...register('personEmail')} />
							</Col>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={() => {
								setSelectedStaff({});
								setSelectedCustomer({});
								setEditModalShow(false);
							}}
						>
							关闭
						</Button>
						<Button variant='primary' type='submit' disabled={isUpdatingStaff} className='d-inline-flex flex-center'>
							{isUpdatingStaff && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
							<span className={classNames({ 'visually-hidden': isUpdatingStaff })}>更新</span>
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default EditStaffToCustomerModal;
