import { DASHBOARD_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const dashboardSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getStatisData: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/statis/card`,
			}),
		}),
		getPendingOrderForManager: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/manager`,
			}),
			providesTags: ['Order'],
		}),
		getPendingOrderForFinance: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/finance`,
			}),
			providesTags: ['Order'],
		}),
		getPendingOrderForBuyer: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/buyer`,
			}),
			providesTags: ['Order'],
		}),
		getPendingOrderForSale: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/sale`,
			}),
			providesTags: ['Order'],
			keepUnusedDataFor: 5,
		}),
		getPendingOrderForAssistant: builder.query({
			query: () => ({
				url: `${DASHBOARD_URL}/order/pending/assistant`,
			}),
			providesTags: ['Order'],
		}),
	}),
});

export const {
	useGetStatisDataQuery,
	useGetPendingOrderForManagerQuery,
	useGetPendingOrderForFinanceQuery,
	useGetPendingOrderForBuyerQuery,
	useGetPendingOrderForSaleQuery,
	useGetPendingOrderForAssistantQuery,
} = dashboardSlice;
