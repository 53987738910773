import React from 'react';
import { ToastContainer } from 'react-toastify';
import Background from '../components/common/Background';
import beachMp4 from '../assets/video/beach.mp4';
import beachWebm from '../assets/video/beach.webm';
import beachImage from '../assets/video/beach.jpg';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
const AuthCardLayout = () => {
	return (
		<>
			<Container fluid className='position-relative'>
				<Background video={[beachMp4, beachWebm]} image={beachImage} overlay='2' className='rounded-soft' />
				{/* <Background
						image='https://images.unsplash.com/photo-1510906594845-bc082582c8cc?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
						overlay='1'
						className='rounded-soft'
					/> */}
				<Outlet />
			</Container>
			<ToastContainer
				position='top-right'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='light'
			/>
			{/* Same as */}
			<ToastContainer />
		</>
	);
};

export default AuthCardLayout;
