/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Loader from '../../../components/Loader';
import { useInitiateOrderApprovalMutation } from '../../../slices/approvalsApiSlice';
import { useNotifyOrderApplyToApplicantMutation, useNotifyOrderApplyToApproverMutation } from '../../../slices/notificationsApiSlice';
import { useGetOrderDraftByIdQuery, useSaveOrderAsDraftMutation, useUpdateOrderDraftMutation } from '../../../slices/orderDraftsApiSlice';
import {
	addDraftItemsToOrder,
	clearScheduleNodes,
	resetOrder,
	saveAssistant,
	saveCustomer,
	saveOrderId,
	saveOrderIndexOfDay,
	savePaymentDetail,
	savePaymentMethod,
	saveQuotationId,
	saveSale,
	saveScheduleNodes,
} from '../../../slices/orderSlice';
import { useCreateOrderMutation, useGetNewIndexOfTodayBySelectedSaleMutation, useGetNewIndexOfTodayQuery } from '../../../slices/ordersApiSlice';
import OrderAssistant from './OrderAssistant';
import OrderBusiness from './OrderBusiness';
import OrderCustomer from './OrderCustomer';
import OrderFooter from './OrderFooter';
import OrderHeader from './OrderHeader';
import OrderPayment from './OrderPayment';
import OrderProduct from './OrderProduct';
import OrderSale from './OrderSale';

const useYupValidationResolver = (validationSchema) =>
	useCallback(
		async (data) => {
			try {
				let values = {};
				if (data.button === 'placeOrder_button') {
					values = await validationSchema.validate(data, {
						abortEarly: false,
					});
					return {
						values,
						errors: {},
					};
				}
				return {
					values: data,
					errors: {},
				};
			} catch (errors) {
				return {
					values: {},
					errors: errors.inner.reduce(
						(allErrors, currentError) => ({
							...allErrors,
							[currentError.path]: {
								type: currentError.type ?? 'validation',
								message: currentError.message,
							},
						}),
						{}
					),
				};
			}
		},
		[validationSchema]
	);
const placeOrderSchema = yup.object({
	paymentMethod: yup.string().required('请选择支付方式'),
	arrivalTime: yup.string().required('请选择到货时间'),
	warrantyPeriod: yup
		.number()
		.nullable()
		.integer('必须是整数')
		.min(0, '保修期要大于等于0')
		.transform((value) => (Number.isNaN(value) ? null : value))
		.required('请填写保修期'),
});

const handleGenerateOrderIdBySale = async (dispatch, sale, getOrderIndexBySelectedSale) => {
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth();
	const currentDay = new Date().getDate();
	const res = await getOrderIndexBySelectedSale(sale).unwrap();
	let generatedOrderIdBySale =
		sale.alias + '-' + currentYear + (currentMonth + 1 < 10 ? '0' + (currentMonth + 1) : currentMonth + 1) + (currentDay < 10 ? '0' + currentDay : currentDay) + res?.nextIndex;
	dispatch(saveOrderId(generatedOrderIdBySale));
	dispatch(saveOrderIndexOfDay(res.incrementIndex));
	dispatch(saveQuotationId(generatedOrderIdBySale));
};

const CreateOrderScreen = () => {
	const { draftId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const resolver = useYupValidationResolver(placeOrderSchema);
	const { userInfo } = useSelector((state) => state.auth);
	const order = useSelector((state) => state.order);
	const methods = useForm({
		resolver,
		defaultValues: {
			paymentMethod: order.paymentMethod === '全额支付' ? '全额支付' : order.paymentMethod,
			warrantyPeriod: order.paymentDetail?.warrantyPeriod,
			prePaymentOption: order.paymentDetail.prePaymentOption ? (order.paymentDetail.prePaymentOption === '百分比' ? '百分比' : order.paymentDetail.prePaymentOption) : '百分比',
			prePaymentPercent: order.paymentDetail.prePaymentPercent ? (order.paymentDetail.prePaymentPercent === 0 ? 0 : order.paymentDetail.prePaymentPercent) : 0,
			percentBalance: order.paymentDetail.percentBalance ? (order.paymentDetail.percentBalance === 0 ? 0 : order.paymentDetail.percentBalance) : 0,
			percentPayment: order.paymentDetail.percentPayment ? (order.paymentDetail.percentPayment === 0 ? 0 : order.paymentDetail.percentPayment) : 0,
			prePaymentBalance: order.paymentDetail.prePaymentBalance ? (order.paymentDetail.prePaymentBalance === 0 ? 0 : order.paymentDetail.prePaymentBalance) : 0,
			prePayment: order.paymentDetail.prePayment ? (order.paymentDetail.prePayment === 0 ? 0 : order.paymentDetail.prePayment) : 0,
			paymentExplain: order.paymentDetail.paymentExplain ? (order.paymentDetail.paymentExplain === '' ? '' : order.paymentDetail.paymentExplain) : '',
			arrivalTime: order.paymentDetail.arrivalTime ? (order.paymentDetail.arrivalTime === '' ? '' : order.paymentDetail.arrivalTime) : '',
			orderNotes: order.paymentDetail.orderNotes ? (order.paymentDetail.orderNotes === '' ? '' : order.paymentDetail.orderNotes) : '',
		},
	});
	const { handleSubmit, setValue } = methods;
	const currentYear = new Date().getFullYear();
	const currentMonth = new Date().getMonth();
	const currentDay = new Date().getDate();
	// Get a new index of day for generate orderId
	const { data: orderIndex, isLoading: loadingIndex, error, refetch: refetchOrderIndex } = useGetNewIndexOfTodayQuery();
	const [createOrder, { isLoading: orderCreating, error: createError }] = useCreateOrderMutation();
	const [saveOrderAsDraft, { isLoading: orderDraftSaving, error: saveError }] = useSaveOrderAsDraftMutation();
	const [skipLoadDraft, setSkipLoadDraft] = useState(true);
	// Get draft data by id
	const {
		data: orderDraft,
		isLoading: loadingDraft,
		error: loadingDraftError,
	} = useGetOrderDraftByIdQuery(draftId, {
		skip: skipLoadDraft,
	});
	const [updateOrderDraft, { isLoading: updatingDraft, error: updatingDraftError }] = useUpdateOrderDraftMutation();
	const [getOrderIndexBySelectedSale] = useGetNewIndexOfTodayBySelectedSaleMutation();

	const [initiateOrderApproval, { isLoading: initiatingApproval }] = useInitiateOrderApprovalMutation();
	const [newOrderId, setNewOrderId] = useState();

	const [notifyAppover] = useNotifyOrderApplyToApproverMutation();
	const [notifyApplicant] = useNotifyOrderApplyToApplicantMutation();

	const calculateAccountsReceivable = (paymentMethod, prePaymentOption, percentPayment, prePayment) => {
		let accountsReceivable = 0;
		switch (paymentMethod) {
			case '全额支付':
				accountsReceivable = order.totalPrice;
				break;
			case '部分支付':
				if (prePaymentOption === '百分比') {
					accountsReceivable = percentPayment;
				}
				if (prePaymentOption === '预付款') {
					accountsReceivable = prePayment;
				}
				break;
			default:
				break;
		}
		return accountsReceivable;
	};

	const onSubmit = async (data) => {
		const accountsReceivable = calculateAccountsReceivable(data?.paymentMethod, data.prePaymentOption, data.percentPayment, data.prePayment);
		try {
			if (data.button === 'saveAsDraft_button') {
				await saveOrderAsDraft({
					customer: order.customer,
					orderItems: order.orderItems,
					sale: order.sale,
					assistant: order.assistant,
					scheduleNodes: order.scheduleNodes,
					paymentMethod: data?.paymentMethod,
					prePaymentOption: data.prePaymentOption ? data.prePaymentOption : null,
					prePaymentPercent: data.prePaymentPercent ? data.prePaymentPercent : 0,
					percentPayment: data.percentPayment ? data.percentPayment : 0,
					percentBalance: data.percentBalance ? data.percentBalance : 0,
					prePayment: data.prePayment ? data.prePayment : 0,
					prePaymentBalance: data.prePaymentBalance,
					paymentExplain: data.paymentExplain ? data.paymentExplain : '',
					arrivalTime: data.arrivalTime,
					warrantyPeriod: data.warrantyPeriod,
					orderNotes: data.orderNotes ? data.orderNotes : '',
				}).unwrap();
				dispatch(resetOrder());
				navigate(`/${userInfo.role}/draftlist`);
			}
			if (data.button === 'placeOrder_button') {
				if (order.orderItems.some((item) => item.sellingPrice < item.minimumSellingPrice || data?.paymentMethod === '部分支付')) {
					const order_need_approval_res = await createOrder({
						customer: order.customer,
						orderItems: order.orderItems,
						sale: order.sale,
						assistant: order.assistant,
						scheduleNodes: data.paymentMethod === '部分支付' ? order.scheduleNodes : [],
						orderId: order.orderId,
						orderIndexOfDay: order.orderIndexOfDay,
						itemsPrice: order.itemsPrice,
						totalPrice: order.totalPrice,
						taxPrice: 0,
						// 增加订单应收账款
						accountsReceivable,
						// 付款方式('全额支付','额度支付','部分支付')
						paymentMethod: data?.paymentMethod,
						// 预付选项('百分比','预付款')
						prePaymentOption: data?.prePaymentOption ? data?.prePaymentOption : null,

						// 预支付百分比
						prePaymentPercent: data?.prePaymentPercent ? data?.prePaymentPercent : 0,
						// 预支付百分比金额
						percentPayment: data?.percentPayment ? data?.percentPayment : 0,
						// 预支付百分比剩余尾款
						percentBalance: data?.percentBalance ? data?.percentBalance : 0,

						// 预付款金额
						prePayment: data?.prePayment ? data?.prePayment : 0,
						// 预付款剩余尾款
						prePaymentBalance: data?.prePaymentBalance ? data?.prePaymentBalance : 0,
						paymentExplain: data?.paymentExplain ? data?.paymentExplain : '',
						arrivalTime: data.arrivalTime,
						warrantyPeriod: data?.warrantyPeriod,
						orderNotes: data?.orderNotes ? data?.orderNotes : '',
					}).unwrap();
					// 重置订单
					dispatch(resetOrder());
					if (!updatingDraft) {
						navigate(`/order/${order_need_approval_res._id}/success`);
					}
					// 发送订单审批事项
					const approval_res = await initiateOrderApproval({
						_id: order_need_approval_res._id,
						orderId: order_need_approval_res.orderId,
						applicant: order.sale,
						assistant: order.assistant,
					}).unwrap();

					const { approver, applicant, assistant, detail, _id } = approval_res;

					// 通知订单审批者审核
					await notifyAppover({ toUser: approver, fromUser: applicant, content: { detail, _id } }).unwrap();

					if (userInfo.role === 'assistant') {
						// 通知订单发起者审批进度
						await notifyApplicant({ toUser: assistant, fromUser: assistant, content: { detail, _id } });
					} else {
						// 通知订单发起者审批进度
						await notifyApplicant({ toUser: applicant, fromUser: applicant, content: { detail, _id } });
					}
				} else {
					const res = await createOrder({
						customer: order.customer,
						orderItems: order.orderItems,
						sale: order.sale,
						assistant: order.assistant,
						scheduleNodes: order.scheduleNodes,
						orderId: order.orderId,
						orderIndexOfDay: order.orderIndexOfDay,
						itemsPrice: order.itemsPrice,
						totalPrice: order.totalPrice,
						taxPrice: 0,
						// 增加订单应收账款
						accountsReceivable,
						// 付款方式('全额支付','额度支付','部分支付')
						paymentMethod: data?.paymentMethod,
						// 预付选项('百分比','预付款')
						prePaymentOption: data?.prePaymentOption ? data?.prePaymentOption : null,
						// 预支付百分比
						prePaymentPercent: data?.prePaymentPercent ? data?.prePaymentPercent : 0,
						// 预支付百分比金额
						percentPayment: data?.percentPayment ? data?.percentPayment : 0,
						// 预支付百分比剩余尾款
						percentBalance: data?.percentBalance ? data?.percentBalance : 0,
						// 预付款金额
						prePayment: data?.prePayment ? data?.prePayment : 0,
						// 预付款剩余尾款
						prePaymentBalance: data?.prePaymentBalance ? data?.prePaymentBalance : 0,
						paymentExplain: data?.paymentExplain ? data?.paymentExplain : '',
						arrivalTime: data.arrivalTime,
						warrantyPeriod: data?.warrantyPeriod,
						orderNotes: data?.orderNotes ? data?.orderNotes : '',
					}).unwrap();
					// 重置订单
					dispatch(resetOrder());
					if (!updatingDraft) {
						navigate(`/order/${res._id}/success`);
					}
				}
			}
			if (data.button === 'saveAsQuotation_button') {
				if (draftId) {
					await updateOrderDraft({
						_id: draftId,
						customer: order.customer,
						orderItems: order.orderItems,
						sale: order.sale._id ? order.sale : userInfo,
						assistant: order.assistant._id ? order.assistant : {},
						scheduleNodes: data?.prePaymentOption === '百分比' ? order.scheduleNodes : {},
						paymentMethod: data?.paymentMethod,
						prePaymentOption: data?.prePaymentOption ? data?.prePaymentOption : null,
						prePaymentPercent: data?.prePaymentPercent ? data?.prePaymentPercent : 0,
						percentPayment: data.percentPayment ? data.percentPayment : 0,
						percentBalance: data.percentBalance ? data.percentBalance : 0,
						prePayment: data?.prePayment ? data?.prePayment : 0,
						prePaymentBalance: data.prePaymentBalance ? data.prePaymentBalance : 0,
						paymentExplain: data?.paymentExplain ? data?.paymentExplain : '',
						arrivalTime: data?.arrivalTime,
						warrantyPeriod: data?.warrantyPeriod < 0 ? 0 : data?.warrantyPeriod,
						orderNotes: data?.orderNotes ? data?.orderNotes : '',
					}).unwrap();
				}
				dispatch(savePaymentMethod(data?.paymentMethod));
				dispatch(
					savePaymentDetail({
						prePaymentOption: data?.prePaymentOption ? data?.prePaymentOption : null,
						prePayment: data?.prePayment ? data?.prePayment : 0,
						prePaymentPercent: data?.prePaymentPercent ? data?.prePaymentPercent : 0,
						percentPayment: data?.percentPayment ? data?.percentPayment : 0,
						percentBalance: data?.percentBalance ? data?.percentBalance : 0,
						prePaymentBalance: data?.prePaymentBalance ? data?.prePaymentBalance : 0,
						paymentExplain: data?.paymentExplain ? data?.paymentExplain : '',
						arrivalTime: data?.arrivalTime,
						warrantyPeriod: data?.warrantyPeriod < 0 ? 0 : data?.warrantyPeriod,
						orderNotes: data?.orderNotes ? data?.orderNotes : '',
					})
				);
				if (draftId) {
					navigate(`/order/quotation/${draftId}`);
				} else {
					navigate(`/order/quotation`);
				}
			}
			if (data.button === 'updateDraft_button') {
				await updateOrderDraft({
					_id: draftId,
					customer: order.customer,
					orderItems: order.orderItems,
					sale: order.sale,
					assistant: order.assistant,
					scheduleNodes: order.scheduleNodes,
					paymentMethod: data?.paymentMethod,
					prePaymentOption: data?.prePaymentOption ? data?.prePaymentOption : null,
					prePaymentPercent: data?.prePaymentPercent ? data?.prePaymentPercent : 0,
					percentPayment: data.percentPayment ? data.percentPayment : 0,
					percentBalance: data.percentBalance ? data.percentBalance : 0,
					prePayment: data?.prePayment ? data?.prePayment : 0,
					prePaymentBalance: data.prePaymentBalance ? data.prePaymentBalance : 0,
					paymentExplain: data?.paymentExplain ? data?.paymentExplain : '',
					arrivalTime: data?.arrivalTime,
					warrantyPeriod: data?.warrantyPeriod,
					orderNotes: data?.orderNotes ? data?.orderNotes : '',
				}).unwrap();
				dispatch(resetOrder());
				toast.success('草稿已保存');
				navigate(`/${userInfo.role}/draftlist`);
			}
		} catch (err) {
			toast.error(error?.data?.message || error?.error);
		}
	};
	useMemo(() => {
		if (typeof draftId !== 'undefined') {
			setSkipLoadDraft(false); // draftId不是undefined时, 页面加载时不跳过查询草稿, 默认跳过(true)
			if (orderDraft) {
				dispatch(saveCustomer(orderDraft.customer));
				dispatch(addDraftItemsToOrder(orderDraft.orderItems));
				if (orderDraft?.assistant?._id) {
					dispatch(saveAssistant(orderDraft.assistant));
				}
				if (orderDraft?.sale?._id) {
					dispatch(saveSale(orderDraft.sale));
					handleGenerateOrderIdBySale(dispatch, orderDraft.sale, getOrderIndexBySelectedSale);
				}
				dispatch(saveScheduleNodes(orderDraft.scheduleNodes));
				setValue('draftId', orderDraft._id);
				setValue('paymentMethod', orderDraft.paymentMethod);
				setValue('prePaymentOption', orderDraft.prePaymentOption);
				setValue('prePaymentPercent', orderDraft.prePaymentPercent);
				setValue('percentPayment', orderDraft.percentPayment);
				setValue('percentBalance', orderDraft.percentBalance);
				setValue('prePayment', orderDraft.prePayment);
				setValue('prePaymentBalance', orderDraft.prePaymentBalance);
				setValue('paymentExplain', orderDraft.paymentExplain);
				setValue('arrivalTime', orderDraft.arrivalTime);
				setValue('warrantyPeriod', orderDraft.warrantyPeriod);
				setValue('orderNotes', orderDraft.orderNotes);
			}
		}
	}, [dispatch, draftId, getOrderIndexBySelectedSale, orderDraft, setValue]);

	useEffect(() => {
		if (userInfo.role !== 'assistant') {
			refetchOrderIndex();
			const generatedOrderId =
				userInfo.alias +
				'-' +
				currentYear +
				(currentMonth + 1 < 10 ? '0' + (currentMonth + 1) : currentMonth + 1) +
				(currentDay < 10 ? '0' + currentDay : currentDay) +
				orderIndex?.nextIndex;
			setNewOrderId(generatedOrderId);
		} else if (order.sale._id) {
			handleGenerateOrderIdBySale(dispatch, order.sale, getOrderIndexBySelectedSale);
			dispatch(saveAssistant(userInfo));
		} else {
			setNewOrderId('');
			dispatch(saveAssistant(userInfo));
		}

		const handleBeforeUnload = (e) => {
			e.preventDefault();
			dispatch(clearScheduleNodes());
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [
		currentDay,
		currentMonth,
		currentYear,
		dispatch,
		draftId,
		getOrderIndexBySelectedSale,
		order.sale,
		orderDraft,
		orderIndex?.nextIndex,
		refetchOrderIndex,
		setValue,
		userInfo,
		userInfo.alias,
		userInfo.role,
	]);

	return (
		<>
			<FormProvider {...methods}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Col>
						<Row className='g-3'>
							<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
								<OrderHeader
									newOrderId={newOrderId}
									incrementIndex={orderIndex?.incrementIndex}
									loadingIndex={loadingIndex}
									orderCreating={orderCreating}
									updatingDraft={updatingDraft}
									initiatingApproval={initiatingApproval}
									orderDraftSaving={orderDraftSaving}
									error={error}
								/>
							</Col>
							{loadingDraft ? (
								<Loader />
							) : (
								<>
									<Col lg={8}>
										<OrderCustomer loadingDraft={loadingDraft} />
										<OrderProduct />
										<OrderPayment />
									</Col>
									<Col lg={4}>
										<div className='sticky-sidebar z-1'>
											{userInfo.role === 'assistant' && order.sale._id && <OrderSale />}
											{['sale', 'manager', 'user'].includes(userInfo.role) && <OrderAssistant />}
											<OrderBusiness />
										</div>
									</Col>
									<Col>
										<OrderFooter />
									</Col>
								</>
							)}
						</Row>
					</Col>
				</Form>
			</FormProvider>
		</>
	);
};

export default CreateOrderScreen;
