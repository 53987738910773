import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import Header from './components/Header';
import './utils/initFA';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductProvider from './providers/ProductProvider';
import NotificationProvider from './providers/NotificationProvider';

const App = () => {
	return (
		<ProductProvider>
			<NotificationProvider>
				<Header />
				{/* <NavbarVertical /> */}
				<main className='py-3'>
					<Container fluid>
						<Outlet />
					</Container>
				</main>
				<ToastContainer
					position='bottom-right'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
			</NotificationProvider>
		</ProductProvider>
	);
};

export default App;
