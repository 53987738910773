import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Card, Col, Dropdown, OverlayTrigger, Placeholder, Row, Tooltip } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../../../components/common/Background';
import CardDropdown from '../../../components/common/CardDropdown.jsx';
import { clearCustomer, clearSale } from '../../../slices/orderSlice.js';
import corner1 from './../../../assets/img/illustrations/corner-1.png';
import SelectOrderSaleModal from './table/SelectOrderSaleModal.jsx';

const OrderSale = () => {
	const { sale } = useSelector((state) => state.order);
	const { watch } = useFormContext();
	const { draftId } = watch();
	const [modalShow, setModalShow] = useState(false);
	const dispatch = useDispatch();
	return (
		<>
			<Card className='mb-3'>
				<Background
					image={corner1}
					className='bg-card d-none d-md-block'
					style={{
						borderTopRightRadius: '0.375rem',
						borderBottomRightRadius: '0.375rem',
					}}
				/>
				<Card.Header className='position-relative'>
					<Card.Title>
						<Row className='flex-between-center'>
							<Col md>
								<h6 className='mb-2 mb-md-0 me-1'>销售信息</h6>
							</Col>
							<Col xs='auto'>
								<CardDropdown drop='start'>
									<div className='py-2'>
										<Dropdown.Item
											className='text-danger'
											onClick={() => {
												dispatch(clearSale());
												dispatch(clearCustomer());
											}}
										>
											清空
										</Dropdown.Item>
									</div>
								</CardDropdown>
							</Col>
						</Row>
					</Card.Title>
				</Card.Header>
				<Card.Body className='position-relative'>
					<Row>
						<Col>
							<span>
								{sale._id ? (
									<>
										<p>
											销售员: <strong className='border-bottom'>{sale.name}</strong>
											<OverlayTrigger
												overlay={
													<Tooltip style={{ position: 'fixed' }} id='overlay-trigger-example'>
														重新选择
													</Tooltip>
												}
											>
												<Button variant='falcon-primary' size='sm' className='rounded-pill ms-2 mb-1' type='button' onClick={() => setModalShow(true)}>
													<FontAwesomeIcon icon='fa-arrows-rotate' />
												</Button>
											</OverlayTrigger>
										</p>
									</>
								) : draftId ? (
									<Placeholder animation='glow' className='mb-2'>
										<Placeholder xs={6} bg='primary' />
									</Placeholder>
								) : (
									<></>
								)}
							</span>

							{Object.values(sale).length !== 0 && (
								<>
									<p>
										联系人电话：
										<span>
											{sale.phone ? (
												<strong>{sale.phone}</strong>
											) : draftId ? (
												<Placeholder animation='glow' className='mb-2'>
													<Placeholder xs={3} bg='primary' />
												</Placeholder>
											) : (
												<></>
											)}
										</span>
									</p>
									{sale.email ? (
										<p>
											邮箱：
											<span>
												<strong>{sale.email}</strong>{' '}
											</span>
										</p>
									) : (
										<></>
									)}
								</>
							)}
						</Col>
					</Row>
				</Card.Body>
			</Card>
			{/* Modal */}
			<SelectOrderSaleModal modalShow={modalShow} setModalShow={setModalShow} />
		</>
	);
};

export default OrderSale;
