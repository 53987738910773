/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useGetApprovalByIdQuery, useUpdateCustomerApprovalByIdMutation } from '../../slices/approvalsApiSlice';
import { useCheckCustomerNameMutation, useCreateCustomerMutation } from '../../slices/customersApiSlice';
import createMarkup from '../../utils/createMarkup';
import { useNotifyCustomerApprovedToApplicantMutation, useNotifyOrderApprovedToApplicantMutation } from '../../slices/notificationsApiSlice';

const CustomerApprovalDetailScreen = () => {
	const { id: approvalId } = useParams();
	const { userInfo } = useSelector((state) => state.auth);
	const navigate = useNavigate();

	const { data: approval, isLoading: loadingApproval, error: loadingApprovalError, refetch } = useGetApprovalByIdQuery(approvalId);

	const [updateCustomerApprovalStatus, { isLoading: updatingApproval, error: updateError }] = useUpdateCustomerApprovalByIdMutation();

	const [notifyCustomerApprovedToApplicant] = useNotifyCustomerApprovedToApplicantMutation();
	const [checkCustomerName, { isLoading: nameChecking }] = useCheckCustomerNameMutation();

	const [createCustomer, { isLoading: Creating }] = useCreateCustomerMutation();

	useCallback(() => {
		async function fetchData() {
			await checkCustomerName({ newCustomerType: approval.detail?.newCustomerType, newCustomerName: approval.detail?.newCustomerName }).unwrap();
		}
		if (!loadingApproval) {
			try {
				return fetchData();
			} catch (error) {
				toast.error(error?.data?.message || error.error);
			}
		}
	}, []);

	const handlePass = async () => {
		try {
			await checkCustomerName({ newCustomerType: approval.detail?.newCustomerType, newCustomerName: approval.detail?.newCustomerConcatName }).unwrap();

			const approved_res = await updateCustomerApprovalStatus({
				_id: approvalId,
				status: 'approved',
			}).unwrap();
			toast.success('审批通过');

			const { approver, applicant, detail, _id } = approved_res;

			const createdCustomer = await createCustomer({
				type: detail?.newCustomerType,
				name: detail?.newCustomerConcatName,
				organization: detail?.newCustomerOrganization,
				department: detail?.newCustomerDepartment,
				group: detail?.newCustomerGroup,
				city: detail?.newCustomerCity,
				address: detail?.newCustomerAddress,
				phone: detail?.newCustomerPhone,
				email: detail?.newCustomerEmail,
				applicant: applicant,
			}).unwrap();

			toast.success(`${createdCustomer.name}客户创建成功`);

			console.log('审批ID:' + _id);
			// notify applicant
			await notifyCustomerApprovedToApplicant({
				toUser: applicant,
				fromUser: approver,
				content: { message_text: `聂总: 新增客户审批通过 点击查看 ✅`, detail, approvalId: _id },
			}).unwrap();

			// toUserNotifications.save();
			navigate('/approval/list');
		} catch (error) {
			toast.error(error?.data?.message || error.error);
		}
	};

	const handleUnagree = async () => {
		// 更新驳回理由
		const approved_res = await updateCustomerApprovalStatus({
			_id: approvalId,
			status: 'reject',
		});
		const { approver, applicant, detail, _id } = approved_res;

		// notify applicant
		await notifyCustomerApprovedToApplicant({
			toUser: applicant,
			fromUser: approver,
			content: { message_text: `聂总: 新增客户审批通过 点击查看 ✅`, detail, approvalId: _id },
		}).unwrap();
		toast.success('申请已驳回');
		navigate('/approval/list');
	};

	const handleRenew = async () => {
		// 再次提交
		await updateCustomerApprovalStatus({
			_id: approvalId,
			status: 'reject',
		});

		toast.success('重新提交成功');
		navigate('/approval/list');
	};
	useEffect(() => {
		refetch();
	}, [refetch]);
	return (
		<div>
			<Button
				className='mb-3'
				onClick={() => {
					navigate('/approval/list');
				}}
			>
				返回列表
			</Button>

			{loadingApproval ? (
				<Loader />
			) : (
				<>
					{/* {<Message variant={approval.status}>审批状态: {approval.status.content} </Message>} */}
					<h4>{approval?.title}</h4>
					<p className='fs--1 ps-2 mb-3'>
						销售员: <strong>{approval?.applicant.name}</strong>
					</p>
					{approval?.assistant._id && (
						<p className='fs--1 ps-2 mb-3'>
							申请人: <strong>{approval?.assistant.name}</strong>
						</p>
					)}
					<p className='fs--1 ps-2 mb-0' dangerouslySetInnerHTML={createMarkup(approval?.reason)}></p>
					{userInfo.canApproval && approval.status === 'waitingApproval' && (
						<>
							<Button variant='success' onClick={handlePass}>
								<FontAwesomeIcon icon='check' /> 同意
							</Button>
							<Button className='ms-3' variant='outline-danger' onClick={handleUnagree}>
								<FontAwesomeIcon icon='xmark' /> 不同意
							</Button>
						</>
					)}
					{!userInfo.canApproval && approval.status === 'reject' && (
						<>
							<Button className='mx-1' variant='success' onClick={handleRenew}>
								<FontAwesomeIcon icon='fa-solid fa-rotate-right' /> 再次申请
							</Button>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default CustomerApprovalDetailScreen;
