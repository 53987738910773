import { Button, Card, Table } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Message from '../../components/Message';
import { useGetMyOrdersQuery } from '../../slices/ordersApiSlice';
// import Loader from '../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
const OrderListScreen = () => {
	const { data: orders, isLoading, isError } = useGetMyOrdersQuery();

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									订单跟进
								</li>
							</ol>
						</nav>
					</Card.Title>
					<p className='card-subtitle text-muted'>Order List</p>
				</Card.Header>
				<Card.Body className='p-0'>
					{isLoading ? (
						<Loader />
					) : isError ? (
						<Message variant='danger'></Message>
					) : orders ? (
						<Table striped hover responsive className='table-sm'>
							<thead>
								<tr>
									<th></th>
									<th>订单编号</th>
									<th>创建人</th>
									<th>创建日期</th>
									<th>总价</th>
									<th>下单支付</th>
									<th>发货支付</th>
									<th>验收支付</th>
									<th>发货/安装/服务</th>
									<th>验收</th>
									<th>完成</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{orders?.map((order) => (
									<>
										<tr key={order._id}>
											<td>{order.orderId}</td>
											<td>{order.createdUser && order.createdUser.name}</td>
											<td>{order.createdAt.substring(0, 10)}</td>
											<td>
												<span className='text-primary'>
													<strong>¥{order.totalPrice}</strong>
												</span>
											</td>
											{order.accountsReceivables.filter((ar) => ar.title === '下单' && ar.isPaid).length !== 0 &&
											order.accountsReceivables.filter((ar) => ar.title === '下单' && ar.isPaid) ? (
												<td>{order.accountsReceivables.filter((ar) => ar.title === '下单' && ar.isPaid)[0]?.paidAt.substring(0, 10)}</td>
											) : (
												<td className='text-center'>
													<FaTimes style={{ color: 'red' }} />
												</td>
											)}
											{order.accountsReceivables.filter((ar) => ar.title === '发货' && ar.isPaid).length !== 0 &&
											order.accountsReceivables.filter((ar) => ar.title === '发货' && ar.isPaid).length ? (
												<td>{order.accountsReceivables.filter((ar) => ar.title === '发货' && ar.isPaid)[0]?.paidAt.substring(0, 10)}</td>
											) : (
												<td className='text-center'>
													<FaTimes style={{ color: 'red' }} />
												</td>
											)}
											{order.accountsReceivables.filter((ar) => ar.title === '验收' && ar.isPaid).length !== 0 &&
											order.accountsReceivables.filter((ar) => ar.title === '验收' && ar.isPaid).length ? (
												<td>{order.accountsReceivables.filter((ar) => ar.title === '验收' && ar.isPaid)[0]?.paidAt.substring(0, 10)}</td>
											) : (
												<td className='text-center'>
													<FaTimes style={{ color: 'red' }} />
												</td>
											)}
											<td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
											<td>{order.isAccepted ? order.acceptedAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
											<td>{order.isCompleted ? order.completedAt.substring(0, 10) : <FaTimes style={{ color: 'red' }} />}</td>
											<td>
												<LinkContainer to={`/order/detail/${order._id}`}>
													<Button variant='falcon-primary' className='btn-sm me-2'>
														查看订单
													</Button>
												</LinkContainer>
											</td>
										</tr>
									</>
								))}
							</tbody>
						</Table>
					) : (
						<>
							<div className='d-flex justify-content-center'>
								<p className='vh-75 fs-9'>
									没有订单？<Link to='/order/create/new'>去新建</Link>
								</p>
							</div>
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderListScreen;
