import React from 'react';
import { Card, Col, FloatingLabel, Form, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/common/Flex';
import { useFormContext } from 'react-hook-form';

const OrderBusiness = () => {
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext();

	return (
		<Card className='mb-3'>
			<Card.Header>
				<Flex>
					<div>
						<Card.Title>
							<h6 className='mb-2 mb-md-0 me-1'>商务信息</h6>
						</Card.Title>
					</div>
				</Flex>
			</Card.Header>
			<Card.Body>
				<Form.Group as={Row} className='mb-3' controlId='arrivalTime'>
					<Form.Label column sm='3'>
						到货时间:
					</Form.Label>
					<Col sm='9'>
						<Form.Select isInvalid={!!errors.arrivalTime} {...register('arrivalTime', { onChange: (e) => setValue('arrivalTime', e.target.value) })}>
							<option value=''>选择到货时间</option>
							<option value='1-2周'>1-2周</option>
							<option value='3-4周'>3-4周</option>
							<option value='4-6周'>4-6周</option>
							<option value='6-8周'>6-8周</option>
							<option value='2个月以上'>2个月以上</option>
						</Form.Select>
						<Form.Control.Feedback type='invalid'>{errors.arrivalTime?.message}</Form.Control.Feedback>
					</Col>
				</Form.Group>

				<Form.Group as={Row} className='mb-3' controlId='warrantyPeriod'>
					<Form.Label column sm='3'>
						保修期:
						<OverlayTrigger
							overlay={
								<Tooltip style={{ position: 'fixed' }} id='basePriceTooltip'>
									保修期默认为1年
								</Tooltip>
							}
						>
							<span className='ms-2 text-primary fs-10'>
								<FontAwesomeIcon icon='question-circle' />
							</span>
						</OverlayTrigger>
					</Form.Label>
					<Col sm='9'>
						<InputGroup>
							<Form.Control
								type='text'
								name='warrantyPeriod'
								placeholder='请填写保修期'
								isInvalid={!!errors.warrantyPeriod}
								{...register('warrantyPeriod', { valueAsNumber: true })}
							/>
							<InputGroup.Text id='basic-addon2'>年</InputGroup.Text>
							<Form.Control.Feedback type='invalid'>{errors.warrantyPeriod?.message}</Form.Control.Feedback>
						</InputGroup>
					</Col>
				</Form.Group>

				<Form.Group className='mb-3' controlId='orderNotes'>
					<Form.Label>备注:</Form.Label>
					<FloatingLabel label='在此开始记录...'>
						<Form.Control size='text' name='orderNotes' as='textarea' rows={3} style={{ height: '100px' }} {...register('orderNotes')} />
					</FloatingLabel>
				</Form.Group>
			</Card.Body>
		</Card>
	);
};

export default OrderBusiness;
