import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Card, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { useGetMyOrderDraftsQuery } from '../../slices/orderDraftsApiSlice';
import formatDate from '../../utils/formatDate.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const OrderDraftListScreen = () => {
	const { data: orderDrafts, isLoading, error } = useGetMyOrderDraftsQuery();

	const renderTooltip = (props) => (
		<Tooltip id='button-tooltip' {...props}>
			离成功就差一步！
		</Tooltip>
	);
	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className='mb-2'>
						<nav aria-label='breadcrumb'>
							<ol className='breadcrumb'>
								<li className='breadcrumb-item'>
									<a href='/dashboard'>
										<FontAwesomeIcon icon='home' className='me-2' />
										首页
									</a>
								</li>
								<li className='breadcrumb-item active' aria-current='page'>
									订单编辑
								</li>
							</ol>
						</nav>
					</Card.Title>
					<p className='card-subtitle text-muted'>Order Draft</p>
				</Card.Header>
				<Card.Body className='p-0'>
					{isLoading ? (
						<Loader />
					) : error ? (
						<Message variant='danger'>{error}</Message>
					) : orderDrafts.length !== 0 ? (
						<>
							<Table striped hover responsive className='table-sm'>
								<thead>
									<tr>
										<th>创建日期</th>
										<th>客户</th>
										<th>联系人</th>
										<th>联系人电话</th>
										<th>销售员</th>
										<th>销售助理</th>
										<th>产品数量</th>
										<th>付款方式</th>
										<th>备注</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{orderDrafts?.map((draft) => (
										<tr key={draft._id}>
											<td>{formatDate(draft.createdAt)}</td>
											<td>{draft.customer?.customerName}</td>
											<td>{draft.customer?.staffName}</td>
											<td>{draft.customer?.staffPhone}</td>
											<td>{draft.user && draft.user.name}</td>
											<td>{draft.assistant ? draft.assistant.name : <Badge bg='danger'>未选择</Badge>}</td>
											<td>
												<OverlayTrigger placement='top' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
													<span>{draft.orderItems.length}</span>
												</OverlayTrigger>
											</td>
											<td>{draft.paymentMethod}</td>
											<td>{draft.orderNotes}</td>
											<td>
												<LinkContainer to={`/order/create/${draft._id}/draft`}>
													<Button variant='falcon-primary' className='btn-sm'>
														继续编辑
													</Button>
												</LinkContainer>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</>
					) : (
						<>
							<div className='d-flex justify-content-center'>
								<p className='vh-75 fs-9'>
									还没有草稿 <Link to='/order/create/new'>去新建</Link>
								</p>
							</div>
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
};

export default OrderDraftListScreen;
