import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { useGetStatisDataQuery } from '../../../slices/dashboardApiSlice';
import StatisticsCard from './StatisticsCard';

const StatisticsCards = () => {
	const {
		data: statisData = [
			{
				title: '客户数量',
				value: 0,
				decimal: false,
				valueClassName: 'text-warning',
				linkText: '统计中',
			},
			{
				title: '订单数量',
				value: 0,
				decimal: false,
				valueClassName: 'text-info',
				linkText: '统计中',
			},
			{
				title: '总收入',
				value: 0,
				decimal: false,
				suffix: '',
				prefix: '¥',
				linkText: '统计中',
			},
		],
		isLoading: loadingStatsData,
	} = useGetStatisDataQuery();

	return (
		<Row className='g-3 mb-3'>
			{statisData.map((stat, index) => (
				<Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
					<StatisticsCard stat={stat} style={{ minWidth: '12rem' }} loadingStatsData={loadingStatsData} />
				</Col>
			))}
		</Row>
	);
};

export default StatisticsCards;
