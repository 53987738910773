import { Spinner } from 'react-bootstrap';

const Loader = () => {
	return (
		<>
			<Spinner
				animation='border'
				variant='primary'
				role='status'
				style={{
					width: '100px',
					height: '100px',
					margin: 'auto',
					display: 'block',
				}}
				className='my-5'
			/>
		</>
	);
};

export default Loader;
