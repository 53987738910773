import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './slices/apiSlice';
import authSliceReducer from './slices/authSlice';
import orderSliceReducer from './slices/orderSlice';
import customerSliceReducer from './slices/customerSlice';
const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		order: orderSliceReducer,
		auth: authSliceReducer,
		customer: customerSliceReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
	devTools: true,
});

export default store;
