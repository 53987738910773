import classNames from 'classnames';
import { useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAddStaffToCustomerMutation } from '../../slices/customersApiSlice';

const AddStaffToCustomerModal = ({ selectedCustomer }) => {
	const [addPersonToCustomer, { isLoading: personAdding }] = useAddStaffToCustomerMutation();

	const [addModalShow, setAddModalShow] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const submitNewPerson = async (data) => {
		try {
			await addPersonToCustomer({
				customerId: selectedCustomer._id,
				newPersonName: data.newPersonName,
				newPersonPhone: data.newPersonPhone,
				newPersonWechat: data.newPersonWechat,
				newPersonEmail: data.newPersonEmail,
			}).unwrap();
			setAddModalShow(false);
			reset();
			toast.success('联系人添加成功！');
		} catch (err) {
			toast.error(err.data.message);
		}
	};

	return (
		<>
			<Button variant='outline-primary' size='sm' as={Link} type='button' onClick={() => setAddModalShow(true)}>
				新增联系人
			</Button>
			<Modal show={addModalShow} onHide={() => setAddModalShow(false)} backdrop='static' size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
				<Form
					onSubmit={(e) => {
						if (e) {
							if (typeof e.preventDefault === 'function') {
								e.preventDefault();
							}
							if (typeof e.stopPropagation === 'function') {
								e.stopPropagation();
							}
							return handleSubmit(submitNewPerson)(e);
						}
					}}
				>
					{/* {initiateError && <Message />} */}
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>添加客户联系人</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Row className='mb-3'>
							<Col sm='3'>
								<span className='text-primary fw-bold'>客户名称</span>
							</Col>
							<Col sm='6'>
								<span className='text-primary fw-bold'>{selectedCustomer.name}</span>
							</Col>
						</Row>
						<Form.Group as={Row} className='mb-3' controlId='newPersonName'>
							<Form.Label column sm='3'>
								新联系人
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='newPersonName' placeholder='姓名' autoComplete='false' {...register('newPersonName', { required: true })} />
								{errors.newPersonName?.type === 'required' && <p className='text-danger'>联系人姓名为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='newPersonPhone'>
							<Form.Label column sm='3'>
								联系人电话(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='newPersonPhone' placeholder='11位号码' autoComplete='false' {...register('newPersonPhone')} />
								{errors.newPersonPhone?.type === 'required' && <p className='text-danger'>联系人电话为必填项</p>}
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='newPersonWechat'>
							<Form.Label column sm='3'>
								微信(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='newPersonWechat' placeholder='联系人微信ID' autoComplete='false' {...register('newPersonWechat')} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} className='mb-3' controlId='newPersonEmail'>
							<Form.Label column sm='3'>
								邮箱(选填)
							</Form.Label>
							<Col sm='6'>
								<Form.Control type='text' name='newPersonEmail' placeholder='eg: 123@xxx.com' autoComplete='false' {...register('newPersonEmail')} />
							</Col>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='secondary' onClick={() => setAddModalShow(false)}>
							关闭
						</Button>
						<Button variant='primary' type='submit' disabled={personAdding} className='d-inline-flex flex-center'>
							{personAdding && <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />}
							<span className={classNames({ 'visually-hidden': personAdding })}>提交</span>
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default AddStaffToCustomerModal;
