/* eslint-disable no-unused-vars */
import { Box } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
//Import Material React Table Translations
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { Link } from 'react-router-dom';
import { useGetSaleUsersQuery } from '../../slices/usersApiSlice';
import CustomerCreateApplyModal from './CustomerCreateApplyModal';

const SelectSaleTable = ({ setModalShow }) => {
	const [applyModalShow, setApplyModalShow] = useState(false);
	const [rowSelection, setRowSelection] = useState({});
	const { data: fetchedSaleUsers = [], isError: isLoadingSaleUsersError, isFetching: isFetchingSaleUsers, isLoading: isLoadingSaleUsers } = useGetSaleUsersQuery();

	const columns = useMemo(
		() => [
			{
				accessorKey: 'name',
				header: '销售员',
			},
			{
				accessorKey: 'email',
				header: '邮箱',
			},
			{
				accessorKey: 'phone',
				header: '联系人电话',
			},
			{
				accessorKey: 'alias',
				header: '联系人电话',
			},
		],
		[]
	);
	const hanldeSelectedSale = async () => {
		const rows = table.getSelectedRowModel().rows; //or read entire rows
		const original = rows.map((row) => row.original);
		setRowSelection(original[0]);
		setApplyModalShow(true);
	};

	const table = useMaterialReactTable({
		columns,
		data: fetchedSaleUsers,
		createDisplayMode: 'modal', // ('modal', and 'custom' are also available)
		editDisplayMode: 'custom', // ('modal', 'cell', 'table', and 'custom' are also available)
		enableRowSelection: true,
		enableMultiRowSelection: false,
		getRowId: (row) => row._id,
		enableGrouping: true,
		enableFullScreenToggle: true,
		localization: MRT_Localization_ZH_HANS,
		positionToolbarAlertBanner: 'bottom',
		initialState: {
			expanded: true, //expand all rows by default
			pagination: { pageIndex: 0, pageSize: 50 },
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		muiToolbarAlertBannerProps: isLoadingSaleUsersError
			? {
					color: 'error',
					children: '数据加载错误',
			  }
			: undefined,
		muiTableContainerProps: {
			sx: {
				maxHeight: '500px',
			},
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
				{!isLoadingSaleUsers && !isFetchingSaleUsers && fetchedSaleUsers.length !== 0 && (
					<>
						<Button size='sm' variant='primary' as={Link} onClick={hanldeSelectedSale}>
							确定
						</Button>
					</>
				)}
			</Box>
		),
		state: {
			isLoading: isLoadingSaleUsers,
			showAlertBanner: isLoadingSaleUsersError,
			showProgressBars: isFetchingSaleUsers,
		},
	});

	return (
		<>
			<MaterialReactTable table={table} />
			<CustomerCreateApplyModal setApplyModalShow={setApplyModalShow} applyModalShow={applyModalShow} applicant={rowSelection} />
		</>
	);
};

export default SelectSaleTable;
