import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Card, Dropdown, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isIterableArray } from '../../src/utils/utils.js';
import FalconCardHeader from '../components/common/FalconCardHeader';
import Notification from '../components/notification/Notification';
import { useNotificationContext } from '../providers/NotificationProvider.js';
import { useSetAllNewNotificationToReadMutation } from '../slices/notificationsApiSlice.js';

const NotificationDropdown = () => {
	// const { data: newUnreadNotifications = [], refetch } = useGetMyNewNotificationQuery();
	const { newUnreadNotifications, refetch, setToRead } = useNotificationContext();
	const [setAllNewUnreadNotifications] = useSetAllNewNotificationToReadMutation();

	const [isOpen, setIsOpen] = useState(false);
	const [isAllRead, setIsAllRead] = useState(true);

	// Handler
	const handleToggle = ({ notification }) => {
		setIsOpen(!isOpen);
		setIsAllRead(true);
		setToRead(notification);
		refetch();
	};

	const markAsRead = async (e) => {
		const updatedNewNotifications = newUnreadNotifications.map((notification) =>
			Object.prototype.hasOwnProperty.call(notification, 'unread') ? { ...notification, unread: false } : notification
		);
		setIsAllRead(true);
		await setAllNewUnreadNotifications(updatedNewNotifications).then(() => refetch());
	};

	return (
		<Dropdown navbar={true} as='li' show={isOpen} onToggle={handleToggle}>
			<Dropdown.Toggle
				bsPrefix='toggle'
				as={Link}
				to='#!'
				className={classNames('px-1 nav-link', {
					'notification-indicator notification-indicator-danger': newUnreadNotifications.some((notification) => notification.unread) ? isAllRead : !isAllRead,
				})}
			>
				<FontAwesomeIcon icon='bell' transform='shrink-6' className='fs-6 text-white' />
			</Dropdown.Toggle>

			<Dropdown.Menu className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'>
				<Card className='dropdown-menu-notification dropdown-menu-end shadow-none' style={{ maxWidth: '20rem' }}>
					<FalconCardHeader
						className='card-header'
						title='通知📢'
						titleTag='h6'
						light={false}
						endEl={
							<Link className='card-link fw-normal' to='#!' onClick={markAsRead}>
								标记全部已读
							</Link>
						}
					/>
					<ListGroup variant='flush' className='fw-normal fs-10 scrollbar' style={{ maxHeight: '19rem' }}>
						<div className='list-group-title'>最新</div>
						{newUnreadNotifications.length === 0 && (
							<ListGroup.Item>
								<div className='notification-body text-center'>
									<FontAwesomeIcon icon='fa-brands fa-watchman-monitoring' className='fs-9 mb-2 mt-5' />
									<p className='mb-5'>无任何通知</p>
								</div>
							</ListGroup.Item>
						)}
						{isIterableArray(newUnreadNotifications) &&
							newUnreadNotifications.map((notification) => (
								<ListGroup.Item key={notification._id} onClick={(e) => handleToggle({ notification })}>
									<Notification {...notification} flush />
								</ListGroup.Item>
							))}
					</ListGroup>
					<div className='card-footer text-center border-top' onClick={handleToggle}>
						<Link className='card-link d-block' to='/notificationlist'>
							查看全部
						</Link>
					</div>
				</Card>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default NotificationDropdown;
