/* eslint-disable react/jsx-pascal-case */
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
//Import Material React Table Translations
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { MRT_Localization_ZH_HANS } from 'material-react-table/locales/zh-Hans';
import { Button, Stack } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SubtleBadge from '../../../../components/common/SubtleBadge';
import SystemAlert from '../../../../components/modal/SystemAlert';
import { clearScheduleNodes, removeFromOrder, updateOrderItemsQty, updateOrderItemsSellingPrice } from '../../../../slices/orderSlice';

const OrderProductListTable = () => {
	const dispatch = useDispatch();
	const [validationErrors, setValidationErrors] = useState({});
	const [rowSelection] = useState({});
	const order = useSelector((state) => state.order);
	const { orderItems } = order;
	const { setValue } = useFormContext();

	const handleRemoveOrderItem = (id) => {
		dispatch(removeFromOrder(id));
		toast.warning('产品已移除');
	};

	const [showClearSchedulNodesConfirm, setShowClearSchedulNodesConfirm] = useState(false);
	const handelShowClearScheduleNodesConfirm = (id) => {
		// reset current product modified selling price

		setShowClearSchedulNodesConfirm(false);
	};

	const handleClearScheduleNodes = () => {
		dispatch(clearScheduleNodes());
		setShowClearSchedulNodesConfirm(false);
	};

	const sumQty = useMemo(() => orderItems.reduce((acc, curr) => acc + curr.qty, 0), [orderItems]);

	const sumPrice = useMemo(() => orderItems.reduce((acc, curr) => acc + curr.sellingPrice * curr.qty, 0), [orderItems]);

	const columns = useMemo(
		() => [
			{
				header: '货号&品名',
				accessorKey: 'productCode',
				enableEditing: false,
				size: '260',
				Cell: ({ cell, row }) => {
					return (
						<>
							{row.original.sellingPrice < row.original.minimumSellingPrice && <FontAwesomeIcon icon='star' className='fs-11 text-danger me-2' />}
							<SubtleBadge>{cell.getValue()}</SubtleBadge>
							<span className='fs-11 mx-2'>{row.original.name}</span>
						</>
					);
				},
			},
			{
				header: '品牌',
				accessorKey: 'brand',
				enableEditing: false,
				Cell: ({ cell, row }) => {
					return (
						<>
							<span className='fs-11'>{cell.getValue()}</span>
						</>
					);
				},
			},
			{
				header: '规格',
				accessorKey: 'specification',
				enableEditing: false,
				Cell: ({ cell, row }) => {
					return (
						<>
							<span className='fs-11'>{cell.getValue()}</span>
						</>
					);
				},
			},
			{
				header: '厂家',
				accessorKey: 'supplierName',
				enableEditing: false,
				Cell: ({ cell, row }) => {
					return (
						<>
							<span className='fs-11'>{cell.getValue()}</span>
						</>
					);
				},
			},
			{
				header: '底价',
				accessorKey: 'minimumSellingPrice',
				enableEditing: false,
				Cell: ({ cell, row }) => {
					return (
						<>
							<span className='fs-10'>{cell.getValue()}</span>
						</>
					);
				},
			},
			{
				header: '数量',
				accessorKey: 'qty',
				Cell: ({ cell, row }) => {
					return (
						<>
							<span className='fs-10'>{cell.getValue()}</span>
						</>
					);
				},
				muiEditTextFieldProps: ({ cell, row }) => ({
					type: 'number',
					required: true,
					error: !!validationErrors?.[cell.id],
					helperText: validationErrors?.[cell.id],
					onBlur: (event) => {
						if (validationErrors?.[cell.id] === undefined) {
							dispatch(updateOrderItemsQty({ ...row.original, qty: Number(event.currentTarget.value) }));
						} else {
							dispatch(updateOrderItemsQty({ ...row.original, qty: row.original.qty }));
						}
					},
					onChange: (event) => {
						// valid product item quantity if it's regular
						const validationError = !validateQty(Number(event.currentTarget.value)) ? '数量不能小于1' : undefined;
						setValidationErrors({
							...validationErrors,
							[cell.id]: validationError,
						});
					},
				}),
				Footer: () => (
					<Stack>
						总数量:
						<Box color='warning.main'>{Math.round(sumQty)}</Box>
					</Stack>
				),
			},
			{
				header: '销售单价',
				accessorKey: 'sellingPrice',
				Cell: ({ cell, row }) => {
					const { original } = row;
					return (
						<>
							<span className='fs-10 text-primary fw-bolder'>{cell.getValue()}</span>
							<FontAwesomeIcon icon='pencil' className='fs-11 ms-1' />
							{original.sellingPrice < original.minimumSellingPrice && (
								<Link
									className='fs-11 ms-1 text-500'
									onClick={() => {
										dispatch(
											updateOrderItemsSellingPrice({
												...row.original,
												sellingPrice: original.minimumSellingPrice,
												isDiscount: false,
											})
										);
									}}
								>
									重置
								</Link>
							)}
						</>
					);
				},
				muiEditTextFieldProps: ({ cell, row, colunms }) => ({
					type: 'number',
					required: true,
					error: !!validationErrors?.[cell.id],
					helperText: validationErrors?.[cell.id],
					variant: 'outlined',
					onFocus: () => {
						setValidationErrors({
							...validationErrors,
							[cell.id]: undefined,
						});
						if (order.scheduleNodes.length !== 0) {
							setShowClearSchedulNodesConfirm(true);
							//dispatch(clearScheduleNodes()); //  clear all schedule node if order product item quantity or selling price changed
						}
					},
					onBlur: (event) => {
						if (Number(event.currentTarget.value) !== row.original.sellingPrice) {
							dispatch(
								updateOrderItemsSellingPrice({
									...row.original,
									sellingPrice: Number(event.currentTarget.value),
									isDiscount: Number(event.currentTarget.value) < row.original.minimumSellingPrice ? true : false,
								})
							);
							setValidationErrors({});
							setValue('prePaymentPercent', 0); // reset pre payment percent form field value
							setValue('percentPayment', 0); // reset pre payment percent form field value
						}
					},
					onChange: (event) => {
						// valid selling price if less than minimum selling price
						const validationError = !validateSellingPrice(Number(event.currentTarget.value), row.original.minimumSellingPrice) ? '销售价低于底价' : undefined;
						setValidationErrors({
							...validationErrors,
							[cell.id]: validationError,
						});
					},
				}),
				Footer: () => (
					<Stack>
						总价:
						<Box color='warning.main'>¥ {sumPrice.toFixed(2)}</Box>
					</Stack>
				),
			},
		],
		[dispatch, order.scheduleNodes.length, setValue, sumPrice, sumQty, validationErrors]
	);

	const table = useMaterialReactTable({
		columns,
		data: orderItems,
		displayColumnDefOptions: {
			'mrt-row-expand': {
				enableResizing: true,
			},
		},
		muiTablePaperProps: {
			elevation: 0,
		},
		enableColumnResizing: true,
		enableRowActions: true,
		positionActionsColumn: 'first',
		enableRowNumbers: true,
		enableStickyHeader: true,
		enableStickyFooter: true,
		getRowId: (row) => row._id,
		enableResizing: true,
		enableMultiRowSelection: false,
		enableGrouping: true,
		enableFullScreenToggle: false,
		enableCellActions: true,
		enableClickToCopy: 'context-menu',
		enableEditing: true,
		enableColumnPinning: true,
		editDisplayMode: 'cell',
		localization: MRT_Localization_ZH_HANS,
		initialState: {
			showGlobalFilter: true,
			density: 'compact',
			expanded: true, //expand all groups by default
			// grouping: ['supplier.name'],
			columnPinning: {
				left: ['mrt-row-actions', 'mrt-row-numbers', 'productCode', 'name'],
				// right: ['mrt-row-actions'],
			},
			pagination: { pageIndex: 0, pageSize: 10 },
			sorting: [{ id: 'supplierName', desc: false }], //sort by state by default
		},

		muiToolbarAlertBannerChipProps: { color: 'primary' },
		muiTableContainerProps: { sx: { maxHeight: 900, minHeight: 348 } },
		state: {
			rowSelection,
		},
		positionToolbarAlertBanner: 'top',
		renderRowActions: ({ row, table }) => (
			<Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
				<Button
					size='sm'
					variant='outline-danger'
					className='border-none trasparent'
					onClick={() => {
						handleRemoveOrderItem(row.id);
					}}
				>
					<FontAwesomeIcon icon='fa-solid fa-trash' />
				</Button>
			</Box>
		),
	});

	return (
		<>
			<SystemAlert
				title='提示🔔'
				bodyText='调整产品价格，已设置的订单尾款支付节点将重置!'
				cancelText='取消'
				confirmText='继续调整'
				modalShow={showClearSchedulNodesConfirm}
				setModalShow={handelShowClearScheduleNodesConfirm}
				handleConfirm={handleClearScheduleNodes}
			/>
			<MaterialReactTable table={table} />
		</>
	);
};

export default OrderProductListTable;

const validateQty = (qty) => {
	if (qty >= 1) {
		return true;
	} else {
		return false;
	}
};
const validateSellingPrice = (sellingPrice, minimumSellingPrice) => {
	if (sellingPrice >= minimumSellingPrice) {
		return true;
	} else {
		return false;
	}
};
